import { Location, LocationStrategy } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { TemaService } from 'src/app/services/tema/tema.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { WebService } from 'src/app/services/web/web.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public hide = true;
  public logoPath: string = '';
  public bgPath: string = '';

  public loginError: string = '';

  public isGo = false;

  public formLogin = new FormGroup({
    username: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator ]
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    rememberMe: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: []
    }),
    origin: new FormControl<string>(this.utilityService.getBaseUrl(), {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  constructor(
    private utenteService: UtenteService,
    private router: Router,
    private temaService: TemaService,
    private utilityService: UtilityService
  ) {
    console.log('LOCATION : ', location.href);

    // let reg = new RegExp(/https?:\/\/.*?(?::[0-9]*)?\//);

    this.temaService.temaUpdated.subscribe(() => {
      this.logoPath = temaService.logoPath;
      this.bgPath = temaService.bgPath;
    });

  }

  public ctrAuth() {
    //chiamo il servizio di controllo autenticazione
    this.spinnerOver.show();

    this.loginError = '';

    this.formLogin.disable();

    this.utenteService.login(this.formLogin.getRawValue()).subscribe({
      next: (value) => {

        this.router.navigate(['home']).then((navigato) => {
          this.spinnerOver.hide();
        });

      },
      error: (err) => {
        this.spinnerOver.hide();
        console.error('Errore : ', err);

        if (err === 'AGGIORNA_PASSWORD') {
          this.loginError = 'La password è scaduta o è stato richiesto un reset password. Una mail di reset è stata inviata all\'indirizzo inserito. Se non l\'hai ricevuta, controlla la cartella "Spam", altrimenti contatta l\'assistenza.';
        } else if (err === 'MAIL_NON_VERIFICATA') {
          this.loginError = 'Indirizzo email non verificato. Controlla la casella email corrispondente per trovare la mail di verifica. Se non l\'hai ricevuta, controlla la cartella "Spam", altrimenti contatta l\'assistenza.';
        } else {
          this.loginError = err;
        }

        this.formLogin.enable();
      }
    }
    );

  }

  public passwordDimenticata() {
    this.router.navigate(['password-dimenticata']);
  }

}
