<h2 mat-dialog-title>Filtri di Ricerca</h2>
<div mat-dialog-content class="dialog-center-cont">
  <form [formGroup]="formFiltri" appFormContainer [larghezza]="larghezza">
    <ng-container *ngFor="let fl of filtriPerCampi; let idxFil = index">
      <!-- Campi -->
      <mat-form-field appearance="outline" appFormElement [larghezza]="fl.larghezza || 1" [riempi]="fl.riempi || false"
        *ngIf="
          fl.input === 'text' || fl.input === 'number'
        ">
        <mat-label>{{ fl.titolo }}</mat-label>
        <input matInput [type]="fl.input" [formControlName]="fl.forControlName || ''" />
      </mat-form-field>

      <mat-form-field appFormElement appearance="outline" [larghezza]="fl.larghezza || 1" [riempi]="fl.riempi || false"
        *ngIf="
          fl.input === 'multiple-option' ||  fl.input === 'option'
        ">
        <mat-label>{{fl.titolo}}</mat-label>
        <mat-select [placeholder]="fl.titolo" [multiple]="fl.input === 'multiple-option' ? true : false"
          [formControlName]="fl.forControlName || ''">
          <mat-option *ngFor="let amb of arrayDatiFiltri[fl?.forControlName || '']"
            [value]="amb[fl?.idValueOption || '']">
            {{amb[fl.descValueOption || '']}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appFormElement appearance="outline" [larghezza]="fl.larghezza || 1" [riempi]="fl.riempi || false"
        *ngIf="fl.input === 'date' && $any(formFiltri.get(fl.forControlName!))?.controls?.length">
        <mat-label>{{fl.titolo}}</mat-label>
      
        <mat-date-range-input [formArray]="formFiltri.get(fl.forControlName!)" [rangePicker]="picker">

          <input matStartDate placeholder="Inizio"
            [formControl]="$any(formFiltri.get(fl.forControlName!))?.controls[0]">
          <input matEndDate placeholder="Fine" [formControl]="$any(formFiltri.get(fl.forControlName!))?.controls[1]">
        </mat-date-range-input>

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field> -->


      <!-- CHIP STYLE -->

      <mat-form-field class="chip-list" *ngIf="fl.input === 'multiple-option-chip' && fl.forControlName"
        [larghezza]="fl.larghezza || 1" appFormElement appearance="outline" [riempi]="fl.riempi || false">
        <mat-label>{{fl.titolo}}</mat-label>
        <mat-chip-grid #chipGrid>
          <mat-chip-row *ngFor="let elemSel of formFiltri.get(fl.forControlName)?.value"
            (removed)="remove(elemSel, fl.forControlName)">
            {{elemSel}}
            <button matChipRemove [attr.aria-label]="'remove ' + elemSel">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Nuovo elemento..." #itemInput [formControl]="formControlCampiMultiSelect[fl.forControlName]"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event, fl.forControlName)" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, fl.forControlName)">
          <mat-option
            *ngFor="let elem of  this.filteredItemsCampiMultiSelect[fl.forControlName] | async | slice : 0 : fl.numeroRecOptions ? fl.numeroRecOptions :  10"
            [value]="elem">
            {{elem}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Separatore -->
      <div appFormElement [larghezza]="fl.larghezza || 3" [riempi]="fl.riempi || false" style="padding-bottom: 20px;"
        *ngIf="fl.input === 'separatore'">
        <app-separatore-label titoloSeparatore="{{ fl.titolo }}"
          styleSeparatore="{{ fl.scssStyle }}"></app-separatore-label>
      </div>
    </ng-container>
  </form>
</div>

<div mat-dialog-actions class="dialog-footer-cont">
  <!-- <div>
        <button mat-flat-button mat-dialog-close color="accent">Chiudi</button>
    </div> -->
  <div>
    <button mat-flat-button (click)="cancellaFiltri()" color="accent" class="accent-button">
      <mat-icon matSuffix>filter_list_off</mat-icon>
      Cancella Filtri
    </button>
  </div>

  <div>
    <button mat-flat-button (click)="applicaFiltri()" color="primary" class="primary-button">
      Applica Filtri
    </button>
  </div>
</div>