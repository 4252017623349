import { PolicyService } from 'src/app/services/policy/policy.service';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { ComitatiConFigAz } from 'src/app/services/comitati/comitati.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-compila-comitati',
  templateUrl: './compila-comitati.component.html',
  styleUrls: ['./compila-comitati.component.scss']
})
export class CompilaComitatiComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public formRisposta = new FormGroup({

    risposta: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<CompilaComitatiComponent>,
    private policyService: PolicyService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      comitato: ComitatiConFigAz,
      idPolicy: string,
    }) { }

  ngAfterViewInit(): void {

    if (this.data.comitato.risposta) {
      this.formRisposta.get('risposta')?.setValue(this.data.comitato.risposta);
    }
  }

  salva() {
    if (this.formRisposta.valid) {
      this.spinnerOver.show();

      const risposta = this.formRisposta.get('risposta')?.value;

      this.policyService.putCompilazioneComitati(this.data.idPolicy, this.data.comitato.id, risposta!).subscribe({
        next: (esito) => {

          this.utilityService.opneSnackBar('Compilazione effettuata ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
          this.spinnerOver.hide();
          this.dialogRef.close(esito);
        },
        error: (err) => {
          this.utilityService.opneSnackBar(err?.error?.message || 'Errore compilazione ', '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
          this.spinnerOver.hide();
        }

      })

    } else {
      Object.values(this.formRisposta.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      )
    }
  }

}
