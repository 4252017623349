import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StakeholderService, Tipologie } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-categoria-stakeholder',
  templateUrl: './dialog-crea-categoria-stakeholder.component.html',
  styleUrls: ['./dialog-crea-categoria-stakeholder.component.scss']
})
export class DialogCreaCategoriaStakeholderComponent implements OnInit {

  public arrayTipologia: Tipologie[] = [];


  public formCateStakeholder = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
 
    idAzienda: new FormControl<string | undefined>(undefined),
    
    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    tipologia: new FormControl<'ESTERNI' | 'INTERNI' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCategoriaStakeholderComponent>,
    private stakeholderService: StakeholderService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      cate_stakeholder: any,
      inMaterialita?: boolean,
    }
  ) {
    /* Se sono in modifica di una categoria */
    if (this.data?.cate_stakeholder) {
      Object.keys(this.data.cate_stakeholder).forEach((value, index, array) => {
        this.formCateStakeholder.get(value)?.setValue((this.data.cate_stakeholder as any)[value]);
      })
      this.formCateStakeholder.get('tipologia')?.disable();
    }

    /* Se sono in creazine della categoria dentro la materialità */
    /*   if (this.data.inMaterialita && (this.data?.cate_stakeholder?.tipologia === 'INTERNI' || this.data?.cate_stakeholder?.tipologia)) {
        this.formCateStakeholder.get('tipologia')?.setValue(this.data?.cate_stakeholder?.tipologia)
        this.formCateStakeholder.get('tipologia')?.disable();
      } */
  }

  ngOnInit(): void {
    this.stakeholderService.getTipologia().subscribe((tipologie) => {
      this.arrayTipologia = tipologie;
    });
  }

  public salva() {


    if (this.formCateStakeholder.valid) {

      const id = this.formCateStakeholder.get('id')?.value;

      if (id) {
        return this.stakeholderService.putCategorieStake(id, this.formCateStakeholder.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.stakeholderService.postCategorieStake(this.formCateStakeholder.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formCateStakeholder.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

}
