import { Directive, ElementRef, Injector, AfterViewInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormError]'
})
export class FormErrorDirective implements AfterViewInit, OnDestroy {

  private control!: NgControl | null;
  private statusChangeSub: Subscription | null = null;
  private message = '';

  private _errMsg: { [key: string]: any } = {
    required: 'Il campo è obbligatorio.',
    maxlength: 'Il campo deve essere lungo {requiredLength} caratteri.',
    minlength: 'Il campo deve essere lungo {requiredLength} caratteri.',
    pattern: 'Il campo deve contenere solo numeri non caratteri',
    max: 'Valore massimo consentito {max}',
    min: 'Valore minimo consentito {min}',
    blankValidator: 'Il campo deve contenere almeno un carattere valido.',
    validateEmail: 'E-mail non valida.',
    email: 'E-mail non valida.'
  };

  constructor(
    private el: ElementRef<HTMLElement>,
    private _inj: Injector
  ) { }

  ngAfterViewInit(): void {
    const container = this._inj.get(MatFormField, null);
    if (!container) {
      console.error('MatFormField non trovato');
      return;
    }
    this.control = container._control?.ngControl as NgControl | null;

    if (this.control?.statusChanges) {
      this.statusChangeSub = this.control.statusChanges.subscribe(() => {
        this._getTipoErrore();
      });
    }
  }

  ngOnDestroy(): void {
    this.statusChangeSub?.unsubscribe();
  }

  private _getTipoErrore(): void {
    if (this.control?.errors) {
      const error = Object.keys(this.control.errors)[0];
      switch (error) {
        case 'maxlength':
        case 'minlength':
          this.message = this._errMsg[error].replaceAll('{requiredLength}', this.control.errors[error].requiredLength);
          break;
        case 'max':
          this.message = this._errMsg[error].replaceAll('{max}', this.control.errors[error].max);
          break;
        case 'min':
          this.message = this._errMsg[error].replaceAll('{min}', this.control.errors[error].min);
          break;
        default:
          this.message = this._errMsg[error] || 'Errore sconosciuto';
          break;
      }
      this.el.nativeElement.textContent = this.message;
    } else {
      this.el.nativeElement.textContent = '';
    }
  }
}
