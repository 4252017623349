import { UnitaDiMisura, UnitaDiMisuraService } from './../../../services/unita-di-misura/unita-di-misura.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaUnitaDiMisuraComponent } from './dialog/dialog-crea-unita-di-misura/dialog-crea-unita-di-misura.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { DialogImportazioneExcelComponent } from '../../analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-cfg-unita-di-misura',
  templateUrl: './cfg-unita-di-misura.component.html',
  styleUrls: ['./cfg-unita-di-misura.component.scss']
})
export class CfgUnitaDiMisuraComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome Breve',
      forControlName: 'nomeBreve',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
    {
      forControlName: 'idCategoriaUnitaDiMisura',
      input: 'multiple-option',
      titolo: 'Categoria',
      fnDatiOption: () => (this.unitaDiMisuraService.getCategoria()),
      descValueOption: 'nome',
      idValueOption: 'id',
      riempi: true
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    titolo: {
      title: 'Nome Breve',
      value: 'nomeBreve',
      sortable: true
    },
    descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
      sortable: true
    },
    categoria: {
      title: 'Categoria',
      value: 'nomeCategoriaUnitaDiMisura',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    /*  duplica: {
       type: 'button',
       buttonIcon: 'content_copy',
       title: 'Duplica',
       buttonId: 'duplica',
     }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private unitaDiMisuraService: UnitaDiMisuraService,
    public utenteService: UtenteService
  ) { }

  ngOnInit(): void {

  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovaUnitaDiMisura(recordUnitaDiMisura?: UnitaDiMisura) {
    const dialogCreaUnitaDiMisura = this.dialog.open(DialogCreaUnitaDiMisuraComponent, {
      data: {
        unitaDiMisura: recordUnitaDiMisura,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      maxWidth: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaUnitaDiMisura.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'nomeBreve',
        sort: 'asc'
      }];
    }

    return this.unitaDiMisuraService.getUnitaDiMisura(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovaUnitaDiMisura(event.data);
        break;
      case 'elimina':
        this._eliminaUnitaDiMisura(event.data);
        break;
      case 'duplica':
        this._duplicaUnitaDiMisura();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare
   * @param idRiga id della riga da eliminare
   */
  private _eliminaUnitaDiMisura(datiRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'unità di misura?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.unitaDiMisuraService.deleteUnitaDiMisura(datiRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un UnitaDiMisura
   */
  public _duplicaUnitaDiMisura() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  public importaUnitaDM() {
    const dialogImportaUnitaDM = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione Unità di Misura',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione delle Unità di Misura',
        pathTemplateExcel: 'assets/excel/unitaDiMisura.xlsx',
        azienda: false,
        nomeImport: 'unita-di-misura',
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogImportaUnitaDM.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }
}
