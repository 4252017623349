<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Testi Template</h2>
<div mat-dialog-content class="dialog-center-cont">
    <ng-scrollbar>
        <form [formGroup]="formTemplateMail">

            <div appFormContainer [larghezza]="3" [rowGap]="20">
                <!-- ------------------------------- M A I L - I N V I O ---------------------- -->
                <div class="mail-invio">
                    <div style="padding: 10px 15px 0 15px;">
                        <div class="desc-bott">

                            <div class="contenitore-titolo-subtitolo">
                                <div class="titolo-sezione-dialog">
                                    Testo Mail Invio {{testoTemplate}}
                                </div>
                                <div class="descrizione-sezione-dialog">
                                    Questo testo personalizzato verrà inserito all'interno della prima mail inviata
                                    agli
                                    Stakeholder
                                </div>
                            </div>

                            <button [disabled]="!isInvioMail" mat-flat-button color="accent" class="accent-button"
                                (click)="testMailInvio()">
                                Test Invio
                            </button>

                        </div>
                    </div>

                    <div class="editor-cont">
                        <app-editor formControlName="mailInvio"></app-editor>
                    </div>
                </div>
                <!-- ------------------------------- M A I L - N O T I F I C A ---------------------- -->
                <div class="mail-notifica">
                    <div style="padding: 10px 15px 0 15px;">
                        <div class="desc-bott">
                            <div class="contenitore-titolo-subtitolo">
                                <div class="titolo-sezione-dialog">
                                    Testo Mail Notifica Promemoria {{testoTemplate}}
                                </div>
                                <div class="descrizione-sezione-dialog">
                                    Questo testo personalizzato verrà inserito all'interno della mail di promemoria
                                    inviata
                                    agli
                                    Stakeholder
                                </div>
                            </div>
                            <button [disabled]="!isInvioMail" mat-flat-button color="accent" class="accent-button"
                                [disabled]="!isInvioMail" (click)="testMailNotifica()">Test
                                Invio</button>
                        </div>
                    </div>
                    <div class="editor-cont">
                        <app-editor formControlName="mailNotifica"></app-editor>
                    </div>
                </div>
                <!-- ------------------------------- L A N D I N G - P A G E ---------------------- -->
                <div class="landing-page">
                    <div style="padding: 10px 15px 0 15px;">
                        <div class="desc-bott">
                            <div class="contenitore-titolo-subtitolo">
                                <div class="titolo-sezione-dialog">
                                    Testo Landing Page compilazione {{testoTemplate}}
                                </div>
                                <div class="descrizione-sezione-dialog">
                                    Questo testo verrà inserito in testata della landing page di compilazione
                                    {{testoTemplate}}
                                </div>
                            </div>
                            <button [disabled]="!isInvioMail" mat-flat-button color="accent" class="accent-button"
                                (click)="anteprima()">Anteprima</button>
                        </div>
                    </div>
                    <div class="editor-cont">
                        <app-editor formControlName="landingPage"></app-editor>
                    </div>
                </div>
            </div>
        </form>
    </ng-scrollbar>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salvaFromHTML()" class="primary-button">
            Conferma
        </button>
    </div>
</div>