import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl, FormControlName } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filters } from '../lista-tabellare/classes/lista-tabellare-data-source';
import { Filtri } from '../lista-tabellare/lista-tabellare.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FiltriService } from 'src/app/services/filtri/filtri.service';

@Component({
  selector: 'app-dialog-filtro-generico',
  templateUrl: './dialog-filtro-generico.component.html',
  styleUrls: ['./dialog-filtro-generico.component.scss']
})
export class DialogFiltroGenericoComponent implements OnInit, OnDestroy {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  formControlCampiMultiSelect: { [key: string]: FormControl } = {};
  filteredItemsCampiMultiSelect: { [key: string]: Observable<any[]> } = {};

  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);


  public arrayDatiFiltri: { [key: string]: any } = {};
  /**
   * FormGroup per la gestione dei filtri
   */
  public formFiltri: FormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<DialogFiltroGenericoComponent>,
    private readonly filtriService: FiltriService,
    @Inject(MAT_DIALOG_DATA) public data: {
      filtri: Filtri[],
      valoriFiltro: Filters[]
    }
  ) { }

  ngOnInit(): void {


    if (this.data?.filtri) {
      // se sono impostati i filtri li ciclo e creo il FormControl
      this.data.filtri.forEach((value: Filtri, index, array) => {

        if (value?.forControlName && value.input !== 'multiple-option' && value.input !== 'multiple-option-chip') {
          this.formFiltri.addControl(
            value.forControlName,
            new FormControl(value.value || null)
          );
        } else if (value?.forControlName && value.input === 'multiple-option') {
          this.formFiltri.addControl(
            value.forControlName,
            new FormControl([])
          );

        } else if (value?.forControlName && value.input === 'multiple-option-chip') {

          // Aggiungi il FormControl a formControlCampiMultiSelect ( viene usato per gestire l'autocomplete )
          this.formControlCampiMultiSelect[value.forControlName] = new FormControl();

          this.formFiltri.addControl( //Form control dove ci sono i valori selezionati, i chip
            value.forControlName,
            new FormControl(value.value || null)
          );

          if (value.fnDatiOption) {

            // Crea l'Observable per filteredItemsCampiMultiSelect
            this.filteredItemsCampiMultiSelect[value.forControlName] = this.formControlCampiMultiSelect[value.forControlName]?.valueChanges.pipe(
              debounceTime(250),
              startWith(undefined),
              switchMap((ricerca: string | undefined) => value.fnDatiOption!(ricerca)),
            );
          }
        }


        if (value.input !== 'multiple-option-chip') {
          // metodo da fare dentro la funzione multipla
          if (value?.fnDatiOption && value?.forControlName) {
            value.fnDatiOption(
            ).subscribe((risp) => {
              this.arrayDatiFiltri[value?.forControlName || ''] = risp;
            });
          }
        }

        const valFiltro = this.data?.valoriFiltro.find((valFil) => {
          return valFil.chiave === value?.forControlName;
        })

        if (valFiltro) {
          if (value?.forControlName && (value.input === 'multiple-option' || value.input === 'multiple-option-chip')) {
            this.formFiltri.get(valFiltro.chiave)?.setValue(valFiltro.values);
          } else {
            this.formFiltri.get(valFiltro.chiave)?.setValue(valFiltro.values[0]);

          }
        }

      });
    }
  }

  ngOnDestroy(): void {

  }

  applicaFiltri() {


    const datiFiltri = this.filtriService.costruisciJsonFiltri(this.formFiltri.getRawValue(), this.data.filtri || []);

    this.dialogRef.close({
      filtri: datiFiltri,
    });
  }

  cancellaFiltri() {
    this.formFiltri.reset();
  }


  /* ----------------  MULTIPLE-OPTION-CHIP -----------------*/

  add(event: MatChipInputEvent, formCtrName: string | undefined): void {
    const value = (event.value || '').trim();

    // Add nuovo elemento selezionato
    if (value) {
      if (formCtrName) {
        const arrayChip = this.formFiltri.get(formCtrName)?.value || [];
        arrayChip.push(value);
        this.formFiltri.get(formCtrName)?.setValue(arrayChip);
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    if (formCtrName) {

      this.formControlCampiMultiSelect[formCtrName].setValue(null);

    }
  }

  remove(elemSel: string, formCtrName: string | undefined): void {
    if (formCtrName) {

      const arrayChip = this.formFiltri.get(formCtrName)?.value || [];

      const index = arrayChip.indexOf(elemSel);
      if (index >= 0) {
        arrayChip.splice(index, 1);
        this.announcer.announce(`Removed ${elemSel}`);
        this.formFiltri.get(formCtrName)?.setValue(arrayChip);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent, formCtrName: string | undefined): void {
    if (formCtrName) {

      const arrayChip = this.formFiltri.get(formCtrName)?.value || [];
      arrayChip.push(event.option.viewValue);

      this.itemInput.nativeElement.value = '';

      this.formControlCampiMultiSelect[formCtrName].setValue(null);

      this.formFiltri.get(formCtrName)?.setValue(arrayChip);
    }
  }

}

