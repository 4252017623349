<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Materialità" subTitolo="Gestione della materialità"></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovo()" *ngIf="
                !isPermessoSoloRead()">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>