import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaPolicySostenibilitaComponent } from './dialog/dialog-crea-policy-sostenibilita/dialog-crea-policy-sostenibilita.component';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-policy-sostenibilita',
  templateUrl: './policy-sostenibilita.component.html',
  styleUrls: ['./policy-sostenibilita.component.scss']
})
export class PolicySostenibilitaComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  private azienda: Azienda | undefined = undefined;
  public filtri: Filtri[] = [
    {
      titolo: 'Anno',
      forControlName: 'anno',
      input: 'text',
    },
  ];
  private isMostra: boolean = false;
  public colonne: { [key: string]: Colonna } = {
    anno: {
      title: 'Anno',
      value: 'anno',
      sortable: true
    },
    settore: {
      title: 'Settore',
      sortable: true,
      campiSort: ['settore'],
      value: (record: any) => (record?.settori[0]?.titolo || ''),
    },
    risultatiImpegni: {
      title: 'Impegni',
      value: (record: any) => (record?.compilazioni?.impegni?.compilati || 0) + '/' + (record?.compilazioni?.impegni?.totali || 0)
    },
    risultatiAzioni: {
      title: 'Azioni',
      value: (record: any) => (record?.compilazioni?.azioni?.compilati || 0) + '/' + (record?.compilazioni?.azioni?.totali || 0)
    },
    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead() ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead() ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => this.nascondiBottoneElimina(record),
      buttonMostraSempre: true
    },
  };

  private _subAziendaCorrente: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private aziendaService: AziendaService,
    private policyService: PolicyService,
    public utenteService: UtenteService,
    private licenzeService: LicenzeService
  ) { }

  nascondiBottoneElimina(record: any) {
    if (this.utenteService.infoUtente?.isDeveloper) {
      return false; //non nascondere
    }
    if ((record.stato === 'PUBBLICATO' || !this.utenteService.isPermessoAttivo('DELETE_POLICY'))) {
      return true //nascondere
    }
    return false
  }

  isPermessoSoloRead() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_POLICY');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_POLICY')
    return (!isEdit && !isCreate)
  }

  ngAfterViewInit(): void {

    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });



  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.policyService.getPolicySostenibilita(page, pageSize, ricerca, filters, sortBy);
  };

  /**
   * Metodo di modifica o creazione nuova policy 
   * @param record 
   */
  crea(record?: any) {
    if (!record) {
      this.spinnerOver.show();
      this.licenzeService.getUserHasLicense('Policy').subscribe({
        next: (esito) => {
          this.apriDialogCreaPolicy(record);
          this.spinnerOver.hide();
        },
        error: (err) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message,
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.apriDialogCreaPolicy(record);
    }
  }

  private apriDialogCreaPolicy(record: any) {
    const dialogCreaPolicy = this.dialog.open(DialogCreaPolicySostenibilitaComponent, {
      data: { policy: record },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaPolicy.afterClosed().subscribe(() => {
      // Ricarica i dati della tabella dopo la chiusura del dialogo
      this.tabella.caricaDati();
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
  * Funzione per eliminare una riga da una lista tabellare 
  * @param idRiga id della riga da eliminare 
  */
  private _elimina(policy: any) {

    let descrizioneElimina: string;
    let titolo: string;
    descrizioneElimina = '<div> <p><strong> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</strong></p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p><strong>Sei sicuro di voler continuare?</strong></p>'
    titolo = 'Eliminazione Dati Irreversibile'

    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.policyService.deletePolicySostenibilita(policy?.id).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );

          }
        }]
    });

  }
}
