<div class="contenitore">
    <mat-accordion class="accordion">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Cruscotto Generale
                </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="cont-exp">
                <!-- <div style="display: flex; column-gap: 20px;">
                    <div class="cont-graf">
                        GRAFICO 1
                    </div>
                    <div class="cont-graf">
                        GRAFICO 2
                    </div>
                </div> -->
                <div class="cont-graf">
                    <div class="titolo-grafico">
                        Disponibilità Pacchetti
                    </div>
                    <app-chart-host chartType="bar">
                        <canvas baseChart height="1" width="4" [data]="barChartData" [options]="barChartOptions"
                            [type]="barChartType" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)">
                        </canvas>
                    </app-chart-host>
                </div>

                <div class="cont-tab">

                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div class="titolo-grafico">
                            Riepilogo Generale
                        </div>
                        <div style="display: flex; column-gap: 10px; transform: scale(0.8);">
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>download</mat-icon>
                            </button>
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                    <app-lista-tabellare #tabellaRiepilogo [fnCaricamentoDati]="fnCaricamentoDati"
                        [colonne]="colonneRiepilogo ? colonneRiepilogo : {} " [maxNrButton]="1" [isFiltri]="false">
                    </app-lista-tabellare>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Pianificazione Attività
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cont-exp">
                <div class="cont-tab">
                      Tabella 2 
                </div>
            </div>
        </mat-expansion-panel> -->
    </mat-accordion>
</div>