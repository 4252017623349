import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Colonna, BottoniListaEvent, Filtri } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaImpegnoComponent } from './dialog/dialog-crea-impegno/dialog-crea-impegno.component';
import { ImpegniService, Impegno } from 'src/app/services/impegni/impegni.service';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { map } from 'rxjs';
import { DialogImportazioneExcelComponent } from '../../analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-cfg-impegni',
  templateUrl: './cfg-impegni.component.html',
  styleUrls: ['./cfg-impegni.component.scss']
})
export class CfgImpegniComponent {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [

    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true
    },
    {
      titolo: 'TAG',
      forControlName: 'tag',
      input: 'multiple-option-chip',
      riempi: true,
      larghezza: 3,
      fnDatiOption: (ricerca: string | undefined) => this.impegniService.getImpegniTags(0, 1000, ricerca),
      numeroRecOptions: Infinity
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    /* duplica: {
      type: 'button',
      buttonIcon: 'content_copy',
      title: 'Duplica',
      buttonId: 'duplica',
    }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(
    public dialog: MatDialog,
    private impegniService: ImpegniService,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    public utenteService: UtenteService

  ) { }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovoImpegno(recordImpegno?: Impegno) {
    const dialogCreaImpegno = this.dialog.open(DialogCreaImpegnoComponent, {
      data: {
        impegno: recordImpegno,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaImpegno.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'titolo',
        sort: 'asc'
      }];
    }

    return this.impegniService.getImpegni(page, pageSize, ricerca, filters, sortBy);
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovoImpegno(event.data);
        break;
      case 'elimina':
        this._eliminaImpegno(event.data.id);
        break;
      case 'duplica':
        this._duplicaImpegno();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare
   * @param idRiga id della riga da eliminare
   */
  private _eliminaImpegno(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'Impegno?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();

            this.impegniService.deleteImpegno(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un Impegno
   */
  public _duplicaImpegno() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  public importaImpegni() {
    const dialogImportaImpegni = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione Impegni',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione degli impegni',
        pathTemplateExcel: 'assets/excel/impegni.xlsx',
        azienda: false,
        nomeImport: 'impegni',
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '60%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogImportaImpegni.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }
}
