<app-spinner-overlay></app-spinner-overlay>
<form [formGroup]="formLicenze" appFormContainer [larghezza]="6">

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Assessment</mat-label>
        <input matInput placeholder="Nr. Assessment" formControlName="nrAutovalutazioni" type="number" min="0"
            appInputNumber max="999999999999" (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Bilancio</mat-label>
        <input matInput placeholder="Nr. Bilancio" formControlName="nrBilanci" type="number" min="0" appInputNumber
            max="999999999999" (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isSostenibileGo" appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Sintesi</mat-label>
        <input matInput placeholder="Nr. Sintesi" formControlName="nrSintesi" type="number" min="0" appInputNumber
            max="999999999999" (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isSostenibileGo" appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Materialità</mat-label>
        <input matInput placeholder="Nr. Materialità" formControlName="nrMaterialita" type="number" min="0"
            appInputNumber max="999999999999" (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isSostenibileGo" appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Policy</mat-label>
        <input matInput placeholder="Nr. Policy" formControlName="nrPolicy" type="number" min="0" max="999999999999"
            appInputNumber (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isSostenibileGo" appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Piano</mat-label>
        <input matInput placeholder="Nr. Piano" formControlName="nrPiano" type="number" min="0" max="999999999999"
            appInputNumber (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
        <mat-label>Nr. Aziende</mat-label>
        <input matInput placeholder="Nr. Aziende" formControlName="nrMaxAziende" type="number" min="0"
            max="999999999999" appInputNumber (keydown)="onKeyDown($event)" inputmode="numeric" />
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1.5" [riempi]="true">
        <mat-label>Data di Attivazione</mat-label>
        <input matInput [matDatepicker]="pickerDataAttivazione" [matDatepickerFilter]="dateFilter" formControlName="dataAttivazione"
            (dateChange)="onDataAttivazioneChange($event)">
        <mat-datepicker-toggle matIconSuffix [for]="pickerDataAttivazione"></mat-datepicker-toggle>
        <mat-datepicker #pickerDataAttivazione></mat-datepicker>
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1.5" [riempi]="true">
        <mat-label>Data di Scadenza</mat-label>
        <input matInput [matDatepicker]="pickerDataScandeza" [matDatepickerFilter]="dateFilter" formControlName="dataScadenza">
        <mat-datepicker-toggle matIconSuffix [for]="pickerDataScandeza"></mat-datepicker-toggle>
        <mat-datepicker #pickerDataScandeza></mat-datepicker>
        <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
        <mat-label>Codice Promotore (rivenditore)</mat-label>
        <mat-select formControlName="codicePromotore" placeholder="Codice Promotore (rivenditore)">
            <mat-option *ngFor="let pro of arrayCodicePromotore" [value]="pro.codice">{{pro.codice}} -
                {{pro.descrizione}}</mat-option>
        </mat-select>
    </mat-form-field>

</form>