import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { forkJoin, map, Observable } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { CfgStampa, ConfigStampeService } from 'src/app/services/config-stampe/config-stampe.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-cfg-linee-guida-stampe',
  templateUrl: './cfg-linee-guida-stampe.component.html',
  styleUrls: ['./cfg-linee-guida-stampe.component.scss']
})
export class CfgLineeGuidaStampeComponent implements AfterViewInit {

  public removePlugins = [
    'BlockQuote',
    'FontBackgroundColor',
    'FontColor',
    'FontSize',
    'Indent',
    'IndentBlock',
    'Link',
    'PasteFromOffice',
    'Style',
    'Table',
    'TableToolbar',
    'TextTransformation'
  ];
  
  private _contesti: {
    tipo: ('QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY' | 'PIANO' | 'BILANCIOGO' | 'AUTOVALUTAZIONE'),
    label: string,
    config: CfgStampa[],
    mailInvio: string
    mailNotifica: string,
    landingPage: string,
  }[] = [
      {
        tipo: 'POLICY',
        label: 'Policy',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'BILANCIO',
        label: 'Bilancio',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'SINTESI',
        label: 'Sintesi',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'SURVEY',
        label: 'Survey',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'PIANO',
        label: 'Piano',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'BILANCIOGO',
        label: 'Report Tematico',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
      {
        tipo: 'AUTOVALUTAZIONE',
        label: 'Assessment',
        config: [],
        mailInvio: '',
        mailNotifica: '',
        landingPage: '',
      },
    ];

  get contesti() {
    return this._contesti;
  }

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('scrollConfStampa') scrollConfStampa!: NgScrollbar;
  @ViewChild('scrollConfMail') scrollConfMail!: NgScrollbar;

  public salvataggioInCorso: { [key: string]: boolean } = {};

  constructor(
    private utilityService: UtilityService,
    private configStampeService: ConfigStampeService
  ) { }

  ngAfterViewInit() {
    // this.caricaLineeGuidaContesto('POLICY')

    const obsObj: { [key: string]: Observable<boolean> } = {};

    this._contesti.forEach((val) => {
      obsObj[val.tipo] = this.caricaLineeGuidaContesto(val.tipo)
    })

    forkJoin(obsObj).subscribe({
      next: (result) => {

        const esito = Object.keys(result).map((key) => result[key]).every((val) => val === true);

        if (esito) {

          this.spinnerOver.hide();

        } else {

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Non è stato possibile recuperare le linee guida.',
            bottoni: [{ nome_btn: 'Ok' }]
          })

          this.spinnerOver.hide();
        }

      },
      error: (err => {
        console.error('errore get config stampe', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Non è stato possibile recuperare le linee guida.',
          bottoni: [{ nome_btn: 'Ok' }]
        })

        this.spinnerOver.hide();
      })

    });

  }

  caricaLineeGuidaContesto(contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY' | 'PIANO' | 'BILANCIOGO'| 'AUTOVALUTAZIONE'): Observable<boolean> {
    this.spinnerOver.show();

    return this.configStampeService.getLineeGuida(contesto).pipe(
      map((result) => {
        const cfg = this._contesti.find((dati) => dati.tipo === contesto);

        if (cfg) {
          cfg.config = result?.cfgStampa;
          cfg.mailInvio = result?.mailInvio;
          cfg.mailNotifica = result?.mailNotifica;
          cfg.landingPage = result?.landingPage;
        }

        setTimeout(() => {
          this.scrollConfStampa.update();
          this.scrollConfMail.update();
        }, 0);

        return true;
      })
    );

  }

  /**
   * Metodo che mi ordina la visualizzazione degli header drag and drop, e l'index dell'oggetto spostato
   * @param event 
   */
  dropCfgStampa(event: any) {
    /* if (this.indicePolicy) {
      moveItemInArray(this.indicePolicy, event.previousIndex, event.currentIndex);

      if (event.previousIndex === this.indicePanel) {
        this.indicePanel = event.currentIndex;
      }

      // Aggiorna gli indici di tutti gli elementi nella lista
      this.indicePolicy.forEach((obj, index) => {
        obj.index = index;
      });

    } */
  }

  openGroup(event: any) {
    /* if (this.indicePolicy) {
      this.indicePanel = event.index;
    } */
  }

  salva(contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY' | 'PIANO' | 'BILANCIOGO'| 'AUTOVALUTAZIONE', cfg: CfgStampa) {
    console.log(contesto, cfg);

    this.salvataggioInCorso[cfg.chiave] = true;

    return this.configStampeService.salvaLineaGuida(cfg, contesto).subscribe({
      next: (result) => {

        console.log(result);

        this.utilityService.opneSnackBar("Salvataggio effettuato", '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });


        this.salvataggioInCorso[cfg.chiave] = false;

      },
      error: (err) => {

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Non è stato possibile salvare la linea guida.',
          bottoni: [{ nome_btn: 'Ok' }]
        })

        this.salvataggioInCorso[cfg.chiave] = false;
      }
    });

  }

  salvaCfgMail(contesto: 'QUESTIONARIO_GENERICO' | 'AUTOVALUTAZIONE' | 'SURVEY' | 'BILANCIO' | 'SINTESI' | 'INFORMATIVA_AZIENDALE' | 'POLICY' | 'PIANO' | 'BILANCIOGO'| 'AUTOVALUTAZIONE', mailNotifica: string, mailInvio: string, landingPage: string) {
    console.log(contesto, mailInvio, mailNotifica, landingPage);

    return this.configStampeService.salvaTemplateMail(mailNotifica, mailInvio, landingPage, contesto).subscribe({
      next: (result) => {

        console.log(result);

        this.utilityService.opneSnackBar("Salvataggio effettuato", '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });

      },
      error: (err) => {

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Non è stato possibile salvare i template mail.',
          bottoni: [{ nome_btn: 'Ok' }]
        })

        /*    this.salvataggioInCorso[cfg.chiave] = false; */
      }
    });

  }
}
