<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Certificazioni</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formCertif" appFormContainer [larghezza]="1">

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Nome</mat-label>
            <input matInput placeholder="Nome" formControlName="nomeBreve" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <textarea matInput placeholder="Descrizione" formControlName="descrizione" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field class="input-form" appFormElement [larghezza]="1" [riempi]="true"
            *ngIf="this.data.contesto !== 'sintesi' && this.data.contesto !== 'bilancio'  && this.data.contesto !== 'policy'">
            <mat-label>Settori</mat-label>
            <mat-select formControlName="settori" placeholder="Settori" multiple [compareWith]="compareSettori">
                <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
