import { ValoriKpi } from 'src/app/services/KPI/kpi.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, filter, map, Observable, of, throwError } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { CompilaSintesiSostenibilitaComponent } from 'src/app/components/compila-sintesi-sostenibilita/compila-sintesi-sostenibilita.component';
import { TemaService } from 'src/app/services/tema/tema.service';

@Component({
  selector: 'app-bilancio-sostenibilita-esterna',
  templateUrl: './bilancio-sostenibilita-esterna.component.html',
  styleUrls: ['./bilancio-sostenibilita-esterna.component.scss']
})
export class BilancioSostenibilitaEsternaComponent implements OnInit, AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CompilaSintesiSostenibilitaComponent) compilaSintesiSostenibilita!: CompilaSintesiSostenibilitaComponent;

  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public completato: boolean = false;
  public temporaryToken!: string;
  public descrizione: string = "";
  public messaggio: string = '';

  public compilazione: any;
  public idBilancioSostenibilita: string = "";
  private idAzienda: string = "";
  public landingPage: string = "";
  public isAnteprima: boolean = false;
  public isUtenteGo: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private bilancioService: BilancioService,
    private temaService: TemaService,
  ) {

    this.temaService.temaUpdated.subscribe(tipoInstallazione => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parametriDaURL();
  }

  /**
 * Metodo che mi prende il token dal URL
 */
  public parametriDaURL() {
    // Sottoscrivi all'observable paramMap per ottenere i parametri dall'URL
    this.spinnerOver.show();
    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue
      this.temporaryToken = params['temporaryToken'];
      this.idAzienda = params['idA'];
      this.idBilancioSostenibilita = params['idB'];
      if (this.temporaryToken) {
        this._getSurveyByTemporaryToken();
      } else if (this.idBilancioSostenibilita && this.idAzienda) {
        this._anteprimaBilancio();
      }
    });
  }

  private _anteprimaBilancio() {
    this.isAnteprima = true;
    this.spinnerOver.show();
    this.bilancioService.getAnteprimaBilancioCompilato(this.idBilancioSostenibilita, this.idAzienda).subscribe({
      next: (esito) => {

        this.spinnerOver.hide();
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }


  /**
 * Metodo che mi prende i dati della survey dal token
 */
  private _getSurveyByTemporaryToken() {
    this.isAnteprima = false;
    this.bilancioService.getElementiEsterniDaCompilare(
      this.temporaryToken
    ).subscribe({
      next: (esito) => {
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.messaggio = err?.error?.message || "Nessun dato trovato";
        this.completato = true;
        this.spinnerOver.hide();
      }
    })
  }


  loading = (loading: boolean) => {
    if (loading) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }


  salva = (compilazione: any, isImportazione?: boolean): Observable<any> => {
    if (compilazione) {
      this.spinnerOver.show();

      let arrayRequest: any[] = [];

      (this.compilazione.ambiti as Ambito[]).forEach((ambito) => {
        ambito.tematiche.forEach((tematica) => {

          const objCall = {
            idAmbito: ambito.id,
            idTematica: tematica.id,
            idElemento: '',
            idUnitaDiRiferimento: '',
            idCategoriaUnitaDiMisura: '',
            risposta: ''
          }

          if (tematica.impegni?.length) {
            tematica.impegni.forEach((impegno) => {
              arrayRequest.push({
                ...objCall, idElemento: impegno.id,
                risposta: impegno.risposta
              });
            })
          }


          if (tematica.kpis?.length) {
            tematica.kpis.forEach((kpi) => {
              const valoriKpi = kpi.valoriKpi || [];
              const valoriTarget = kpi.valoriTarget || [];

              const risposta = [
                ...valoriKpi,
                ...valoriTarget
              ];
              arrayRequest.push({
                ...objCall, idElemento: kpi.id,
                idUnitaDiRiferimento: kpi.idUnitaDiRiferimento,
                idCategoriaUnitaDiMisura: kpi.idCategoriaUnitaDiMisura,
                risposta: risposta
              });

            })
          }

          if (tematica.impatti?.length) {
            tematica.impatti.forEach((impatti) => {
              arrayRequest.push({
                ...objCall, idElemento: impatti.id,
                risposta: impatti.valoreAttribuito
              });
            })
          }

          if (tematica.azioni?.length) {
            tematica.azioni.forEach((azione) => {
              arrayRequest.push({
                ...objCall, idElemento: azione.id,
                risposta: azione.risposta
              });
            })
          }

          if (tematica.certificazioni?.length) {
            tematica.certificazioni.forEach((certif) => {
              arrayRequest.push({
                ...objCall, idElemento: certif.id,
                risposta: certif.risposta
              });
            })
          }

        })
      })

      const arrayRequestFiltered = arrayRequest.filter((elem) => {
        if (elem.risposta !== null && elem.risposta !== undefined) {
          // Se elem.risposta è un array, controlla i valori interni
          if (Array.isArray(elem.risposta)) {
            return elem.risposta.some((risp: any) =>
              risp?.valoreAttribuito !== null && risp?.valoreAttribuito !== undefined
            );
          }
          // Se non è un array, ritorna true perché risposta è valida (non null o undefined)
          return true;
        }
        // escludo se elem.risposta è null o undefined
        return false;
      });

      return this.bilancioService.postElementiEsterniDaCompilare(
        this.temporaryToken,
        arrayRequestFiltered
      ).pipe(
        map(risp => {
          this.spinnerOver.hide();
          if (!isImportazione) {
            this.messaggio = 'Le tue risposte sono state salvate. Grazie.'
            this.completato = true;
          }
        }),
        catchError(err => {
          this.spinnerOver.hide();
          console.error(err);
          return throwError(() => err);
        })
      )
    }
    return of();
  }
}
