import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { Responsabile, RuoliList, Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { finalize, firstValueFrom, tap } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { GestioneLicenzeComponent } from '../../gestione-licenze/gestione-licenze.component';
import { LicenzeService, PromotoreLicenze } from 'src/app/services/licenze/licenze.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { RiepilogoLicenzeComponent } from '../../riepilogo-licenze/riepilogo-licenze.component';
import { DashboardComponent } from 'src/app/page/dashboard/dashboard.component';
import { DialogRiepilogoLicenzeComponent } from 'src/app/page/utenti/dialog/dialog-riepilogo-licenze/dialog-riepilogo-licenze.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },  // Home page
  { path: 'riepilogo-licenzze', component: RiepilogoLicenzeComponent }, // Pagina di destinazione
]

@Component({
  selector: 'app-dialog-crea-utenti',
  templateUrl: './dialog-crea-utenti.component.html',
  styleUrls: ['./dialog-crea-utenti.component.scss'],
})
export class DialogCreaUtentiComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(GestioneLicenzeComponent) gestioneLicenzeComponent!: GestioneLicenzeComponent;

  public arrayRuoli: RuoliList[] = [];
  public objResponsabile: Responsabile[] = [];
  public arrayAziende: Azienda[] = [];
  public selectedAzienda: string[] = [];
  public isPermessoSoloRead: boolean = (!this.utenteService.isPermessoAttivo('EDIT_AZIENDA') && !this.utenteService.isPermessoAttivo('CREATE_AZIENDA'))
  private ruoloScelto: string = '';
  public loadingResponsabile: boolean = false;
  private idUtenteSalvato: string | undefined = undefined;

  /* Form Controls */
  public formUtenti = new FormGroup({

    /* Nome */
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* Cognome */
    lastName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* email */
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email, UtilityService.emailValidator],
    }),

    /* Ruoli Utente */
    ruolo: new FormControl<RuoliList | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    /* Responsabile */
    utenteResponsabile: new FormControl<Responsabile | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    /* aziende */
    idAziende: new FormControl<string[]>([]),

    /* username  */
    username: new FormControl<string>(''),
    /* attivo */
    attivo: new FormControl<boolean>(true),
    /* keyId */
    keyId: new FormControl<string | undefined>(undefined),
    /* CMSId */
    CMSId: new FormControl<string | undefined>(undefined),
    /* id */
    id: new FormControl<string | undefined>(undefined),

    /* Ruoli Utente */
    codiceLicenza: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  });


  constructor(
    public dialogRef: MatDialogRef<DialogCreaUtentiComponent>,
    public utenteService: UtenteService,
    public aziendaService: AziendaService,
    private readonly utilityService: UtilityService,
    private readonly dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router,
    public activatedRoute: ActivatedRoute,
    public licenzeService: LicenzeService,
    @Inject(MAT_DIALOG_DATA) public data: {
      utente: Utente,
      idAzienda: string
    }
  ) {
  }

  ngAfterViewInit(): void {


    if (this.data?.utente?.ruolo?.roleName) {
      this.ruoloScelto = this.data.utente.ruolo.roleName;
    }

    /* Se sono in modifica di un utente, disabilito la form licenze
   perché non si possono modificare le licenze base */
    if (this.data?.utente?.id && this.canSeeLicenze()) {
      this.gestioneLicenzeComponent.formLicenze.disable();
      this.gestioneLicenzeComponent.formLicenze.get('dataScadenza')?.enable();
    }

    const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName;

    if (ruoloUtenteLogin && ['collaboratore', 'collaboratore_go'].includes(ruoloUtenteLogin)) {
      this.formUtenti.get('idAziende')?.addValidators(Validators.required);
      this.formUtenti.get('utenteResponsabile')?.addValidators(Validators.required);
      this.formUtenti.get('idAziende')?.updateValueAndValidity();
      this.formUtenti.get('utenteResponsabile')?.updateValueAndValidity();
    }

    this.ctrDisableCampi();
    this._getRuoli();
    this._getUtentiPerCollaboratore(this.data.utente?.ruolo?.roleName);
    this.datiIniziali();
    this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche
  }
  /**
   * Nel cambio di un ruolo utente
   * @param event 
   */
  onSelectionChangeRuolo(event: MatSelectChange) {
    this.ruoloScelto = event.value.roleName;
    const form = this.formUtenti;
    const isRuoloSceltoCollaboratore = this.ruoloScelto === 'collaboratore' || this.ruoloScelto === 'collaboratore_go';
    const isReadOnly = this.ruoloScelto === 'utente_read_only' || this.ruoloScelto === 'utente_read_only_go';
    const utenteLogin = this.utenteService.infoUtente?.utente
    const isCurrentUserConfiguratore = utenteLogin?.ruolo?.roleName === 'configuratore';

    this.gestioneformLicenze();
    console.log('RUOLO : ' + this.ruoloScelto);

    if (this.ruoloScelto === 'configuratore') {
      this._getAziendeView([{
        values: [this.ruoloScelto],
        operator: 'eq',
        chiave: 'ruolo'
      },]);
    } else if (this.ruoloScelto === 'utente_premium') {
      setTimeout(() => {
        this.gestioneLicenzeComponent.setNrMaxAziende(1);
      }, 0);
    } else if (this.ruoloScelto === 'professionista') {
      setTimeout(() => {
        this.gestioneLicenzeComponent.setNrMaxAziende(999);
      }, 0);
    }

    // Se il ruolo selezionato è 'collaboratore' e l'utente corrente è un 'configuratore'
    if (isRuoloSceltoCollaboratore && isCurrentUserConfiguratore) {
      // Resetta i campi 'utenteResponsabile' e 'idAziende'
      form.get('utenteResponsabile')?.setValue(undefined);
      form.get('utenteResponsabile')?.addValidators(Validators.required);
      form.get('idAziende')?.setValue([]);

      // Recupero responsabili
      this._getUtentiPerCollaboratore(this.ruoloScelto);

    }
    else {
      // Rimuove i validatori se non applicabile
      form.get('utenteResponsabile')?.clearValidators();
      if (!isRuoloSceltoCollaboratore) {
        this._getAziendeGoOrCloud(this.ruoloScelto);
      }
    }

    // Aggiunge o rimuove i validatori in base al ruolo
    if (isRuoloSceltoCollaboratore || isReadOnly) {
      form.get('idAziende')?.addValidators(Validators.required);

      let filtroAziende: Filters[] = [{
        values: utenteLogin?.idAziende || [],
        operator: 'eq',
        chiave: 'id'
      },
      {
        values: [this._getTipoInstallazione(event.value?.roleName)],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      },
      {
        values: [this.ruoloScelto],
        operator: 'eq',
        chiave: 'ruolo'
      },
      ]
      this._getAziendeView(filtroAziende)
    } else {
      form.get('idAziende')?.clearValidators();
    }

    // Aggiorna la validità dei campi
    form.get('utenteResponsabile')?.updateValueAndValidity();
    form.get('idAziende')?.updateValueAndValidity();
  }

  /**
   * Nel cambio del responsabile se selezionato come ruolo Collaboratore
   * @param event 
   */
  onSelectionChangeResponsabile(event: MatSelectChange) {
    console.log(event.value);

    let filtroAziende: Filters[] = [{
      values: event.value.idAziende,
      operator: 'eq',
      chiave: 'id'
    },
    {
      values: [this._getTipoInstallazione(event.value.ruolo.roleName)],
      operator: 'eq',
      chiave: 'tipoInstallazione'
    },
    {
      values: [this.ruoloScelto],
      operator: 'eq',
      chiave: 'ruolo'
    },
    ]
    this._getAziendeView(filtroAziende)
  }

  /**
   * Salvataggio utente e creazione licenza
   * Viene chiamato da HTML
   * @returns 
   */
  async salvaUtente(keepOpen?: boolean): Promise<boolean> {

    keepOpen = !!keepOpen;

    const idUtente = this.data?.utente?.id;
    const codiceLicenza = this.data?.utente?.codiceLicenza
    const codicePacchetto = this.gestioneLicenzeComponent?.formLicenze?.get('codicePacchetto')?.value

    if (!(await this.salva())) return false;

    // Se l'utente in creazione eredità le licenze allora non devo SALVARE / AGGIORNARE le licenze.
    if (!this.canSeeLicenze()) {
      if (!keepOpen) {
        this.dialogRef.close();
      }
      return false;
    }

    //Aggiorno la Licenza se sono in modifica di un utente
    if (idUtente && codiceLicenza && codicePacchetto) {

      if (!(await this.gestioneLicenzeComponent.aggiornaLicenzaBase(idUtente, codiceLicenza, codicePacchetto))) return false;

      if (!keepOpen) {
        this.dialogRef.close();
      }
      return true;

    }

    // Creo la licenza se sono in una nuova creazione 
    if (this.idUtenteSalvato) {

      if (!(await this.gestioneLicenzeComponent.salvaLicenzaBase(this.idUtenteSalvato))) return false;
      if (!keepOpen) {
        this.dialogRef.close();
      }
      return true;

    }

    return false;
  }

  /**
  * Funzione di salvataggio di un nuovo Utente
  * @returns 
  */
  async salva(): Promise<boolean> {

    if (!this.formUtenti.valid) {
      Object.values(this.formUtenti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return false;
    }

    if (this.canSeeLicenze()) {
      if (!this.gestioneLicenzeComponent.isFormLicenzeValid()) return false;
    }

    const keyId = this.formUtenti.get('keyId')?.value;
    const ruoloSelezionato = this.formUtenti.get('ruolo')?.value?.roleName;

    if (ruoloSelezionato !== 'collaboratore' && ruoloSelezionato !== 'collaboratore_go') {
      this.formUtenti.get('utenteResponsabile')?.setValue(undefined);
    }

    this.spinnerOver.show();

    if (keyId) {

      // AGGIORNAMENTO UTENTE
      try {
        await firstValueFrom(
          this.utenteService.putUtente(keyId,
            this.formUtenti.getRawValue())
            .pipe(
              finalize(() => this.spinnerOver.hide()),
            )
        );

        this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        return true;
      } catch (err: any) {
        console.error('Errore durante l\'aggiornamento dell\'Utente: ', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || 'Errore durante l\'aggiornamento dell\'Utente',
          bottoni: [{ nome_btn: 'Chiudi' }]
        },
          500);
        return false;
      }

    } else {

      // CREAZIONE UTENTE
      try {
        await firstValueFrom(
          this.utenteService.postUtente(this.formUtenti.getRawValue())
            .pipe(
              tap((esito: any) => {
                this.idUtenteSalvato = esito?.id
              }),
              finalize(() => this.spinnerOver.hide())
            )
        );

        this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
          duration: 2000,
          panelClass: ['success-snackbar'],
        });
        return true;

      } catch (err: any) {
        console.error('Errore durante il salvataggio dell\'Utente:', err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || 'Errore nel salvataggio dell\'Utente',
          bottoni: [{ nome_btn: 'Chiudi' }]
        },
          500);
        return false;
      }
    }
  }

  compareRuoli(ruolo1: RuoliList, ruolo2: RuoliList): boolean {
    return ruolo1?.roleName === ruolo2?.roleName; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  compareResponsabile(resp1: Responsabile, resp2: Responsabile): boolean {
    return resp1?.keyId === resp2?.keyId;
  }

  private _getRuoli() {

    let obsRuoli: any;

    if (this.data?.utente?.id) {
      obsRuoli = this.utenteService.getRuoli();
    } else {
      obsRuoli = this.utenteService.getRuoliCreate()
    }

    this.spinnerOver.show();
    obsRuoli.pipe(
      finalize(() => this.spinnerOver.hide())
    )
      .subscribe(
        {
          next: (result: any) => {

            this.arrayRuoli = result || [];

          },
          error: (err: any) => {
            console.error('Errore durante il caricamento dei Ruoli:', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel recupero dei Ruoli',
              bottoni: [{ nome_btn: 'Ok' }]
            })
          }
        }
      )
  }

  private _getAziendeView(filtroAzienda?: Filters[]) {

    if (!filtroAzienda) {
      filtroAzienda = [];
    }

    this.spinnerOver.show();
    this.aziendaService.getAziendeView(0, 1000, '', filtroAzienda, [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }])
      .pipe(
        finalize(() => this.spinnerOver.hide())
      )
      .subscribe({
        next: (result: any) => {

          this.arrayAziende = result?.content || [];

          this.formUtenti.get('idAziende')?.setValue(
            (this.formUtenti.get('idAziende')?.value || []).filter((val) => {
              return !!this.arrayAziende.find(ditta => ditta.id === val);
            })
          );

        },
        error: (err: any) => {
          console.error('Errore durante il caricamento delle aziende:', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle aziende',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
  }

  public async datiIniziali() {

    if (this.data?.utente) {

      Object.keys(this.data.utente).forEach((value, index, array) => {

        if (value === 'utenteResponsabile') {
          this.formUtenti.get('utenteResponsabile')?.setValue(this.data.utente.utenteResponsabile);
        }

        this.formUtenti.get(value)?.setValue((this.data.utente as any)[value]);
      })
      this.formUtenti.controls.email.disable();

      if (this.canSeeLicenze()) {
        this.gestioneLicenzeComponent.getLicenzeBase(this.data.utente.id, this.data.utente.codiceLicenza);
      }

    }

    if (this.data?.utente?.ruolo?.roleName) {
      this._getUtentiPerCollaboratore(this.data?.utente?.ruolo?.roleName);
    }

    if (this.data.idAzienda) {
      let filtroAziende: Filters[] = [{
        values: [this.data.idAzienda],
        operator: 'eq',
        chiave: 'id'
      },
      {
        values: [this._getTipoInstallazione(this.data?.utente?.ruolo?.roleName)],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      }]
      this._getAziendeView(filtroAziende)

      this.formUtenti.controls.idAziende.disable();
    } else {
      let filtroAziende: Filters[];

      const aziende = await firstValueFrom(this.aziendaService.listaAziende);

      // if (this.data?.utente?.utenteResponsabile?.idAziende) {
      if (aziende) {
        // Prendo le aziende dal responsabile dell'utente
        filtroAziende = [{
          // values: this.data.utente.utenteResponsabile.idAziende,
          values: aziende.map(az => az.id),
          operator: 'eq',
          chiave: 'id'
        },
        {
          values: [this.data?.utente?.ruolo?.roleName],
          operator: 'eq',
          chiave: 'ruolo'
        },
        {
          values: [this._getTipoInstallazione(this.data?.utente?.ruolo?.roleName)],
          operator: 'eq',
          chiave: 'tipoInstallazione'
        },
        ];
        this._getAziendeView(filtroAziende);
      } else if (this.data?.utente?.idAziende) {
        // Prendo le aziende dall'utente
        let filtroAziende: Filters[] = [{
          values: this.data.utente.idAziende,
          operator: 'eq',
          chiave: 'id'
        },
        {
          values: [this._getTipoInstallazione(this.data?.utente?.ruolo?.roleName)],
          operator: 'eq',
          chiave: 'tipoInstallazione'
        }]
        this._getAziendeView(filtroAziende);
      } else {
        // Prendo le aziende in cloud o GO
        this._getAziendeGoOrCloud(this.data?.utente?.ruolo?.roleName);
      }
    }
    const ruolo = this.formUtenti.get('ruolo')?.value;
    const isAziendaObbligatoria = ruolo && ['collaboratore', 'collaboratore_go', 'utente_read_only', 'utente_read_only_go'].includes(ruolo.roleName);

    if (isAziendaObbligatoria) {
      this.formUtenti.get('idAziende')?.addValidators(Validators.required);
      this.formUtenti.get('idAziende')?.updateValueAndValidity();
    }

  }

  private _getAziendeByIdAzienda() {

    this.aziendaService.getAziendeByIdAzienda([this.data.idAzienda], 0, 1000, '', [], [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }]).subscribe({
      next: (result: any) => {

        this.arrayAziende = result?.content || [];
        Object.values(this.arrayAziende).forEach(azienda => {
          this.selectedAzienda = [azienda.id];
        });;
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento delle aziende:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle aziende',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  /**
   * Apre interfacia per pacchetti aggiuntivi sulla licenza
   */
  public async gestioneRiepilogoLicenze() {
    const esitoSalva = await this.salvaUtente(true);

    // this.dialogRef.close();
    // this.router.navigate(['home/riepilogo-licenze'], { relativeTo: this.activatedRoute },);


    const dialogCreaAmbito = this.dialog.open(DialogRiepilogoLicenzeComponent, {
      data: {
        idUtente: this.data?.utente?.id
      },
      panelClass: ['dialog-container', 'maxwidth'],
      disableClose: false,
      width: '95%',
      height: '95%',
      maxWidth: '95%',
      autoFocus: false,
    });
    dialogCreaAmbito.afterClosed()

  }



  public canSeeLicenze() {
    const ruoloCanSeeLicenze = (this.ruoloScelto && !(['collaboratore', 'collaboratore_go', 'utente_read_only', 'utente_read_only_go'].includes(this.ruoloScelto)))
    const isAbilitaLicenze = this.utenteService.isPermessoAttivo('ABILITA_LICENZA');

    if (isAbilitaLicenze && (ruoloCanSeeLicenze || !this.ruoloScelto)) {
      return true
    }
    return false
  }

  /**
 * Metodo che mi filtra i responsabili quando selezionato ruolo collaboratore
 * Se sono un utente sotto configuratore allora il responsabile mi viene assegnato in automatico. 
 */
  private _getUtentiPerCollaboratore(ruolo?: string) {

    let filtroUtenti: Filters[] = [{
      values: [true],
      operator: 'is',
      chiave: 'attivo'
    }];

    if (ruolo && this._getTipoInstallazione(ruolo) === 'SOSTENIBILE_GO') {
      filtroUtenti.push({
        values: ['utente_premium_go'],
        operator: 'eq',
        chiave: 'roleName'
      })
    } else {
      filtroUtenti.push({
        values: ['utente_premium', 'professionista'],
        operator: 'eq',
        chiave: 'roleName'
      })
    }
    this.loadingResponsabile = true;
    this.formUtenti.get('utenteResponsabile')?.disable();

    this.spinnerOver.show();
    this.utenteService.getUtenti(0, 1000, '', filtroUtenti).pipe(
      finalize(() => {
        this.loadingResponsabile = false;
        this.formUtenti.get('utenteResponsabile')?.enable();
      })
    )
      .subscribe({
        next: (esito => {
          this.objResponsabile = [];
          esito.content.forEach((utente: Utente) => {
            const Responsabile = {
              keyId: utente?.keyId,
              id: utente?.id,
              nomeUtente: utente.firstName + ' ' + utente.lastName,
              ruolo: utente.ruolo,
              idAziende: utente.idAziende,
            }
            this.objResponsabile.push(Responsabile);
          });

        }),
        error: (err => {
          console.error(err);
        })
      })

  }

  ctrDisable(ruolo: string): boolean {
    const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName;
    const ruoloUtenteInModifica = this.data?.utente?.ruolo?.roleName;
    const isCollaboratore = (r: string) => r === 'collaboratore' || r === 'collaboratore_go';
    const isModifica = this.formUtenti.get('id')?.value;

    // Disabilita il ruolo collaboratore se sono in modifica di un utente. Se l'utente in modifica
    // è un collaboratore allora disabilito tutti i ruoli 
    if (isModifica && (isCollaboratore(ruoloUtenteInModifica) || isCollaboratore(ruolo))) {
      return true;
    }

    // Non posso creare un utente con il mio stesso ruolo, tranne se sono un configuratore 
    if (ruolo === ruoloUtenteLogin && ruoloUtenteLogin !== 'configuratore') {
      return true;
    }

    return false;
  }

  private _getAziendeGoOrCloud(ruolo: string) {

    if (ruolo === 'configuratore') {
      this._getAziendeView();
    } else {
      //Filtro le aziende per tipo ruolo go o cloud
      let tipoInstallazione = this._getTipoInstallazione(ruolo)

      let filtroAziende: Filters[] = [{
        values: [tipoInstallazione],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      }]

      if (ruolo === 'utente_read_only_go' || ruolo === 'utente_read_only') {

        filtroAziende.push({
          values: [ruolo],
          operator: 'eq',
          chiave: 'ruolo'
        })
      }

      this._getAziendeView(filtroAziende);
    }
  }

  private _getTipoInstallazione(ruolo: string) {

    if (ruolo?.includes('_go')) {
      return 'SOSTENIBILE_GO'
    }
    return 'SOSTENIBILE_CLOUD'

  }

  ctrDisableCampi() {

    if (this.data?.utente) {

      const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName
      const keyIdUtenteLogin = this.utenteService.infoUtente?.utente?.keyId
      const idUtenteEdit = this.data?.utente?.id;
      const keyIdUtenteEdit = this.data?.utente?.keyId;
      const ruoloUtenteEdit = this.data?.utente?.ruolo?.roleName;

      if (keyIdUtenteLogin === keyIdUtenteEdit && ruoloUtenteLogin !== 'configuratore') {
        this.formUtenti.get('ruolo')?.disable()
        this.formUtenti.get('idAziende')?.disable();
        return
      }

      if (keyIdUtenteLogin !== keyIdUtenteEdit && ruoloUtenteLogin === ruoloUtenteEdit) {
        this.formUtenti.disable();
      }

      if (this.isPermessoSoloRead) {
        this.formUtenti.disable();
      }

      if (ruoloUtenteLogin === 'collaboratore' || ruoloUtenteLogin === 'collaboratore_go') {
        if (['utente_premium', 'utente_premium_go', 'professionista'].includes(ruoloUtenteEdit)) {
          this.formUtenti.disable();
        }
      }

      if (idUtenteEdit) {
        this.formUtenti.get('ruolo')?.disable()
      }

    }
  }

  gestioneformLicenze() {

    if (!this.canSeeLicenze()) return;

    setTimeout(() => {
      const formLicenze = this.gestioneLicenzeComponent?.formLicenze
      if (this.ruoloScelto === 'utente_premium_go') {
        formLicenze.get('nrAutovalutazioni')?.setValue(1)
        formLicenze.get('nrBilanci')?.setValue(1)
        formLicenze.get('nrMaxAziende')?.setValue(1)

        if (this.gestioneLicenzeComponent.arrayCodicePromotore.length) {
          // Setto codice Promotore
          const promotoreTrovato = this.gestioneLicenzeComponent.arrayCodicePromotore.find(
            (codice: PromotoreLicenze) => codice.codice === 'SOSGO'
          );
          formLicenze.get('codicePromotore')?.setValue((promotoreTrovato as PromotoreLicenze).codice)
        }
      } else {
        formLicenze.reset();
      }

      formLicenze.get('dataAttivazione')?.setValue(new Date());

      let data = formLicenze.get('dataAttivazione')?.value;

      if (typeof data === 'string') {
        data = new Date(data);
      }

      formLicenze.get('dataScadenza')?.setValue(this.gestioneLicenzeComponent.calcolaDataScadenza(data))
      formLicenze.updateValueAndValidity();
    }, 0);

  }

}
