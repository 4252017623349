<h2 mat-dialog-title>Settore</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-spinner-overlay></app-spinner-overlay>
    <form [formGroup]="formSettori" appFormContainer [larghezza]="3">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Settore </mat-label>
            <input matInput placeholder="Settore" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <div appFormElement [larghezza]="0" [riempi]="true" style="text-align: center;">
            <mat-label>Generico</mat-label>
            <mat-radio-group aria-labelledby="Generico" formControlName="generico" (change)="cambioRadio($event)">
                <mat-radio-button class="example-radio-button" [value]="true">
                    Si
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="false">
                    No
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <textarea matInput placeholder="Descrizione" formControlName="descrizione"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <div class="separatore" appFormElement [larghezza]="3"
            *ngIf="this.formSettori.get('generico')?.value === false">
            <app-separatore-label titoloSeparatore="Assegnazioni Codici Ateco"></app-separatore-label>
        </div>

        <!-- ---------------------------------- C H I P ----------------------------- -->
        <mat-form-field class="chip-list" *ngIf="this.formSettori.get('generico')?.value === false">
            <mat-label>Cod. Ateco</mat-label>
            <mat-chip-grid #chipGrid aria-label="Codici Ateco" required>
                <mat-chip-row *ngFor="let atecoSel of codiciAtecoSel" (removed)="remove(atecoSel._id)">
                    {{ atecoSel._id }} - {{atecoSel.desc}}
                    <button matChipRemove [attr.aria-label]="'remove ' + atecoSel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="Aggiungi Codice Ateco..." #codAteco [matChipInputFor]="chipGrid"
                [formControl]="codiciAtecoCtrl" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let ateco of filteredAteco | async | slice : 0 : 100" [value]="ateco">
                    <b>{{ ateco._id }}</b> - {{ateco.desc}}
                </mat-option>
                <div *ngIf="this.showAltriRecordDisponibili" style="text-align: center; color: #807f7f;
        margin: 0 10px 0 10px;"> Numero record limitati.
                    Modifica il filtro per restringere la ricerca
                </div>
            </mat-autocomplete>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
