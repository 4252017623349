import { MaterialitaService, Soglie, datiGrafico } from 'src/app/services/materialita/materialita.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import AnnotationPlugin, { } from "chartjs-plugin-annotation";
import { BaseChartDirective } from 'ng2-charts';
import { DialogImpostaEsclusioniComponent } from './dialog/dialog-imposta-esclusioni/dialog-imposta-esclusioni.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RisultatiSurvey } from 'src/app/services/materialita/materialita-web.service';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';


@Component({
  selector: 'app-analisi-risultati',
  templateUrl: './analisi-risultati.component.html',
  styleUrls: ['./analisi-risultati.component.scss']
})
export class AnalisiRisultatiComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true }) scatterChart!: BaseChartDirective;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public isPermessoSoloRead: boolean = false;
  public coloreVerde: boolean = false;
  public risultatiSurvey: RisultatiSurvey | undefined = undefined;
  public puntoColoreTematica: string[] = [];
  public puntoColoreTematicaBorder: string[] = [];
  private _idMaterialita: string | undefined;
  public materialita: any;
  public tipoPunteggio: number = 0;
  public apriExpandTematica: number = 0;
  public espanso: boolean = false;
  public idTematicaDaRaggruppare: string = '';
  public isRaggruppata: boolean = false;
  public erroreGetSurvey: boolean = false;
  public isInizializzato: boolean = false;
  public isCollapse = true;
  public colori: string[] = [
    "#36a2eb", "#ff6384", "#ff9f40", "#9467bd",
    "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728",
    "#5e3c99", "#d73027", "#ff9f40", "#91bfdb", "#8c564b",
    "#636363", "#1a9850", "#4575b4", "#addd8e",
    "#8c510a", "#996ab2", "#fee08b", "#e377c2", "#d7191c",
    "#bf812d", "#35978f", "#80cdc1", "#17becf", "#543005",
    "#313695", "#66bd63", "#d73027", "#4575b4", "#91bfdb",
    "#ff9f40", "#addd8e", "#525252", "#636363",
    "#91bfdb", "#e377c2", "#cbc9e2", "#9e9ac8", "#d73027",
    "#4575b4", "#17becf", "#1a9850", "#66bd63", "#a6d96a",
    "#d7191c", "#996ab2", "#5e3c99", "#8c564b", "#bf812d",
    "#80cdc1", "#35978f", "#543005", "#8c510a", "#fee08b"
  ];

  @Input()
  get idMaterialita(): string | undefined {
    return this._idMaterialita;
  }

  set idMaterialita(idMaterialita: string | undefined) {
    this._idMaterialita = idMaterialita;
  }

  public step = 0;

  /* SLIDERS */
  interniDisabled = false;
  interniMax = 0; // valore massimo dello slider
  interniMin = 0; // valore minimo dello slider 
  interniShowTicks = false; // tipo slider
  interniStep = 0.1; // scorrimento slider (0,1 - 0,2 - 0,3 - 1 - 1,1)
  interniValue = 0;  // valore attuale dello slider
  esterniDisabled = false;
  esterniMax = 0;
  esterniMin = 0;
  esterniShowTicks = false;
  esterniStep = 0.1;
  esterniValue = 0;

  public scatterChartPlugins: any[] = [
    {
      //. Mi calcolo le coordinate dei quadranti prima che il grafico venga disegnato
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx; //. Recupero del contesto del grafico (ctx): Ottiene il contesto 2D del canvas del grafico. Il contesto è utilizzato per disegnare elementi grafici sul canvas.
        const xAxis = chart.scales['x']; //. Recupero delle scale x e y del grafico (xAxis e yAxis): Ottiene le scale x e y del grafico. Le scale forniscono informazioni sulle dimensioni e la posizione degli assi del grafico.
        const yAxis = chart.scales['y'];
        const leftQuadrantStartX = xAxis.left; //. Inizio Quadrante sinistro X =  coordinata x dell'origine del grafico (0)
        const leftQuadrantEndX = xAxis.getPixelForValue(this.interniValue); //. Fine Quadrante sinistro X = valore della soglia degli interni
        const bottomQuadrantStartY = yAxis.getPixelForValue(this.esterniValue); //. Inizio Quadrante alto Y = valore della soglia degli esterni

        // Shadow per l'asse y
        ctx.fillStyle = 'rgba(240, 240, 240, 0.8)'; //. colore dell'quadrante 
        ctx.fillRect( //. Stabilisco il perimetro del quadrante per colorarlo. 
          leftQuadrantStartX, //. La coordinata x iniziale del rettangolo, che è l'origine del grafico sulle ascisse.
          yAxis.top, //. La coordinata y iniziale del rettangolo, che è la cima dell'asse y nel sistema di coordinate 
          leftQuadrantEndX - leftQuadrantStartX, //. Larghezza del rettangolo = dim quadrante - origine (es: 5 - 0 = 5 larghezza)
          yAxis.getPixelForValue(this.esterniValue) - yAxis.top //. L'altezza del rettangolo, che è la distanza tra l'inizio del quadrante inferiore e la cima dell'asse y
        );

        // Shadow per l'asse x
        ctx.fillStyle = 'rgba(240, 240, 240, 0.8)';
        ctx.fillRect(
          leftQuadrantStartX,
          bottomQuadrantStartY,
          xAxis.right - xAxis.left,
          yAxis.bottom - bottomQuadrantStartY
        );

        /* BORDER rosso a destra dell'asse Y */
        ctx.strokeStyle = '#f7597a';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(leftQuadrantEndX, yAxis.top);
        ctx.lineTo(leftQuadrantEndX, yAxis.bottom);
        ctx.stroke();

        /* BORDER rosso top dell'asse X */
        ctx.strokeStyle = '#f7597a';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(leftQuadrantStartX, bottomQuadrantStartY);
        ctx.lineTo(xAxis.right, bottomQuadrantStartY);
        ctx.stroke();

        /*Colore dei pallini */
      }
    }
  ];
  // scatter
  public scatterChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        /*     bodyFont: { weight: 'bold' }, */

        callbacks: {
          label: (tooltip: any) => {
            return (' ' + tooltip.dataset.label + ': ' + '(' + tooltip.parsed.x + ' ; ' + tooltip.parsed.y + ')');
          }
        }
      },
      /* LEGENDA */
      legend: {
        display: false
      },
      /* TITOLO */
      title: {
        align: 'end',
        display: true,
        text: 'Incluso in Materialità',
      },
    },
    scales: {

      x: {
        min: 0,
        max: 1,
        ticks: {
          color: 'black',

        },
        grid: {
          color: '#bcbcbc'
        },
        title: {
          display: true,
          text: 'Punteggio Stakeholder Interni',
        }
      },
      y: {
        min: 0,
        max: 1,
        ticks: {

          autoSkip: false
        },
        grid: {
          color: '#bcbcbc'
        },
        title: {
          display: true,
          text: 'Punteggio Stakeholder Estertni',
        }
      },
    },

  };
  public scatterChartLabels: string[] = [];
  public scatterChartData: ChartData<'scatter'> | undefined = undefined;

  public scatterChartType: ChartType = 'scatter'

  constructor(
    public dialog: MatDialog,
    public materialitaService: MaterialitaService,
    private readonly utilityService: UtilityService,
    private readonly utenteService: UtenteService,
  ) {
  }

  ngOnInit(): void {
    /* this._idMaterialita = '65b3cdab7ae8e0202badf3be'; */
    /* this.inizializzaComponente(); // !RIMUOVERE IN PROD, per apertura da dashboard */
    Chart.register(AnnotationPlugin); //per fare visualizzare le righe xy delle soglie

    const isEdit = this.utenteService.isPermessoAttivo('EDIT_MATERIALITA');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_MATERIALITA')
    this.isPermessoSoloRead = (!isEdit && !isCreate)
  }

  public inizializzaComponente() {
    this.isInizializzato = false;
    this.getMaterialitaById();

    this.datiIniziali();

  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: object[];
  }): void {

  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: object[];
  }): void {

  }

  updateQuadranti() {
    // Richiama l'aggiornamento del grafico
    this.scatterChart.update();

  }

  impostaEsclud() {
    const dialogImpEsclusioni = this.dialog.open(DialogImpostaEsclusioniComponent, {
      data: {
        matEsclusioni: this.materialita?.esclusioni,
        idMaterialita: this._idMaterialita
      },
      panelClass: 'dialog-container',
      disableClose: false,
      maxWidth: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogImpEsclusioni.afterClosed().subscribe((result) => {

      if (this.isPermessoSoloRead) {
        return;
      }

      this.inizializzaComponente();

    });
  }

  datiIniziali() {
    let erroreMsg: string = '';
    this.erroreGetSurvey = false;
    this.spinnerOver.show();
    if (this._idMaterialita) {

      this.materialitaService.getRisultatiSurvey(this._idMaterialita).subscribe({
        next: (result) => {
          this.valorizzaPointsGrafico(result);
          this.risultatiSurvey = result;
          this.spinnerOver.hide();
          this.isInizializzato = true;


        },
        error: (err) => {
          console.error(err);
          if (err.status === 406) {
            erroreMsg = err.error.message;
          }
          this.spinnerOver.hide();
          this.erroreGetSurvey = true;
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: erroreMsg,
            bottoni: [
              {
                nome_btn: 'Chiudi', handler: () => {
                }
              }]
          });
        }

      })

      // ! Controllo già presente nel metodo valorizzaPointsGrafico(), Inserito qui perchè non
      // ! riesce a valorizzarsi le soglie. !!!! DA RIVEDERE DOPO PROD 
      if (this.risultatiSurvey && this.risultatiSurvey.tipoPunteggio === "BASE_5") {
        this.esterniMax = 5;
        this.interniMax = 5;

      } else {
        this.esterniMax = 10;
        this.interniMax = 10;
      }
    }
  }


  /**
 * mi salvo le soglie quando sono in dragEnd
 */
  salvaSoglie() {
    if (this._idMaterialita) {


      const soglie: Soglie = {
        sogliaInterni: this.interniValue,
        sogliaEsterni: this.esterniValue,
      }

      this.materialitaService.putSoglie(this._idMaterialita, soglie).subscribe({
        next: (result) => {

        },
        error: (err) => {

        }
      })
    }
  }

  public getMaterialitaById() {
    if (this._idMaterialita) {
      this.materialitaService.getMaterialitaById(this._idMaterialita).subscribe({
        next: result => {

          this.materialita = result;

          //dati grafico
          this.interniValue = this.materialita?.sogliaInterni;
          this.esterniValue = this.materialita?.sogliaEsterni;
        },
        error: err => {
          console.error(err);

        }
      });
    }
  }

  /**
   * Metodo che mi valorizza la posizione, colore, e label dei pallini sul grafico
   * @param risultatiSurvey 
   */
  valorizzaPointsGrafico(risultatiSurvey: any, event?: MatSlideToggleChange) {
    let datiGrafico: datiGrafico[] | undefined = undefined
    this.puntoColoreTematica = [];
    this.puntoColoreTematicaBorder = [];
    this.espanso = false;

    //Valore massimo della soglia e asse x y grafico
    if (risultatiSurvey && risultatiSurvey.tipoPunteggio === "BASE_5") {
      this.tipoPunteggio = 5;
      this.esterniMax = 5;
      this.interniMax = 5;
    } else {
      this.tipoPunteggio = 10;
      this.esterniMax = 10;
      this.interniMax = 10;
    }

    if (this.scatterChartOptions?.scales && this.scatterChartOptions.scales["x"]?.max) {
      this.scatterChartOptions.scales["x"].min = -1; // Si mette a -1 per dare spazio ai punti sul grafico (altrimenti sono troppo a ridosso del bordo)
      this.scatterChartOptions.scales["x"].max = this.tipoPunteggio + 1; // Si aggiunge 1 per dare spazio ai punti sul grafico (altrimenti sono troppo a ridosso del bordo)
    }
    if (this.scatterChartOptions?.scales && this.scatterChartOptions.scales["y"]?.max) {
      this.scatterChartOptions.scales["y"].min = -1; // Si mette a -1 per dare spazio ai punti sul grafico (altrimenti sono troppo a ridosso del bordo)
      this.scatterChartOptions.scales["y"].max = this.tipoPunteggio + 1; // Si aggiunge 1 per dare spazio ai punti sul grafico (altrimenti sono troppo a ridosso del bordo)
    }


    risultatiSurvey.tematiche.forEach((tematica: any, indexTematica: number) => {

      if (!datiGrafico) {
        datiGrafico = [];
      }

      const colore = this.colori[indexTematica];

      this.puntoColoreTematica.push(`${colore}60`); //colore con opacità per background
      this.puntoColoreTematicaBorder.push(colore);

      //! Se tematica raggruppata allora imposto prendo i dati dalla tematica raggruppata e non dal impatto
      if (tematica.raggruppata) {
        datiGrafico.push({
          titoloImpatti: tematica.titolo,
          valoriInterni: tematica.medieRaggr.interni,
          valoriEsterni: tematica.medieRaggr.esterni,
          sizePoint: 12,
          colorePoint: colore,
        });

      } else {
        //! se non è raggruppata prendo i dati dell'impatto 
        tematica.impatti.forEach((impatto: any) => {
          // Controllo il primo impatto materiale, cosichè espando la tematica iniziale.
          if (!this.isInizializzato && !this.espanso && impatto.valori.interni.media >= this.interniValue && impatto.valori.esterni.media >= this.esterniValue) {

            this.apriExpandTematica = indexTematica;

            this.espanso = true;
          }

          if (!datiGrafico) {
            datiGrafico = [];
          }
          datiGrafico.push({
            titoloImpatti: impatto.titolo,
            valoriInterni: impatto.valori.interni.media,
            valoriEsterni: impatto.valori.esterni.media,
            sizePoint: 6,
            colorePoint: colore,
          });
        })
      }
    })

    /* Creo oggetto grafico, ovvero titolo coordinate dei pallini che si visualizza sul grafico */
    if (datiGrafico) {
      this.scatterChartLabels = (datiGrafico as any[]).map((item: any) => item.titoloImpatti);

      this.scatterChartData = {
        /*   labels: this.scatterChartLabels, */
        datasets: (datiGrafico as any[]).map((item: any) => {
          return {
            data: [{
              x: item.valoriInterni,
              y: item.valoriEsterni
            }],
            label: ' ' + item.titoloImpatti,
            pointRadius: item.sizePoint,
            pointBackgroundColor: `${item.colorePoint}75`,
            pointBorderColor: item.colorePoint,
            pointHoverRadius: 10,
            borderWidth: 2
          };
        })

      };
    }
  }


  /**
   * Raggruppa tutti gli impatti di una tematica
   * @param event event.source.id (id tematica, vedi HTML), event.checked (true, false) 
   */
  raggruppaImpatti(event: MatSlideToggleChange, indexTematica: number) {
    if (this.risultatiSurvey) {

      if (event.checked) {
        this.risultatiSurvey.tematiche[indexTematica].raggruppata = true;
        if (indexTematica === this.apriExpandTematica) {
          this.apriExpandTematica = -1;
        }

      } else {
        this.risultatiSurvey.tematiche[indexTematica].raggruppata = false;

        this.apriExpandTematica = indexTematica;

      }
    }

    this.valorizzaPointsGrafico(this.risultatiSurvey, event);

    if (this._idMaterialita) {

      this.materialitaService.putRaggruppaImpatti(this._idMaterialita, event.source.id, event.checked).subscribe({
        next: () => {
        },
        error: (err: any) => {
          console.error(err);
        }
      });
    }
  }

  getTooltipText(categorie: any) {

    let valoreTooltip: string = '';

    for (let categoria of categorie) {

      if (!valoreTooltip) {
        valoreTooltip = categoria.titolo + ' : ' + categoria.media;
      }

      valoreTooltip += ' | ' + categoria.titolo + ' : ' + categoria.media;
    }

    return valoreTooltip;
  }

  isCollapseFunx() {
    this.isCollapse = true;
  }
  isExpandFunx() {
    this.isCollapse = false;
  }

  apriInfo() {
    this.utilityService.openDialog({
      titolo: 'Informazioni Analisi Risultati',
      descrizione: '<div style="font-weight: normal !important;"> <br> <span style="font-weight: bold;"> Sezione Sinistra: Risultati Survey</span> <br><br> Nella sezione sinistra vengono elencate tutte le tematiche con gli impatti compilati. In questa sezione, acccanto al titolo della tematica è presente uno <span style="font-weight: bold;"> slider on/off </span>che permette di raggruppare tutti gli impatti di una specifica tematica. Quando lo slider è <span style="font-weight: bold;"> attivato </span>, sul grafico verrà visualizzato un singolo punto di dimensioni maggiori, la cui posizione è determinata dalla media dei punteggi assegnati a tutti gli impatti interni ed esterni relativi a quella tematica. <br> <br> Accanto al titolo della sezione si trova la funzione <span style="font-weight: bold;"> Imposta Esclusioni </span>. Questa funzione consente di filtrare la visualizzazione e il calcolo degli impatti compilati, permettendo un\'analisi più mirata e precisa. <br> <br> <span style="font-weight: bold;"> Sezione Destra: Grafico</span> <br><br> La sezione destra mostra un grafico bidimensionale, con l\'asse delle ordinate e l\'asse delle ascisse corrispondenti alla tipologia punteggio scelta nello step precedente prima della compilazione del questionario. Sul grafico sono rappresentati vari punti colorati, ognuno dei quali indica un impatto compilato. La posizione di ciascuno è determinata dalla valutazione degli stakeholder interni ed esterni. <br><br> Sotto al grafico sono presenti le <span style="font-weight: bold;"> Soglie degli Stakeholder Interni/Esterni</span>. Queste soglie, quando attivate, determinano se gli impatti rientrano nei criteri stabiliti per definire la materialità di esso. Se gli impatti rientrano nella soglia, vengono considerati materiali, altrimenti no.</div>',
      bottoni: [
        {
          nome_btn: 'Ok',
          id_btn: 'ok'
        },

      ]
    }, 1200);
  }

  public convertGraficoInBase64(): string {


    this.oggettoLegendaGrafico();
    const imgBase64: any = this.scatterChart.toBase64Image();

    return imgBase64;
  }

  public oggettoLegendaGrafico() {

    interface Legenda {
      x: number;
      y: number;
      borderColor: string;
      pointColor: string;
      label: string;
    }

    let datiGrafico: Legenda[] = [];
    let x: number;
    let y: number;

    this.scatterChart?.data?.datasets.forEach((datasets: any) => {
      datasets.data.forEach((data: any) => {
        x = data.x;
        y = data.y;

        let dati: Legenda = {
          x: x,
          y: y,
          borderColor: datasets.pointBorderColor,
          pointColor: datasets.pointBackgroundColor,
          label: datasets.label
        };

        datiGrafico.push(dati);
      });
    });
    return datiGrafico;
  }
}


