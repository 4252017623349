import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject, Observable, Subscription, catchError, delay, finalize, of, take } from "rxjs";
import { Colonna } from "../lista-tabellare.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSort, SortDirection } from "@angular/material/sort";

interface ResponseHttp {
    totalPages: number;
    totalElements: number;
    pageable: {
        pageNumber: number;
        pageSize: number;
        offset: number;
        sort: {
            sorted: boolean;
            empty: boolean;
            unsorted: boolean;
        },
        paged: boolean;
        unpaged: boolean;
    },
    first: number;
    last: number;
    size: number;
    content: any[]
    number: number;
    sort: {
        sorted: boolean;
        empty: boolean;
        unsorted: boolean;
    },
    numberOfElements: number;
    empty: boolean;
}

export interface FnCaricamentoDati {
    (
        page: number,
        pageSize: number,
        ricerca?: string,
        filters?: Filters[],
        sortBy?: SortBy[]
    ): Observable<ResponseHttp>
}

export interface FnCaricamentoStyle {
    (record: any): { [key: string]: any }
}

export interface Filters {
    values: any[];
    operator: 'eq' | 'bet' | 'lt' | 'gt' | 'lte' | 'gte' | 'ne' | 'lk' | 'is' | 'eqOrEmpty';
    chiave: string
}

export interface SortBy {
    chiave: string;
    sort: SortDirection;
}

export class ListaTabellareDataSource implements DataSource<any> {

    private _dataSubject = new BehaviorSubject<any[]>([]);
    private _loadingSubject = new BehaviorSubject<boolean>(false);
    private _chiamataRicercaSubscription!: Subscription;
    private _keyColonneValue: string[] = []; // chiavi di colonne di tipo 'value'
    private _keyColonneBtnValue: string[] = []; // chiavi di colonne di tipo 'button'
    private _keyColonneHamburgerValue: string[] = []; // chiavi di colonne nell'hamburger

    public loading$ = this._loadingSubject.asObservable().pipe(delay(0));
    private paginator!: MatPaginator;
    private sort!: MatSort;
    public isListaVuota: boolean = false;


    connect(collectionViewer: CollectionViewer): Observable<readonly any[]> {
        return this._dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._dataSubject.complete();
        this._loadingSubject.complete();
    }

    constructor(
        private fnCaricamentoDati: FnCaricamentoDati,
        private colonne: { [key: string]: Colonna },
        private readonly colonneHamburger: { [key: string]: Colonna },
        private triggerLoading = true,
        private sanitizer: DomSanitizer,
        public fnCaricamentoStyle?: FnCaricamentoStyle,
    ) {
        this._keyColonneValue = Object.keys(this.colonne).filter(value => this.colonne[value].type === 'value' || !this.colonne[value].type);
        this._keyColonneBtnValue = Object.keys(this.colonne).filter(value => this.colonne[value].type === 'button');
        this._keyColonneHamburgerValue = Object.keys(this.colonneHamburger).filter(value => this.colonneHamburger[value].type === 'button' || !this.colonneHamburger[value].type);
    }


    public caricaDati(
        ricerca?: string,
        filters?: Filters[]
    ) {

        if (this.triggerLoading) {
            this._loadingSubject.next(true);
        }

        let sortData: SortBy[] | undefined = undefined;

        if (this.sort.active && this.sort.active !== 'created' && this.sort.direction) {

            // Le colonne da ordinare sono quelle della chaive campiSort, se c'è, o direttamente la colonna indicata dal valore this.sort.active.
            let campiSort: string[] | undefined = this.colonne[this.sort.active].campiSort;

            if (!campiSort && typeof this.colonne[this.sort.active].value === 'string') {
                campiSort = [this.colonne[this.sort.active].value as string];
            }

            if (!campiSort) {
                campiSort = [this.sort.active];
            }

            sortData = campiSort.map((sortVal): SortBy => {
                return {
                    chiave: sortVal,
                    sort: this.sort.direction
                };
            });

        }

        if (this._chiamataRicercaSubscription && !this._chiamataRicercaSubscription.closed) {
            this._chiamataRicercaSubscription.unsubscribe();
        }

        this._chiamataRicercaSubscription = this.fnCaricamentoDati(
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            ricerca,
            filters,
            sortData || undefined
        ).pipe(
            catchError((err) => {
                console.error('ERRORE fnCaricamentoDati : ', err);

                // Visualizzo un messaggio di errore nella tabella 
                const dati = [];
                dati.push({ error: 'Errore caricamento' })
                this.isListaVuota = true;
                this._dataSubject.next(dati);

                return of(null);
            }),
            finalize(() => {
                if (this.triggerLoading) {
                    this._loadingSubject.next(false);
                }
            })
        ).subscribe((response) => {
            if (response) {
                this.paginator.length = response.totalElements;

                // Visualizzo un messaggio di errore nella tabella 
                const dati = response.content || [];
                if (!dati.length) {
                    dati.push({ error: 'Nessun dato trovato' })
                    this.isListaVuota = true;

                } else {
                    this.isListaVuota = false;

                    dati.forEach(record => {
                        /*  COLONNE VALORE */
                        this._keyColonneValue.forEach(keyColonna => {
                            if (typeof this.colonne[keyColonna].value === 'function') {
                                record['_col_val_' + keyColonna] = this.sanitizer.bypassSecurityTrustHtml((this.colonne[keyColonna].value as Function)(record));
                            } else if (typeof this.colonne[keyColonna].value === 'string') {
                                record['_col_val_' + keyColonna] = record[this.colonne[keyColonna].value as string]
                            } else {
                                record['_col_val_' + keyColonna] = this.colonne[keyColonna].value
                            }
                        });

                        /* COLONNE BUTTON */
                        this._keyColonneBtnValue.forEach(keyColonna => {

                            if (this.colonne[keyColonna].nascondiButton) {

                                const nasc = this.colonne[keyColonna].nascondiButton;

                                if (typeof nasc === 'boolean') {
                                    record['_col_hid_' + keyColonna] = nasc;
                                } else if (typeof nasc === 'function') {
                                    record['_col_hid_' + keyColonna] = nasc(record);
                                }
                            } else {
                                record['_col_hid_' + keyColonna] = false;
                            }

                        });

                        /* STYLE */
                        if (this.fnCaricamentoStyle) {
                            record['_style_riga'] = this.fnCaricamentoStyle(record);
                        }

                        /* COLONNE IN HAMBURGER */
                        // Se ci sono colonne in hamburger, si controlla se per il record c'è almeno un tasto all'iterno
                        if (this._keyColonneHamburgerValue?.length) {

                            this._keyColonneHamburgerValue
                                .forEach((keyColonna) => {

                                    const nasc = this.colonneHamburger[keyColonna].nascondiButton;

                                    if (nasc) {
                                        if (typeof nasc === 'boolean') {
                                            record['_col_hid_' + keyColonna] = nasc;
                                        } else {
                                            record['_col_hid_' + keyColonna] = nasc(record);
                                        }
                                    } else {
                                        record['_col_hid_' + keyColonna] = false;
                                    }
                                });

                            const tutteNascoste = this._keyColonneHamburgerValue
                                .every((keyColonna) => {

                                    const nasc = this.colonneHamburger[keyColonna].nascondiButton;

                                    if (nasc) {
                                        if (typeof nasc === 'boolean') {
                                            return nasc;
                                        } else {
                                            return nasc(record);
                                        }
                                    } else {
                                        return false;
                                    }
                                });

                            record['_col_burg_hid'] = tutteNascoste;
                        }
                    });
                }

                this._dataSubject.next(dati);
            }
        })
    }

    public setLoading(isLoading: boolean) {
        this._loadingSubject.next(isLoading);
    }

    public setPaginator(paginator: MatPaginator) {
        this.paginator = paginator;
    }

    public setSort(sort: MatSort) {
        this.sort = sort;
    }


    updateStyle() {
        if (this.fnCaricamentoStyle) {
            this._dataSubject.pipe(
                take(1)
            ).subscribe(
                (esito) => {
                    (esito || []).forEach(record => {
                        record['_style_riga'] = this.fnCaricamentoStyle!(record);
                    });
                    this._dataSubject.next(esito);
                }
            )

        }
    }

}
