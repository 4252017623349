<h2 mat-dialog-title>
    Utenti
</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-lista-utenti [id_azienda]="id"></app-lista-utenti>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
</div>
