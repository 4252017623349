<!-- ToolBar -->
<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="isMenuOpen = !isMenuOpen">
    <mat-icon>menu</mat-icon>
  </button>

  <img [src]="logoPath" alt="Sostenibile" class="logo-path" />

  <span class="toolbar-spacer"></span>
  <div class="label-azienda" style="padding: 20px 10px 0 0 ">
    <mat-form-field appearance="outline">
      <mat-label>Azienda</mat-label>
      <mat-select #matSelect placeholder="Azienda" [(ngModel)]="azienda" (valueChange)="cambioAzienda($event)"
        [compareWith]="compareAzienda">
        <mat-option class="label-azienda-option" *ngFor="let azd of arrayAziende"
          [value]="azd">{{azd.ragioneSociale}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button class="user-button" mat-stroked-button color="primary" [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
    <div style="color:black; font-family: 'Sora';">
      {{infoUtente?.userDetails?.username}}
    </div>
  </button>
  <mat-menu #userMenu="matMenu" [xPosition]="'before'">
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>
<!-- sideNav -->
<mat-drawer-container autosize>
  <mat-drawer-content>
    <ng-scrollbar>
      <router-outlet></router-outlet>
    </ng-scrollbar>
  </mat-drawer-content>
  <mat-drawer class="sidenav" mode="side" [opened]="isMenuOpen">
    <!-- Menu Laterale -->
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="navside-tree">

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>

        <button mat-stroked-button color="basic" (click)="azionaMenu(node)">
          <mat-icon class="material-icons-outlined icone-label-chack">{{ node.icon }}</mat-icon>
          <div class="nome-voce">
            {{ node.sezione }}
          </div>
        </button>

      </mat-tree-node>

      <mat-nested-tree-node class="mat-nested-tree-node" *matTreeNodeDef="let node; when: hasChild">

        <div class="mat-tree-node">

          <button mat-stroked-button color="basic" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.sezione"
            [disabled]="node.disabled && node.disabled === true ? true : false">

            <mat-icon class="material-icons-outlined icone-label-chack">{{ node.icon }}</mat-icon>

            <div class="nome-voce">
              {{ node.sezione }}
            </div>

            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? "expand_less" : "expand_more" }}
            </mat-icon>

          </button>

        </div>

        <div [class.tree-invisible]="!treeControl.isExpanded(node)" [class.tree-visible]="treeControl.isExpanded(node)"
          role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>

      </mat-nested-tree-node>
    </mat-tree>
  </mat-drawer>
</mat-drawer-container>