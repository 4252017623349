import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogGestioneLicenzeComponent } from 'src/app/page/utenti/dialog/dialog-gestione-licenze/dialog-gestione-licenze.component';
import { RiepilogoLicenzeComponent } from 'src/app/page/utenti/riepilogo-licenze/riepilogo-licenze.component';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-dialog-riepilogo-licenze',
  templateUrl: './dialog-riepilogo-licenze.component.html',
  styleUrls: ['./dialog-riepilogo-licenze.component.scss']
})
export class DialogRiepilogoLicenzeComponent {
  @ViewChild(RiepilogoLicenzeComponent) riepilogoLicenzeComponent!: RiepilogoLicenzeComponent;

  public error = '';

  private _utente: Utente | undefined;

  get utente() {
    return this._utente;
  }

  @Input()
  set utente(ute: Utente | undefined) {
    this._utente = ute;
  }

  public generalita: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogGestioneLicenzeComponent>,
    private utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idUtente: string
    }
  ) {

    this.error = '';

    this.utenteService.getUtente(data.idUtente).subscribe({
      next: (result) => {
        this.utente = result;

        if (this.utente) {
          this.generalita = (this.utente.ruolo.descrizione) + ' ' + this.utente.firstName + ' ' + this.utente.lastName
        } else {
          this.generalita = '';
        }

      },
      error: (err) => {
        this.error = 'Impossibile caricare i dati dell\'utente';
      }
    })


  }

}
