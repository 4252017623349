import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, catchError, forkJoin, map, of, startWith, tap } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ateco, JsonFileService } from 'src/app/services/json-file/json-file.service';
import { SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { UtilityService } from 'src/app/services/utility/utility.service';


@Component({
  selector: 'app-dialog-crea-cfg-settori',
  templateUrl: './dialog-crea-cfg-settori.component.html',
  styleUrls: ['./dialog-crea-cfg-settori.component.scss']
})
export class DialogCreaCfgSettoriComponent implements OnInit {

  public showAltriRecordDisponibili: boolean = false;

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public codiciAtecoCtrl = new FormControl<string | Ateco>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  private codiciAtecoArray: Ateco[] | undefined = undefined;
  ateco: Ateco[] = [];
  filteredAteco: Observable<Ateco[]> | undefined;
  private _valueChangeAtecoSubscription: Subscription | undefined;

  /* CHIP */
  private atecoPreSelect: string[] | undefined = undefined;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  codiciAtecoSel: Ateco[] = [];
  @ViewChild('codAteco') codAteco!: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);
  /* CHIP */

  /* Form Controls */
  public formSettori = new FormGroup({

    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* titolo settore */
    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    generico: new FormControl<boolean>(false),
    /* descrizione */
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    /* codice ateco */
    codiciAteco: new FormControl<string[] | undefined>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    sottoSettori: new FormControl<SottoSettore[] | undefined>([], {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public jsonFileService: JsonFileService,
    public dialogRef: MatDialogRef<DialogCreaCfgSettoriComponent>,
    private settoriService: SettoriService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      settore: any
    }
  ) {
  }

  ngOnInit(): void {
    this.valoriIniziali().subscribe(
      (result) => {
        if (result) {
          this.atecoAutoComplete();
        }
      }
    );
  }

  ngOnDestroy() {
    this._valueChangeAtecoSubscription?.unsubscribe();
  }

  public cambioRadio(event: any) {
    
    this.updateValidatoriForm();

    this.formSettori.get('codiciAteco')?.updateValueAndValidity();
  }

  public updateValidatoriForm() {

    if (this.formSettori.get('generico')?.value === true) {
      this.formSettori.get('codiciAteco')?.removeValidators([Validators.required]);
    } else if (this.formSettori.get('generico')?.value === false) {
      this.formSettori.get('codiciAteco')?.addValidators([Validators.required]);
    }

  }

  public salva() {

    if (this.formSettori.get('generico')?.value === true) {
      this.formSettori.get('codiciAteco')?.setValue(undefined);
    } else {
      this.formSettori.get('codiciAteco')?.setValue(this.codiciAtecoSel.map(
        (codAteco) => codAteco._id
      ));
    }

    if (this.formSettori.valid) {
      if (this.formSettori.get('generico')?.value) {

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          fontWeight: '500',
          descrizione: '<strong>Il settore è stato definito come generale.<br></strong>' +
            '<li>Solo un settore può essere definito come generale.<br>' +
            '<li>Eventuali altri settori generali saranno cambiati in non-generali.<br>' +
            '<strong>Continuare?</strong>',
          bottoni: [
            { nome_btn: 'Annulla',
              background_color: 'var(--mat-color-accent)',
             },
            {
              nome_btn: 'Continua',
              background_color: 'var(--mat-color-primary)',
              handler: () => {
                this._eseguiSalvataggio();
              }
            }
          ]
        });

      } else {
        this._eseguiSalvataggio();
      }

    } else {
      Object.values(this.formSettori.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      this.codiciAtecoCtrl.markAsTouched();
    }
  }


  private _eseguiSalvataggio() {

    this.spinnerOver.show();

    const id = this.formSettori.get('id')?.value;

    let obsSalva: Observable<any>;

    if (id) {
      obsSalva = this.settoriService.putSettori(id, this.formSettori.getRawValue());
    } else {
      obsSalva = this.settoriService.postSettori(this.formSettori.getRawValue());
    }

    obsSalva.subscribe(
      {
        next: (result: any) => {
          this.spinnerOver.hide();
          this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
          this.dialogRef.close();
        },
        error: (err: any) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio',
            bottoni: [{ nome_btn: 'Chiudi' }]
          })
        }
      }
    );

  }

  /* displayfn e filter per codice ateco */
  displayFnAteco(ateco: Ateco): string {
    return ateco && ateco._id ? ateco._id + ' - ' + ateco.desc : '';
  }

  private _filterAteco(name: string, options: Ateco[]): Ateco[] {
    const filterValue = name.toLowerCase();
    return this.ateco.filter(option => option.desc.toLowerCase().includes(filterValue) || option._id.toLowerCase().includes(filterValue));
  }


  public valoriIniziali(): Observable<boolean> {

    if (this.data?.settore) {
      Object.keys(this.data.settore).forEach((value, index, array) => {
        this.formSettori.get(value)?.setValue((this.data.settore as any)[value]);
      });

      this.updateValidatoriForm();

      this.atecoPreSelect = this.data.settore.codiciAteco || [];

      return this.jsonFileService.getAtecoById(this.atecoPreSelect || []).pipe(map(
        (result) => {
          this.codiciAtecoSel = result;
          return true;
        }
      ));

    }
    return of(true);
  }
  /**
    * Metodo che mi riempie l'autocompelte dei codici ateco 
    */
  public atecoAutoComplete() {

    this.codiciAtecoArray = this.settoriService.getCodiciAteco();
    if (this.codiciAtecoArray) {
      this.ateco = this.codiciAtecoArray;

      const atecoControl = this.formSettori.get('codiciAteco');

      if (atecoControl) {
        this.filteredAteco = this.codiciAtecoCtrl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.desc;
            return name ? this._filterAteco(name as string, this.ateco) : this.ateco.slice();
          }),
          tap((esito: any) => {
            
            if (esito.length > 10) {
              this.showAltriRecordDisponibili = true;
            } else {
              this.showAltriRecordDisponibili = false;
            }
          })
        );
        if (this.atecoPreSelect) {
          atecoControl.setValue(this.atecoPreSelect);
          this.atecoPreSelect = undefined;
        }
      }
    }
  }


  /* CHIP */
  /*  add(event: MatChipInputEvent): void {
     const value = (event.value || '').trim();
 
     // Aggiungi Codici Ateco inziale 
     if (value) {
       this.codiciAtecoSel.push(value);
     }
 
     // Clear the input value
     event.chipInput!.clear();
 
     // this.formSettori.get('codiciAteco')?.setValue(''); 
   }
  */
  remove(codiceAteco: string): void {
    const index = this.codiciAtecoSel.findIndex((ateco) => {
      return ateco._id === codiceAteco;
    });

    if (index >= 0) {
      this.codiciAtecoSel.splice(index, 1);

      this.announcer.announce(`Removed ${codiceAteco}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.codiciAtecoSel.push(event.option.value);
    this.codAteco.nativeElement.value = '';
    this.codiciAtecoCtrl?.setValue('');
  }
  /* CHIP */
}

