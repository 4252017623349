import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AmbitiService, Ambito } from 'src/app/services/ambiti/ambiti.service';
import { DialogCreaAmbitoComponent } from './dialog/dialog-crea-ambito/dialog-crea-ambito.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-cfg-ambiti',
  templateUrl: './cfg-ambiti.component.html',
  styleUrls: ['./cfg-ambiti.component.scss']
})
export class CfgAmbitiComponent implements OnInit {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome Breve',
      forControlName: 'nomeBreve',
      input: 'text',
    },
    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    titoloBreve: {
      title: 'Nome Breve',
      value: 'nomeBreve',
      sortable: true
    },
    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    /*    duplica: {
         type: 'button',
         buttonIcon: 'content_copy',
         title: 'Duplica',
         buttonId: 'duplica',
       }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(
    public dialog: MatDialog,
    private ambitiService: AmbitiService,
    private utilityService: UtilityService,
    public utenteService: UtenteService
  ) { }

  ngOnInit(): void {

  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovoAmbito(recordAmbito?: Ambito) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaAmbitoComponent, {
      data: {
        ambito: recordAmbito,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      height: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [
        {
          chiave: 'index',
          sort: 'asc',
        },
        {
          chiave: 'titoloBreve',
          sort: 'asc'
        }];
    }

    return this.ambitiService.getAmbiti(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovoAmbito(event.data);
        break;
      case 'elimina':
        this._eliminaAmbito(event.data.id);
        break;
      case 'duplica':
        this._duplicaAmbito();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare 
   * @param idRiga id della riga da eliminare 
   */
  private _eliminaAmbito(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'Ambito?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.ambitiService.deleteAmbito(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err?.error?.message || 'Eliminazione non riuscita',
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un ambito
   */
  public _duplicaAmbito() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }
}
