import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { DialogCreaCfgImpattoComponent } from 'src/app/page/configurazioni/cfg-impatti/dialog/dialog-crea-cfg-impatto/dialog-crea-cfg-impatto.component';
import { Observable, catchError, firstValueFrom, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-selezione-impatti-bilancio',
  templateUrl: '../../selezione-elementi-questionario.component.html',
  styleUrls: ['../../selezione-elementi-questionario.component.scss']
})
export class SelezioneImpattiBilancioComponent extends SelezioneElementiQuestionarioComponent {
  override editElementoListaChk(idAmbito: string, idTematica: string, impatto?: any): void {

    this._dialogCreaCfgImpatto(idAmbito, idTematica, impatto).subscribe();

  }
  async salvataggio(idAmbito: string, idTematica: string, idImpatto: string) {
    try {
      // Salvo i dati modificati
      await firstValueFrom(this.salva(true));

      // Get dei dati aggiornati 
      await firstValueFrom(this._getImpattiBilancio());

      // Scroll all'elemento modificato 
      setTimeout(() => {
        this.scrollToElement(idAmbito, idTematica, idImpatto);
      }, 0);
    } catch (error) {
      console.error(error);
    }
  }

  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del impatto 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idImpatto: string, idFigAziendale: string): void {
    this.spinnerOver.show();
    this.bilancioService.putAssegnaFiguraImpattoBilancio(this.idContest, idAmbito, idTematica, idImpatto, idFigAziendale).subscribe({
      next: () => {
        this.salvataggio(idAmbito, idTematica, idImpatto);

      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }


  private _getImpattiBilancio() {
    this.spinnerOver.show();

    return this.bilancioService.getImpattiBilancio(this.idContest).pipe(
      tap((esito) => {
        this.objQuestionario = esito;
      }),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli impatti',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of()
      }),
      tap({
        finalize: () => this.spinnerOver.hide()
      })
    )
  }

  salva(noCtr?: boolean) {
    let impattiSelezionati = this.oggettoElementiSelezionati();


    if (!noCtr) {
      //Controllo se è stato selezionato almeno un impatto per tematica 
      if (this.ctrElemChecked(impattiSelezionati, 'impatto')) {
        return of(false);
      }
    }

    return this._salvaSurveyElemSel(impattiSelezionati);
  }


  private _salvaSurveyElemSel(impattiSelezionati: any): Observable<boolean> {

    if (this.idContest) {
      return this.bilancioService.putImpattiBilancioSelezionati(
        this.idContest, impattiSelezionati).pipe(
          map((result: any) => {


            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio (sconosciuto)',
              bottoni: [{ nome_btn: 'Ok' }]
            });

            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }

  override selezionaCheck(impatto: any, checked: boolean, idAmbito: string, idTematica: string) {

    if (this.stato !== 'PUBBLICATO' && !impatto.materiale) {

      if (this.isImpatto && checked && (!impatto.impattoPositivoNegativo?.length || !impatto.impattoPotenzialeEffettivo?.length)) {

        impatto.selected = true;

        this._dialogCreaCfgImpatto(idAmbito, idTematica, impatto).subscribe((result) => {
          if (!result) {
            impatto.selected = false;
            checked = false;
          }
        });

      }

      impatto.selected = checked;
      this.updateFormStatus();
      this.ctrCheck(idAmbito, idTematica, impatto, checked);

    }

  }

  private _dialogCreaCfgImpatto(idAmbito: string, idTematica: string, impatto: any): Observable<boolean> {

    const dialogCreaImpatto = this.dialog.open(DialogCreaCfgImpattoComponent, {
      data: {
        impatto: impatto,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'bilancio'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    return dialogCreaImpatto.afterClosed()
      .pipe(
        switchMap((result) => {
          if (result) {
            this.spinnerOver.show();
            this.salvataggio(idAmbito, idTematica, impatto?.id);
            return of(true);
          } else {
            return of(false);
          }
        })
      );
  }
}
