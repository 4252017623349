
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AfterViewInit, Component, Inject, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LicenzeService, PromotoreLicenze } from 'src/app/services/licenze/licenze.service';
import { RuoliList } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { finalize, firstValueFrom, tap } from 'rxjs';
import { startOfDay, toDate } from 'date-fns';


@Component({
  selector: 'app-gestione-licenze',
  templateUrl: './gestione-licenze.component.html',
  styleUrls: ['./gestione-licenze.component.scss']
})
export class GestioneLicenzeComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  // Variabili 
  private _minDate = new Date();

  get minDate() {
    return this._minDate;
  }

  @Input()
  set minDate(data: Date) {
    this._minDate = data;
  }

  public arrayCodicePromotore: PromotoreLicenze[] = [];

  /**
   * Metodo che filtra le date abilitate e non in base alla minDate.
   * Sostituito a [min]="minDate" perchè cambiando il valore a runtime non sembrava aggiornare il calendario.
   * 
   * @param d 
   * @returns 
   */
  public dateFilter = (d: Date | null) => {

    if (d != null) {

      const dataMezzanotte = startOfDay(d);
      const minDateMezzanotte = startOfDay(this.minDate);

      return dataMezzanotte >= minDateMezzanotte;
    } else {
      return false;
    }
  }

  //Form Control Group
  public formLicenze = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    nrAutovalutazioni: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),

    nrBilanci: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),

    nrSintesi: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrMaterialita: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrPolicy: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrPiano: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    nrMaxAziende: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: []
    }),
    codicePromotore: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    dataAttivazione: new FormControl<Date | string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    dataScadenza: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    codicePacchetto: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  })

  private _isSostenibileGo: boolean = false;

  @Input()
  set isSostenibileGo(isSostenibileGo: boolean) {
    this._isSostenibileGo = isSostenibileGo
  }
  get isSostenibileGo() {
    return this._isSostenibileGo;
  }

  private _isNuovo: boolean = false;

  @Input()
  set isNuovo(isNuovo: boolean) {
    this._isNuovo = isNuovo
  }
  get isNuovo() {
    return this._isNuovo;
  }

  constructor(
    private readonly licenzeService: LicenzeService,
    private readonly utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: {
      tipo: string,
      idUtente: string;
      ruoloUtente: RuoliList,
      codiceLicenza: string;
      record: any;
      codicePromotore: string | undefined;
    }
  ) { }

  ngAfterViewInit(): void {

    this._init();

  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-" "," "."
    if (event.key === '-' || event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento dei caratteri "-" "," "."
      event.preventDefault();
    }
  }

  compareCodicePromotore(promotore1: PromotoreLicenze, promotore2: PromotoreLicenze): boolean {
    return promotore1?.codice === promotore2?.codice;
  }

  calcolaDataScadenza(dataAttivazione: Date | undefined): Date {
    if (!dataAttivazione) {
      return new Date();
    }

    // Se utente go o pacchetto aggiuntivo, aggiungi 365 giorni alla data di attivazione
    if (this.isSostenibileGo || this.data.tipo === 'aggiuntivo') {
      const dataScadenza = new Date(dataAttivazione);
      dataScadenza.setDate(dataScadenza.getDate() + 365); // Aggiungi 365 giorni
      return dataScadenza;
    } else {
      // Se è cloud , calcolo la fine dell'anno successivo
      const dataFineAnno = new Date(dataAttivazione);
      dataFineAnno.setFullYear(dataAttivazione.getFullYear()); // Aggiungi 1 anno
      dataFineAnno.setMonth(11); // Imposta dicembre
      dataFineAnno.setDate(31); // Imposta il 31 dicembre
      dataFineAnno.setHours(23, 59, 59, 999); // Imposta l'orario alla fine della giornata
      return dataFineAnno;
    }
  }


  onDataAttivazioneChange(event: any): void {
    this.formLicenze.get('dataScadenza')?.setValue(this.calcolaDataScadenza(event.value));
  }

  public setNrMaxAziende(nr: number){
    this.formLicenze.get('nrMaxAziende')?.setValue(nr);
  }

  private async _init() {

    if (this.isNuovo) {
      this.formLicenze.get('nrMaxAziende')?.addValidators([Validators.required, Validators.min(1)]);
    } else {
      this.formLicenze.get('nrMaxAziende')?.removeValidators([Validators.required, Validators.min(1)]);
    }
    this.formLicenze.get('nrMaxAziende')?.updateValueAndValidity();

    this.spinnerOver.show();
    //Promotori

    await this._getPromotori();

    if (this.data.record) {
      this._valorizzaForm(this.data.record); // sono in modifica della licenza a livello di rinnovo e aggiuntivo

    } else if (this.data.codicePromotore && !this.data.record) { // Sono a creare un rinnovo o aggiuntivo da 0

      // Setto codice Promotore
      const promotoreTrovato = this.arrayCodicePromotore.find(
        (codice: PromotoreLicenze) => codice.codice === this.data.codicePromotore
      );

      this.formLicenze.get('codicePromotore')?.setValue((promotoreTrovato as PromotoreLicenze).codice)
    }

    if (this.formLicenze.get('codicePromotore')?.value) {
      this.formLicenze.get('codicePromotore')?.disable();
    }
  }

  /**
   * Get Promotori
   */
  private async _getPromotori(): Promise<any> {

    this.spinnerOver.show();

    try {
      await firstValueFrom(this.licenzeService.getPromotoriList()
        .pipe(
          finalize(() => this.spinnerOver.hide()),
          tap(esito => {
            this.arrayCodicePromotore = esito;
          })
        )
      )
    } catch (error) {
      console.error(error);
    }

  }

  /**
   * Mi cerca la licenza base 
   * @param idUtente 
   * @param codiceLicenza 
   */
  public getLicenzeBase(idUtente: string, codiceLicenza: string) {
    this.spinnerOver.show();

    this.licenzeService.getLicenzeByUtente(idUtente, codiceLicenza)
      .pipe(finalize(() => this.spinnerOver.hide()))
      .subscribe({
        next: (esito) => {
          if (esito) {
            this._valorizzaForm(esito);
          }
        },
        error: (err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
  }

  /**
   * Metodo che mi valorizza la form licenze 
   * @param licenza oggetto dal esterno con dati della licenza
   */
  private _valorizzaForm(licenza: any) {

    // Valorizzo i campi della form meno che i contatori
    Object.keys(licenza).forEach((key) => {
      this.formLicenze.get(key)?.setValue(licenza[key as keyof typeof licenza]);
    });


    const contatoriMap = {
      nrMaterialita: 'MATERIALITA',
      nrAutovalutazioni: 'AUTOVALUTAZIONE',
      nrBilanci: 'BILANCIO',
      nrSintesi: 'SINTESI',
      nrPolicy: 'POLICY',
      nrPiano: 'PIANO',
      nrMaxAziende: 'AZIENDA',
    };

    // Valorizzo i campi della form con i contatori
    Object.entries(contatoriMap).forEach(([formKey, contatoreKey]) => {
      this.formLicenze.get(formKey)?.setValue(licenza.contatori?.[contatoreKey]);
    });
  }

  isFormLicenzeValid(): boolean {
    if (!this.formLicenze.valid) {
      Object.values(this.formLicenze.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return false;
    }
    return true;
  }

  /**
   * Salva Licenze BASE 
   * @param idUtente 
   */
  async salvaLicenzaBase(idUtente: string): Promise<boolean> {

    if (!this.isFormLicenzeValid()) return false;

    if (!idUtente) return false;

    this.spinnerOver.show();

    const objLicenze = this.getObjLicenze(idUtente);
    try {
      await firstValueFrom(
        this.licenzeService.postAbilitaLicenza(idUtente, objLicenze).pipe(
          finalize(() => this.spinnerOver.hide())
        )
      );

      this.utilityService.opneSnackBar('Licenze salvate', '', {
        duration: 2000,
        panelClass: ['success-snackbar'],
      });
      return true;
    } catch (err: any) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: err?.error?.message || 'Errore Generico',
        bottoni: [{ nome_btn: 'Ok' }],
      });
      return false
    }
  }
  /**
   * Aggiorna Licenze Base 
   * @param idUtente 
   * @param codiceLicenza 
   * @returns 
   */
  async aggiornaLicenzaBase(idUtente: string, codiceLicenza: string, codicePacchetto: string): Promise<boolean> {

    if (!this.isFormLicenzeValid()) return false;

    if (!idUtente) return false;

    this.spinnerOver.show();

    const objLicenze = this.getObjLicenze(idUtente);

    try {
      await firstValueFrom(
        this.licenzeService.putLicenzaBase(idUtente, codiceLicenza, codicePacchetto, objLicenze).pipe(
          finalize(() => this.spinnerOver.hide())
        )
      );

      this.utilityService.opneSnackBar('Licenze aggiornate ', '', {
        duration: 2000,
        panelClass: ['success-snackbar']
      });
      return true;
    } catch (err: any) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: err?.error?.message || 'Errore Generico',
        bottoni: [{ nome_btn: 'Ok' }],
      });
      return false
    }
  }

  /**
   * Ritorna l'oggetto licenza 
   * @param idUtente 
   * @returns 
   */
  getObjLicenze(idUtente: string) {
    const licenza = this.formLicenze.getRawValue();
    const objLicenze = {
      id: licenza.id,
      idUtente: idUtente,
      codicePromotore: licenza.codicePromotore,
      dataAttivazione: licenza.dataAttivazione,
      dataScadenza: licenza.dataScadenza,
      acquisti: {
        MATERIALITA: licenza.nrMaterialita,
        SINTESI: licenza.nrSintesi,
        BILANCIO: licenza.nrBilanci,
        POLICY: licenza.nrPolicy,
        PIANO: licenza.nrPiano,
        AUTOVALUTAZIONE: licenza.nrAutovalutazioni,
        AZIENDA: licenza.nrMaxAziende,
      }
    };
    return objLicenze
  }

}
