<div style="align-items: center;display: flex;justify-content: space-between;width: 100%;height: 50px;">
    <div>
        <div style="font-size: 28px;color: var(--mat-color-accent);font-family: Abel;">Informativa Aziendale
        </div>
    </div>
    <div style="height: 1px;background-color: #707070;flex:1;margin-right: 20px;margin-left: 20px;">
    </div>

</div>
<div mat-dialog-content class="dialog-center-compilazione">

    <app-spinner-overlay></app-spinner-overlay>
    <ng-container *ngIf="inizializzato">
        <ng-scrollbar>
            <div class="ambito" *ngFor="let sez of sezioni; let idxSez = index">
                <div class="titolo">
                    {{infoAz.titolo}} - {{infoAz.descrizione}}
                </div>
                <div *ngFor="let dom of sez.domande; let idxTem = index">

                    <div class="tematiche" appFormContainer [larghezza]="3" [rowGap]="10">

                        <div style="height: 20px;"></div>

                        <ng-container *ngIf="dom.tipo === 'scelta_multipla'">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <section>
                                <ng-container *ngFor="let opz of dom.opzioni; let idxOpz = index">

                                    <p style="margin: 0px;">
                                        <mat-checkbox [value]="opz.chiave"
                                            [checked]="checkSotto(dom.risposte , opz.chiave)"
                                            (change)="cambioCheck($event,idxSez,idxTem,opz.chiave)" [disabled]="inConf">
                                            {{opz.valore}}
                                        </mat-checkbox>
                                    </p>

                                </ng-container>
                            </section>
                        </ng-container>

                        <ng-container *ngIf="dom.tipo === 'scelta_singola'">

                            <mat-label class="descrizione" style="padding-top: 30px;" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                                [value]="dom?.risposte?.length ? dom?.risposte![0] : null"
                                (change)="onRadioChange($event,idxSez,idxTem)">

                                <ng-container *ngFor="let opz of dom.opzioni; let idxOpz = index">
                                    <mat-radio-button [value]="opz.chiave" [disabled]="inConf">
                                        {{opz.valore}}
                                    </mat-radio-button>
                                    <!-- <div class="sotto-opzioni"
                                *ngIf="opz.sottoOpzioni?.length && radioGroup.value === opz.chiave">

                                <div *ngFor="let opzSec of opz.sottoOpzioni" class="radio-group">

                                    <mat-checkbox [value]="opzSec.chiave"
                                        [checked]="checkSotto(opz.sottoRisposte , opzSec.chiave)"
                                        (change)="cambioCheck($event,idxAmb,idxTem,idxOpz,opzSec.chiave)">
                                        {{opzSec.valore}}
                                    </mat-checkbox>

                                </div>

                            </div> -->
                                </ng-container>
                            </mat-radio-group>
                            <div class="separa"></div>
                        </ng-container>

                        <ng-container *ngIf="dom.tipo === 'testo'">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="1">
                                <mat-label>Risposta</mat-label>
                                <input matInput [placeholder]="'Risposta'" [(ngModel)]="dom.risposta"
                                    [disabled]="inConf" />
                                <mat-error appFormError></mat-error>
                            </mat-form-field>
                            <div class="separa"></div>
                        </ng-container>

                        <ng-container *ngIf="dom.tipo === 'numero_intero'">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="1" *ngIf="dom.idCategoriaUnitaDiMisura">
                                <mat-label>Unità Di Misura</mat-label>
                                <mat-select [placeholder]="'Unità Di Misura'" [(ngModel)]="$any(dom.risposta).um"
                                    [disabled]="inConf">
                                    <mat-option *ngFor="let um of arrayUm[dom.idCategoriaUnitaDiMisura]"
                                        [value]="um.id">{{um.descrizione}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appFormElement [larghezza]="1">
                                <mat-label>Risposta</mat-label>
                                <input matInput [placeholder]="'Risposta'" [(ngModel)]="$any(dom.risposta).valore"
                                    type="number" appInputNumber [disabled]="inConf" />
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div class="separa"></div>
                        </ng-container>

                        <ng-container *ngIf="dom.tipo === 'numero_decimale'">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="1" [riempi]="true"
                                *ngIf="dom.idCategoriaUnitaDiMisura">
                                <mat-label>Unità Di Misura</mat-label>
                                <mat-select [placeholder]="'Unità Di Misura'" [(ngModel)]="$any(dom.risposta).um"
                                    [disabled]="inConf">
                                    <mat-option *ngFor="let um of arrayUm[dom.idCategoriaUnitaDiMisura]"
                                        [value]="um.id">{{um.descrizione}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appFormElement [larghezza]="1">
                                <mat-label>Risposta</mat-label>
                                <input matInput [placeholder]="'Risposta'" [(ngModel)]="$any(dom.risposta).valore"
                                    type="number" appInputNumber [disabled]="inConf" step="0.01" />
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <div class="separa"></div>
                        </ng-container>

                        <ng-container *ngIf="dom.tipo === 'data'">

                            <mat-label class="descrizione" appFormElement [larghezza]="3"
                                [riempi]="true">{{dom.domanda}}</mat-label>

                            <mat-form-field appFormElement [larghezza]="1">
                                <mat-label>Risposta</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="dom.data" [disabled]="inConf">
                                <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>
                            <div class="separa"></div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </ng-container>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div *ngIf="!inConf">
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Conferma
        </button>
    </div>
</div>