<h2 mat-dialog-title>Stakeholder</h2>
<div mat-dialog-content class="dialog-center-cont">

    <app-spinner-overlay></app-spinner-overlay>

    <form [formGroup]="formStakeholder" appFormContainer [larghezza]="1">
        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Nome </mat-label>
            <input matInput placeholder="Nome " formControlName="nome" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Cognome</mat-label>
            <input matInput placeholder="Cognome" formControlName="cognome" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Modalità Compilazione</mat-label>
            <mat-select (selectionChange)="validaMail($event)" formControlName="modalitaCompilazione"
                placeholder="Modalità Compilazione">
                <mat-option *ngFor="let mod of arrayModalita" [value]="mod.id">{{mod.descrizione}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email" type="email" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Tipologia</mat-label>
            <mat-select formControlName="tipologia" placeholder="Tipologia">
                <mat-option *ngFor="let tipo of arrayTipologia" [value]="tipo.id">{{tipo.descrizione}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="separatore" appFormElement [larghezza]="1">
            <app-separatore-label titoloSeparatore="Categoria" [styleSeparatore]="'small'"></app-separatore-label>
        </div>


        <mat-radio-group appFormElement [larghezza]="1" [riempi]="true" formControlName="categoria">
            <mat-radio-button *ngFor="let cat of arrayCategorie" [value]="cat.id">
                {{cat.titolo}}
            </mat-radio-button>
        </mat-radio-group>

        <div appFormElement [larghezza]="1" [riempi]="true">
            <button mat-stroked-button (click)="addCategoria()" style="height: 56px;width: 100%;font-weight: bold;"
                [disabled]="(data.isStakeHolderInMat || (this.formStakeholder.get('tipologia')?.value ? false : true) || data.stakeholder)">
                Aggiungi Categoria
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div *ngIf="!isPermessoSoloRead && !this.data?.readOnly">
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>