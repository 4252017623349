<app-spinner-overlay></app-spinner-overlay>

<app-titolo-subtitolo *ngIf="generalita" [titolo]="'Gestione Licenze per Utente'"
    [subTitolo]="generalita"></app-titolo-subtitolo>

<div mat-dialog-content class="dialog-center-cont">
    <div *ngIf="error">{{error}}</div>
    <app-riepilogo-licenze [utente]="utente" *ngIf="utente"></app-riepilogo-licenze>

    <div class="spinner" *ngIf="!error && !utente">
        <mat-spinner style="height: 50px;"></mat-spinner>
    </div>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
</div>