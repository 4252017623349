import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle'; // <--- IMPORT REGISTER HERE
import { TemaService } from './services/tema/tema.service';

register(); // <--- INIT SWIPER HERE


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private temaService: TemaService
  ){
    this.temaService.temaUpdated.subscribe((tema)=>{
      document.title = temaService.title;
    });
  }
}
