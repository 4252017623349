<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Obiettivi"
            subTitolo="Configurazione tipologie di obiettivi del Piano di Sostenibilità."></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd style="display: flex;justify-content: center; column-gap: 23px;">
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovo()">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>