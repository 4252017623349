import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { PolicyWebService } from './policy-web.service';
import { Injectable } from '@angular/core';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Ambito } from '../ambiti/ambiti.service';
import { ComitatiConFigAz } from '../comitati/comitati.service';
import { CfgStampa, GruppoCfgStampa } from '../config-stampe/config-stampe.service';

export interface PolicySostenibilita {
  id?: string;
  idAzienda?: string;
  anno?: string;
  idQuestionario?: string;
  nome?: string;
  idMaterialita?: string;
  idAutovalutazioneCompilata: string;
  idSintesiSostenibilita: string;
  idPianoSostenibilita: string;
  idBilancioSostenibilita: string;
  idAssessment: string;
  settori?: Settore[];
  sottoSettori?: SottoSettore[];
  stato: string,
}

export interface PerimetriDestinatari {
  id: string;
  descrizione: string;
  lineaGuidaPolicy: string;
  lineaGuidaDestinatario: string;
  selected: boolean;
  lineaGuidaCompilata: string;
  chiaveElemento: string;
}

export interface PerimetriDestinatariGrp {
  descrizione: string;
  perimetriDestinatari: PerimetriDestinatari[]
}

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(
    private policyWebService: PolicyWebService,
  ) { }
  /*  Destinatari Policy */
  public getDestinatariPolicy(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.policyWebService.getDestinatariPolicy(page, pageSize, ricerca, filters, sortBy);
  }

  public postDestinatariPolicy(data: any) {
    return this.policyWebService.postDestinatariPolicy(data);
  }


  public deleteDestinatariPolicy(idRiga: any) {
    return this.policyWebService.deleteDestinatariPolicy(idRiga);
  }

  public putDestinatariPolicy(id: string, destinatarioPolicy: any) {
    return this.policyWebService.putDestinatariPolicy(id, destinatarioPolicy);
  }

  /* Perimetro di applicazione Policy */
  public getPerimetroPolicy(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.policyWebService.getPerimetroPolicy(page, pageSize, ricerca, filters, sortBy);
  }

  public postPerimetroPolicy(data: any) {
    return this.policyWebService.postPerimetroPolicy(data);
  }


  public deletePerimetroPolicy(idRiga: any) {
    return this.policyWebService.deletePerimetroPolicy(idRiga);
  }

  public putPerimetroPolicy(id: string, perimetroPolicy: any) {
    return this.policyWebService.putPerimetroPolicy(id, perimetroPolicy);
  }

  /* Policy di Sostenibilità */
  public getPolicySostenibilita(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.policyWebService.getPolicySostenibilita(page, pageSize, ricerca, filters, sortBy);
  }

  public deletePolicySostenibilita(idRiga: any) {
    return this.policyWebService.deletePolicySostenibilita(idRiga);
  }


  /* ---------------- C R E A Z I O N E   P O L I C Y  ---- step1 ------------*/

  /**
   * Creazione step 1 policy
   * @param policySostenibilita oggetto policy di sostenibilita
   * @returns 
   */
  public postCreazionePolicyStep1(policySostenibilita: any) {
    return this.policyWebService.postCreazionePolicyStep1(policySostenibilita);
  }

  /**
   * Aggiornamento step 1 policy
   * @param policySostenibilita id policy di sostenibilita
   * @returns 
   */
  public putAggiornamentoPolicyStep1(idPolicySostenibilita: any) {
    return this.policyWebService.putAggiornamentoPolicyStep1(idPolicySostenibilita);
  }

  /* ----------------T E M A T I C H E    P O L I C Y  ---- step2 ------------*/
  public getQuestionarioPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.getQuestionarioPolicy(idPolicySostenibilita);
  }

  public putQuestionarioPolicy(idPolicySostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.policyWebService.putQuestionarioPolicy(idPolicySostenibilita, idSurvey, ambiti, settori, titolo, stato, tipo);
  }

  /* ------------------------- I M P E G N I    -------------------------------- */
  /**
  * GET IMPEGNI POLICY
  * @param idPolicySostenibilita 
  * @returns obj con lista ambiti - tematiche - impegni
  */
  public getImpegniPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.getImpegniPolicy(idPolicySostenibilita);
  }

  /**
  * SALVATAGGIO IMPEGNI POLICY SELEZIONATI
  * @param idPolicySostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putImpegniPolicySelezionati(idPolicySostenibilita: string, impegniSelezionati: any) {
    return this.policyWebService.putImpegniPolicySelezionati(idPolicySostenibilita, impegniSelezionati);
  }

  /**
  * ASSEGNA FIGURA AD IMPEGNO POLICY
  * @param idPolicySostenibilita 
  * @param policy
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraImpegnoPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idPolicy: string, idFigura: string) {
    return this.policyWebService.putAssegnaFiguraImpegnoPolicy(idPolicySostenibilita, idAmbito, idTematica, idPolicy, idFigura);
  }

  public putImpegniEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, impegno: any) {
    return this.policyWebService.putImpegniEffimeri(idPolicySostenibilita, idAmbito, idTematica, impegno);
  }

  /* ------------------------- A Z I O N I   B P  -------------------------------- */
  /**
  * GET AZIONI BP POLICY
  * @param idPolicySostenibilita 
  * @returns obj con lista ambiti - tematiche - azioniBP
  */
  public getAzioniBPPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.getAzioniBPPolicy(idPolicySostenibilita);
  }

  /**
  * SALVATAGGIO AZIONI BP POLICY SELEZIONATI
  * @param idPolicySostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putAzioniBPPolicySelezionati(idPolicySostenibilita: string, azioniBPSelezionati: any) {
    return this.policyWebService.putAzioniBPPolicySelezionati(idPolicySostenibilita, azioniBPSelezionati);
  }

  /**
  * ASSEGNA FIGURA AD AZIONI BP POLICY
  * @param idPolicySostenibilita 
  * @param policy
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraAzioniBPPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idPolicy: string, idFigura: string) {
    return this.policyWebService.putAssegnaFiguraAzioniBPPolicy(idPolicySostenibilita, idAmbito, idTematica, idPolicy, idFigura);
  }

  public putAzioniBPEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, azioniBP: any) {
    return this.policyWebService.putAzioniBPEffimeri(idPolicySostenibilita, idAmbito, idTematica, azioniBP);
  }

  /* ------------------------- C E R T I F I C A Z I O N I  ******************/
  /**
  * GET CERTIFICAZIONI POLICY
  * @param idPolicySostenibilita 
  * @returns obj con lista ambiti - tematiche - certificazioni
  */
  public getCertificazioniPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.getCertificazioniPolicy(idPolicySostenibilita);
  }

  /**
  * SALVATAGGIO CERTIFICAZIONI POLICY SELEZIONATI
  * @param idPolicySostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putCertificazioniPolicySelezionate(idPolicySostenibilita: string, certificazioniSelezionate: any) {
    return this.policyWebService.putCertificazioniPolicySelezionate(idPolicySostenibilita, certificazioniSelezionate);
  }

  /**
  * ASSEGNA FIGURA AD CERTIFICAZIONI POLICY
  * @param idPolicySostenibilita 
  * @param policy
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraCertificazioniPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.policyWebService.putAssegnaFiguraCertificazioniPolicy(idPolicySostenibilita, idAmbito, idTematica, idCertificazioni, idFigura);
  }

  public putCertificazioniEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, certificazioni: any) {
    return this.policyWebService.putCertificazioniEffimeri(idPolicySostenibilita, idAmbito, idTematica, certificazioni);
  }


  /* -------------------------  C O M I T A T I    -------------------------------- */
  /**
  * GET COMITATI POLICY
  * @param idPolicySostenibilita 
  * @returns obj con lista ambiti - tematiche - comitati
  */
  public getComitatiPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.getComitatiPolicy(idPolicySostenibilita);
  }

  public putCompilazioneComitati(idPolicySostenibilita: string, idComitato: string, risposta: string) {
    return this.policyWebService.putCompilazioneComitati(idPolicySostenibilita, idComitato, risposta);
  }

  /**
  * SALVATAGGIO COMITATI POLICY SELEZIONATI
  * @param idPolicySostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putComitatiPolicySelezionati(idPolicySostenibilita: string, objComitati: ComitatiConFigAz[]) {
    return this.policyWebService.putComitatiPolicySelezionati(idPolicySostenibilita, objComitati);
  }

  /**
  * ASSEGNA FIGURA AD COMITATO POLICY
  * @param idPolicySostenibilita 
  * @param policy
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraComitatoPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idPolicy: string, idFigura: string) {
    return this.policyWebService.putAssegnaFiguraComitatoPolicy(idPolicySostenibilita, idAmbito, idTematica, idPolicy, idFigura);
  }

  public putComitatiEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, comitato: any) {
    return this.policyWebService.putComitatiEffimeri(idPolicySostenibilita, idAmbito, idTematica, comitato);
  }

  /* --------------------------- FIGURE AZIENDALI --- ---------------*/

  public getListaAssegnazioniFigAz(idPolicy: string) {
    return this.policyWebService.getListaAssegnazioniFigAz(idPolicy);
  }

  /* ------------------------- C R U S C O T T O     STEP 9  -------------------------------- */

  public getCruscotti(
    idPolicySostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.policyWebService.getCruscotti(idPolicySostenibilita, page, pageSize, ricerca, filters, sortBy);
  }

  public getTotaliCruscotto(idPolicySostenibilita: string) {
    return this.policyWebService.getTotaliCruscotto(idPolicySostenibilita);
  }

  putCambiaFigura(idPolicySostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.policyWebService.putCambiaFigura(idPolicySostenibilita, idFiguraPrecedente, idFiguraNuova);
  }

  public inviaMailMultiple(idPolicySostenibilita: string) {
    return this.policyWebService.inviaMailMultiple(idPolicySostenibilita);
  }

  public inviaNotificaMultiple(idPolicySostenibilita: string) {
    return this.policyWebService.inviaNotificaMultiple(idPolicySostenibilita);
  }

  public putTemplateMail(idPolicySostenibilita: string, templateMail: any) {
    return this.policyWebService.putTemplateMail(idPolicySostenibilita, templateMail);
  }

  public getTemplateMail(idPolicySostenibilita: string) {
    return this.policyWebService.getTemplateMail(idPolicySostenibilita);
  }

  public getElementoDaCompilare(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.policyWebService.getElementoDaCompilare(idPolicySostenibilita, idAmbito, idTematica, idElemento);
  }

  public postElementoDaCompilare(
    idPolicySostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    rispostaBreve: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.policyWebService.postElementoDaCompilare(
      idPolicySostenibilita,
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      rispostaBreve,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento);
  }

  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.policyWebService.getElementiEsterniDaCompilare(temporaryToken);
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.policyWebService.postElementiEsterniDaCompilare(temporaryToken, objEsterna);
  }

  public invioTestMail(messaggio: string, email: string, idPolicySostenibilita: string) {
    return this.policyWebService.invioTestMail(messaggio, email, idPolicySostenibilita);
  }

  public getAnteprimaPolicyCompilato(idPolicy: string, idAzienda: string) {
    return this.policyWebService.getAnteprimaPolicyCompilato(idPolicy, idAzienda);
  }

  /* -------------------------  PERIMETRI E DESTINATARI  STEP 9  -------------------------------- */

  public getPerimetriDestinatari(idPolicy: string) {
    return this.policyWebService.getPerimetriDestinatari(idPolicy);
  };

  public putPerimetriDestinatari(idPolicySostenibilita: string, perimetriDestinatari: PerimetriDestinatariGrp[]) {
    return this.policyWebService.putPerimetriDestinatari(idPolicySostenibilita, perimetriDestinatari);
  };

  public putCompilazionePerimetriInPolicy(idPolicySostenibilita: string, idPerimetro: string, descrizione: string, lineaGuidaCompilata: string) {
    return this.policyWebService.putCompilazionePerimetriInPolicy(idPolicySostenibilita, idPerimetro, descrizione, lineaGuidaCompilata);
  };

  public putCompilazioneDestinatariInPolicy(idPolicySostenibilita: string, idDestinatario: string, descrizione: string, lineaGuidaCompilata: string) {
    return this.policyWebService.putCompilazioneDestinatariInPolicy(idPolicySostenibilita, idDestinatario, descrizione, lineaGuidaCompilata);
  };


  public pubblicaPolicy(idPolicySostenibilita: string) {
    return this.policyWebService.pubblicaPolicy(idPolicySostenibilita);
  }

  /**
 *  ritorna policy pubblicate
 * @returns 
 */
  public getPolicyPubblicate(idSettore?: string, idSottoSettori?: string[]) {
    return this.policyWebService.getPolicyPubblicate(idSettore, idSottoSettori);
  }

  /**
 * ritorna policy corrispondete all'id
 * @param idPolicy 
 * @returns 
 */
  public getPolicyById(idPolicy: string) {
    return this.policyWebService.getPolicyById(idPolicy);
  }


  /* ------------------------- S T A M P A -------------------------------- */

  /**
  * GET Configurazioni stampa 
  * @param idPolicy 
  * @returns List<GruppoConfigRisultatiSintesiValori>
  */
  public getConfigStampa(idPolicy: string) {
    return this.policyWebService.getConfigStampa(idPolicy);
  }
  public postSalvaElementiStampa(idPolicy: string, cfgStampaPolicy: GruppoCfgStampa) {
    return this.policyWebService.postSalvaElementiStampa(idPolicy, cfgStampaPolicy);
  }
  public postStampaPolicy(idPolicy: string) {
    return this.policyWebService.postStampaPolicy(idPolicy);
  }


}