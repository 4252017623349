import { Injectable } from '@angular/core';
import { PianoWebService } from './piano-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore, SottoSettore } from '../settori/settori.service';
import { Ambito } from '../ambiti/ambiti.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';


export interface Obiettivo {
  id: string,
  titolo: string,
  descrizione: string,
  creatoDaContesto: string,
  selected: string,
  compilato: string,
}

export interface PianoSostenibilita {
  id?: string;
  idAzienda?: string;
  anno?: string;
  idQuestionario?: string;
  nome?: string;
  idMaterialita?: string;
  idAutovalutazioneCompilata: string;
  idSintesiSostenibilita: string;
  idBilancioSostenibilita: string;
  idPolicySostenibilita: string;
  idAssessment: string;
  settori?: Settore[];
  sottoSettori?: SottoSettore[];
  stato: string;
}

@Injectable({
  providedIn: 'root'
})
export class PianoService {

  constructor(
    private pianoWebService: PianoWebService,
  ) { }

  /* Piano di Sostenibilità */
  public getPianoSostenibilita(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getPianoSostenibilita(page, pageSize, ricerca, filters, sortBy);
  }

  public deletePianoSostenibilita(idRiga: any) {
    return this.pianoWebService.deletePianoSostenibilita(idRiga);
  }

  /*  Obiettivi Piano */
  public getObiettivi(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getObiettivi(page, pageSize, ricerca, filters, sortBy);
  }

  public postObiettiviPiano(data: any) {
    return this.pianoWebService.postObiettiviPiano(data);
  }


  public deleteObiettiviPiano(idRiga: any) {
    return this.pianoWebService.deleteObiettiviPiano(idRiga);
  }

  public putObiettiviPiano(id: string, obiettivoPiano: any) {
    return this.pianoWebService.putObiettiviPiano(id, obiettivoPiano);
  }
  /* ---------------- C R E A Z I O N E   P O L I C Y  ---- step1 ------------*/

  /**
   * Creazione step 1 piano
   * @param pianoSostenibilita oggetto piano di sostenibilita
   * @returns 
   */
  public postCreazionePianoStep1(pianoSostenibilita: any) {
    return this.pianoWebService.postCreazionePianoStep1(pianoSostenibilita);
  }

  /**
   * Aggiornamento step 1 piano
   * @param pianoSostenibilita id piano di sostenibilita
   * @returns 
   */
  public putAggiornamentoPianoStep1(idPianoSostenibilita: any) {
    return this.pianoWebService.putAggiornamentoPianoStep1(idPianoSostenibilita);
  }

  /* ----------------T E M A T I C H E   P I A N O  ---- step2 ------------*/
  public getQuestionarioPiano(idPianoSostenibilita: string) {
    return this.pianoWebService.getQuestionarioPiano(idPianoSostenibilita);
  }

  public putQuestionarioPiano(idPianoSostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.pianoWebService.putQuestionarioPiano(idPianoSostenibilita, idSurvey, ambiti, settori, titolo, stato, tipo);
  }

  /* ------------------------- O B I E T T I V I  -------------------------------- */
  /**
  * GET OBIETTIVI PIANO
  * @param idPianoSostenibilita 
  * @returns obj con lista ambiti - tematiche - obiettivi
  */
  public getObiettiviPiano(idPianoSostenibilita: string) {
    return this.pianoWebService.getObiettiviPiano(idPianoSostenibilita);
  }

  /**
  * SALVATAGGIO OBIETTIVI PIANO SELEZIONATI
  * @param idPianoSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putObiettiviPianoSelezionati(idPianoSostenibilita: string, obiettiviSelezionati: any) {
    return this.pianoWebService.putObiettiviPianoSelezionati(idPianoSostenibilita, obiettiviSelezionati);
  }

  public putObiettiviEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, impegno: any) {
    return this.pianoWebService.putObiettiviEffimeri(idPianoSostenibilita, idAmbito, idTematica, impegno);
  }

  /* ------------------------- T A R G E T  -------------------------------- */
  /* Lista obiettivi a cui associare i target nel Piano di Sostenibilità */
  public getObiettiviTarget(
    idPianoSostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getObiettiviTarget(idPianoSostenibilita, page, pageSize, ricerca, filters, sortBy);
  }

  public getTargetQualitativiQuantitativi(listaId: any) {
    return this.pianoWebService.getTargetQualitativiQuantitativi(listaId);
  }

  /**
 * SALVATAGGIO target SELEZIONATI
 * @param idPianoSostenibilita 
 * @param objStep3 
 * @returns 
 */
  public putTargetSelezionati(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQualitativiSelezionati: any) {
    return this.pianoWebService.putTargetSelezionati(idPianoSostenibilita, idAmbito, idTematica, idObiettivo, targetQualitativiSelezionati);
  }


  /* ------------------------- TARGET QUALITATIVI  -------------------------------- */

  /**
  * ASSEGNA FIGURA AD target
  * @param idPianoSostenibilita 
  * @param piano
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraTargetQualitativiPiano(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idTarget: string, idObiettivo: string, idFigura: string) {
    return this.pianoWebService.putAssegnaFiguraTargetQualitativiPiano(idPianoSostenibilita, idAmbito, idTematica, idTarget, idObiettivo, idFigura);
  }

  public putTargetQualitativiEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQualitativi: any) {
    return this.pianoWebService.putTargetQualitativiEffimeri(idPianoSostenibilita, idAmbito, idTematica, idObiettivo, targetQualitativi);
  }
  /* ------------------------- TARGET QUANTITATIVI  -------------------------------- */

  /**
  * ASSEGNA FIGURA AD target
  * @param idPianoSostenibilita 
  * @param piano
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraTargetQuantitativiPiano(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idTarget: string, idObiettivo: string, idFigura: string) {
    return this.pianoWebService.putAssegnaFiguraTargetQuantitativiPiano(idPianoSostenibilita, idAmbito, idTematica, idTarget, idObiettivo, idFigura);
  }

  public putTargetQuantitativiEffimeri(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idObiettivo: string, targetQuantitativi: any) {
    return this.pianoWebService.putTargetQuantitativiEffimeri(idPianoSostenibilita, idAmbito, idTematica, idObiettivo, targetQuantitativi);
  }


  /* --------------------------- FIGURE AZIENDALI --- ---------------*/

  public getListaAssegnazioniFigAz(idPiano: string) {
    return this.pianoWebService.getListaAssegnazioniFigAz(idPiano);
  }

  /* ------------------------- C R U S C O T T O  -------------------------------- */
  public getTotaliCruscotto(idPianoSostenibilita: string) {
    return this.pianoWebService.getTotaliCruscotto(idPianoSostenibilita);
  }


  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */

  public getCruscotti(
    idPianoSostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getCruscotti(idPianoSostenibilita, page, pageSize, ricerca, filters, sortBy);
  }

  /**
 * Cambio figura
 * @param idPianoSostenibilita 
 * @param idFiguraPrecedente 
 * @param idFiguraNuova 
 * @returns 
 */
  putCambiaFigura(idPianoSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.pianoWebService.putCambiaFigura(idPianoSostenibilita, idFiguraPrecedente, idFiguraNuova);
  }

  public inviaMailMultiple(idPianoSostenibilita: string) {
    return this.pianoWebService.inviaMailMultiple(idPianoSostenibilita);
  }

  public inviaMail(idPianoSostenibilita: string, idFigura: string) {
    return this.pianoWebService.inviaMail(idPianoSostenibilita, idFigura);
  }

  public inviaNotificaMultiple(idPianoSostenibilita: string) {
    return this.pianoWebService.inviaNotificaMultiple(idPianoSostenibilita);
  }

  public inviaNotifica(idPianoSostenibilita: string, idFigura: string) {
    return this.pianoWebService.inviaNotifica(idPianoSostenibilita, idFigura);
  }
  public putTemplateMail(idPianoSostenibilita: string, templateMail: any) {
    return this.pianoWebService.putTemplateMail(idPianoSostenibilita, templateMail);
  }


  public getTemplateMail(idPianoSostenibilita: string) {
    return this.pianoWebService.getTemplateMail(idPianoSostenibilita);
  }

  public pubblicaPiano(idPianoSostenibilita: string) {
    return this.pianoWebService.pubblicaPiano(idPianoSostenibilita);
  }

  public getElementoDaCompilare(idPianoSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string, idObiettivo: string) {
    return this.pianoWebService.getElementoDaCompilare(idPianoSostenibilita, idAmbito, idTematica, idElemento, idObiettivo);
  }

  public postElementoDaCompilare(
    idPianoSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    rispostaBreve: string | number,
    anno: string,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string,
    idObiettivo?: string,
  ) {
    return this.pianoWebService.postElementoDaCompilare(
      idPianoSostenibilita,
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      rispostaBreve,
      anno,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento,
      idObiettivo);
  }

  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.pianoWebService.getElementiEsterniDaCompilare(temporaryToken);
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.pianoWebService.postElementiEsterniDaCompilare(temporaryToken, objEsterna);
  }

  public invioTestMail(messaggio: string, email: string, idPianoSostenibilita: string) {
    return this.pianoWebService.invioTestMail(messaggio, email, idPianoSostenibilita);
  }

  public getAnteprimaPianoCompilato(idPiano: string, idAzienda: string) {
    return this.pianoWebService.getAnteprimaPianoCompilato(idPiano, idAzienda);
  }

  /*  Obiettivi Aziendali cfg */
  public getObiettiviAziendali(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getObiettiviAziendali(page, pageSize, ricerca, filters, sortBy);
  }

  public postObiettiviAziendaliPiano(data: any) {
    return this.pianoWebService.postObiettiviAziendaliPiano(data);
  }


  public deleteObiettiviAziendaliPiano(idRiga: any) {
    return this.pianoWebService.deleteObiettiviAziendaliPiano(idRiga);
  }

  public putObiettiviAziendaliPiano(id: string, obiettivoPiano: any) {
    return this.pianoWebService.putObiettiviAziendaliPiano(id, obiettivoPiano);
  }
  /*  modelli sviluppo piano cfg */
  public getModelliSviluppoPiano(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.pianoWebService.getModelliSviluppoPiano(page, pageSize, ricerca, filters, sortBy);
  }

  public postModelliSviluppoPiano(data: any) {
    return this.pianoWebService.postModelliSviluppoPiano(data);
  }


  public deleteModelliSviluppoPiano(idRiga: any) {
    return this.pianoWebService.deleteModelliSviluppoPiano(idRiga);
  }

  public putModelliSviluppoPiano(id: string, obiettivoPiano: any) {
    return this.pianoWebService.putModelliSviluppoPiano(id, obiettivoPiano);
  }

  /* DOCUMENTO   */

  public getDocumento(idPianoSostenibilita: string) {
    return this.pianoWebService.getDocumento(idPianoSostenibilita);
  }

  public putDocumento(idPianoSostenibilita: string, objDocumento: any) {
    return this.pianoWebService.putDocumento(idPianoSostenibilita, objDocumento);
  }


  public putObiettiviAziendaliEffimeri(idPianoSostenibilita: string, obiettivoAziendale: any) {
    return this.pianoWebService.putObiettiviAziendaliEffimeri(idPianoSostenibilita, obiettivoAziendale);
  }

  public putModelloSviluppoEffimeri(idPianoSostenibilita: string, modelloSviluppo: any) {
    return this.pianoWebService.putModelloSviluppoEffimeri(idPianoSostenibilita, modelloSviluppo);
  }

  /* ------------------------- S T A M P A -------------------------------- */

  /**
  * GET Configurazioni stampa 
  * @param idPiano 
  * @returns List<GruppoConfigRisultatiSintesiValori>
  */
  public getConfigStampa(idPiano: string) {
    return this.pianoWebService.getConfigStampa(idPiano);
  }
  public postSalvaElementiStampa(idPiano: string, cfgStampaPiano: GruppoCfgStampa) {
    return this.pianoWebService.postSalvaElementiStampa(idPiano, cfgStampaPiano);
  }
  public postStampaPiano(idPiano: string) {
    return this.pianoWebService.postStampaPiano(idPiano);
  }

/* ------------------------------------------------------ */
   /**
 *  ritorna piano pubblicate
 * @returns 
 */
   public getPianiPubblicate(idSettore?: string, idSottoSettori?: string[]) {
    return this.pianoWebService.getPianiPubblicate(idSettore, idSottoSettori);
  }

  /**
 * ritorna piano corrispondete all'id
 * @param idPiano 
 * @returns 
 */
  public getPianoById(idPiano: string) {
    return this.pianoWebService.getPianoById(idPiano);
  }
}
