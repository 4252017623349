import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogAssociaFiguraAziendaleComponent } from 'src/app/components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { Subject, debounceTime } from 'rxjs';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { UtenteService } from 'src/app/services/utente/utente.service';

interface Tipo {
  chiave: string,
  descrizione: string
}

interface Figura {
  id: string; // id della figura
  nome: string;
  email: string;
  figura: string;
  idAzienda: string;
}

interface ElementoDaCompilareConFigura {
  idElemento: string;  // id dell'elemento
  nomeBreve: string; // nomeBreve/titolo dell'elemento
  descrizione: string,
  tipo: Tipo,
  figura: Figura;
}
interface AssFigAz {
  idTematica: string;
  nomeBreve: string;
  elementi?: ElementoDaCompilareConFigura[];
  domanda: string
}
@Component({
  selector: 'app-dialog-assegnazioni',
  templateUrl: './dialog-assegnazioni.component.html',
  styleUrls: ['./dialog-assegnazioni.component.scss']
})
export class DialogAssegnazioniComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public datiAssociati: AssFigAz[] = [];

  displayedColumns: string[] = ["tipo", "nomeBreve", "figura", "nome", 'azioni'];
  public searchValue: string = '';
  public filteredDatiAssociati: AssFigAz[] = []
  public modificato: boolean = false;
  private searchValueSubject = new Subject<string>();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogAssegnazioniComponent>,
    private readonly sintesiService: SintesiService,
    private readonly bilancioService: BilancioService,
    private readonly pianoService: PianoService,
    private readonly policyService: PolicyService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idContesto: string,
      stato: string,
      contesto: string,
      isPermessoSoloRead: boolean
    }
  ) {

  }

  ngAfterViewInit(): void {

    this._datiIniziali();

    this.searchValueSubject.pipe(
      debounceTime(500)
    ).subscribe(searchValue => {
      this.applicaFiltroRicerca(searchValue);
    });
  }
  public onSearchInputChange(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.spinnerOver.show();
    this.searchValueSubject.next(filterValue);
  }



  public applicaFiltroRicerca(searchValue: string) {

    this.searchValue = searchValue;

    // Copia dell'array datiAssociati
    const datiAssociatiCopy = [...this.datiAssociati];

    this.filteredDatiAssociati = datiAssociatiCopy.map(item => {
      let elementi = item.elementi?.filter((fig) =>
      (fig.tipo.descrizione.toLowerCase().includes(this.searchValue?.toLowerCase() || "")
        || fig.nomeBreve.toLowerCase().includes(this.searchValue?.toLowerCase() || "")
        || fig.figura.nome.toLowerCase().includes(this.searchValue?.toLowerCase() || "")
        || fig.figura.figura.toLowerCase().includes(this.searchValue?.toLowerCase() || ""))
      );

      return { ...item, elementi: elementi }

    }).filter((prov) => prov.elementi?.length);
    this.spinnerOver.hide();
  }

  private _datiIniziali() {
    if (this.data.idContesto) {
      let obsAssegnazioni: any;

      switch (this.data.contesto) {
        case 'sintesi':
          obsAssegnazioni = this.sintesiService.getListaAssegnazioniFigAz(this.data.idContesto);
          break;
        case 'bilancio':
          obsAssegnazioni = this.bilancioService.getListaAssegnazioniFigAz(this.data.idContesto)
          break;
        case 'policy':
          obsAssegnazioni = this.policyService.getListaAssegnazioniFigAz(this.data.idContesto)
          break;
        case 'piano':
          obsAssegnazioni = this.pianoService.getListaAssegnazioniFigAz(this.data.idContesto)
          break;
        default:
          return;
      }

      if (obsAssegnazioni) {
        obsAssegnazioni.subscribe({
          next: (esito: any) => {
            this.datiAssociati = esito;
            this.filteredDatiAssociati = this.datiAssociati;
          },
          error: (err: any) => {
            console.error(err);
          },
        })

      }
    }
  }

  chiudi() {
    this.dialogRef.close(this.modificato)
  }

  modifica(idTematica: string, elem: any) {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: elem?.figura
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogAssociaFiguraAziendale.afterClosed().subscribe((result) => {
      // ritorna id della figura aziendale 
      if (result) {

        this.modificato = true;

        const tipoChiave = elem?.tipo?.chiave.toLowerCase();
        this.spinnerOver.show();
        switch (tipoChiave) {
          case 'impatto':
            this.assegnaFiguraImpatto(elem.idAmbito, idTematica, elem.idElemento, result);
            break;
          case 'impegno':
            this.assegnaFiguraImpegno(elem.idAmbito, idTematica, elem.idElemento, result);
            break;
          case 'kpi':
            this.assegnaFiguraKpi(elem.idAmbito, idTematica, elem.idElemento, elem.idObiettivo, result);
            break;
          case 'azioniebuonepratiche':
            this.assegnaFiguraAzioniBP(elem.idAmbito, idTematica, elem.idElemento, elem.idObiettivo, result);
            break;
          case 'certificazione':
            this.assegnaFiguraCertificazione(elem.idAmbito, idTematica, elem.idElemento, result);
            break;
          default:
            break;
        }

      }
    });

  }
  /**
   * Assegnazione figura aziendale per Impatto
   * @param idAmbito 
   * @param idTematica 
   * @param idElemento elementi a cui viene associata la figura aziendale 
   * @param idFigAziendale id della figura aziendale da associare 
   */
  private assegnaFiguraImpatto(idAmbito: string, idTematica: string, idElemento: string, idFigAziendale: string) {
    if (this.data.contesto === 'sintesi') {

      //NON CI SONO IMPATTI SULLA SINTESI

    } else if (this.data.contesto === 'bilancio') {
      this.bilancioService.putAssegnaFiguraImpattoBilancio(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    }
  }

  /**
 * Assegnazione figura aziendale per KPI
 * @param idAmbito 
 * @param idTematica 
 * @param idElemento elementi a cui viene associata la figura aziendale 
 * @param idFigAziendale id della figura aziendale da associare 
 */
  private assegnaFiguraKpi(idAmbito: string, idTematica: string, idElemento: string, idObiettivo: string, idFigAziendale: string) {

    let obsPutAssegnazioniKpi: any;

    switch (this.data.contesto) {
      case 'sintesi':
        obsPutAssegnazioniKpi = this.sintesiService.putAssegnaFiguraKpiSintesi(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale);
        break;
      case 'bilancio':
        obsPutAssegnazioniKpi = this.bilancioService.putAssegnaFiguraKpiBilancio(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale);
        break;
      case 'piano':
        obsPutAssegnazioniKpi = this.pianoService.putAssegnaFiguraTargetQuantitativiPiano(this.data.idContesto, idAmbito, idTematica, idElemento, idObiettivo, idFigAziendale);
        break;
      default:
        return;
    }

    if (obsPutAssegnazioniKpi) {
      obsPutAssegnazioniKpi.subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err: any) => {
          console.error(err)
        }
      })
    }
  }

  /**
 * Assegnazione figura aziendale per Impegno
 * @param idAmbito 
 * @param idTematica 
 * @param idElemento elementi a cui viene associata la figura aziendale 
 * @param idFigAziendale id della figura aziendale da associare 
 */
  private assegnaFiguraImpegno(idAmbito: string, idTematica: string, idElemento: string, idFigAziendale: string) {
    if (this.data.contesto === 'sintesi') {

      this.sintesiService.putAssegnaFiguraImpegnoSintesi(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    } else if (this.data.contesto === 'bilancio') {
      this.bilancioService.putAssegnaFiguraImpegnoBilancio(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    } else if (this.data.contesto === 'policy') {
      this.policyService.putAssegnaFiguraImpegnoPolicy(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    }
  }

  /**
   * Assegnazione figura aziendale per Azione e buona pratica 
   * @param idAmbito 
   * @param idTematica 
   * @param idElemento elementi a cui viene associata la figura aziendale 
   * @param idFigAziendale id della figura aziendale da associare 
   */
  private assegnaFiguraAzioniBP(idAmbito: string, idTematica: string, idElemento: string, idObiettivo: string, idFigAziendale: string) {

    let obsPutAssegnazioniAzioniBP: any;

    switch (this.data.contesto) {
      case 'sintesi':
        obsPutAssegnazioniAzioniBP = this.sintesiService.putAssegnaFiguraAzioniBPSintesi(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale)
        break;
      case 'bilancio':
        obsPutAssegnazioniAzioniBP = this.bilancioService.putAssegnaFiguraAzioniBPBilancio(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale)
        break;
      case 'policy':
        obsPutAssegnazioniAzioniBP = this.policyService.putAssegnaFiguraAzioniBPPolicy(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale)
        break;
      case 'piano':
        obsPutAssegnazioniAzioniBP = this.pianoService.putAssegnaFiguraTargetQualitativiPiano(this.data.idContesto, idAmbito, idTematica, idElemento, idObiettivo, idFigAziendale);
        break;
      default:
        return;
    }

    if (obsPutAssegnazioniAzioniBP) {
      obsPutAssegnazioniAzioniBP.subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err: any) => {
          console.error(err)
        }
      })
    }
  }

  /**
   * Assegnazione figura aziendale per certificazione
   * @param idAmbito 
   * @param idTematica 
   * @param idElemento elementi a cui viene associata la figura aziendale 
   * @param idFigAziendale id della figura aziendale da associare 
   */
  private assegnaFiguraCertificazione(idAmbito: string, idTematica: string, idElemento: string, idFigAziendale: string) {
    if (this.data.contesto === 'sintesi') {

      this.sintesiService.putAssegnaFiguraCertificazioniSintesi(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {
        }
      })

    } else if (this.data.contesto === 'bilancio') {
      this.bilancioService.putAssegnaFiguraCertificazioniBilancio(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    } else if (this.data.contesto === 'policy') {
      this.policyService.putAssegnaFiguraCertificazioniPolicy(this.data.idContesto, idAmbito, idTematica, idElemento, idFigAziendale).subscribe({
        next: () => {
          this._datiIniziali();
          this.searchValueSubject.next(this.searchValue)
        },
        error: (err) => {

        }
      })
    }
  }


}

