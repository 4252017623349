<ng-container *ngIf="isFiltri">
    <div class="cont-comp-tabella">

        <div class="cont-filtri">

            <mat-form-field style="width: 100%;" class="ricerca-input">
                <input matInput placeholder="Inserisci chiave di ricerca" [(ngModel)]="searchValue"
                    (keyup)="applicaFiltroRicerca($event)">
                <mat-icon matSuffix style="color: var(--mat-color-primary);">search</mat-icon>
            </mat-form-field>

        </div>

        <div class="cont-bottoni">

            <ng-content select="[btnStart]"></ng-content>

            <div *ngIf="filtri?.length">
                <button mat-flat-button class="accent-button" color="accent" (click)="apriFiltro()">
                    <mat-icon matSuffix>filter_list</mat-icon>
                    Filtri
                </button>
            </div>
            <ng-content select="[btnEnd]"></ng-content>

        </div>

    </div>
</ng-container>
<div class="card-tabella mat-elevation-z2">

    <div class="cont-titolo-tabella" *ngIf="titoloTabella !== null && titoloTabella.trim() !== ''">
        <mat-label class="titolo-tabella">{{titoloTabella}}</mat-label>
    </div>

    <div class="cont-tabella">


        <table mat-table [dataSource]="dataSource" matSort matSortActive="created" matSortDirection="desc">

            <ng-container *ngFor="let nomeColonna of nomiColonne; let idx = index" [matColumnDef]="nomeColonna">

                <th [attr.colspan]="idx < nomiColonne.length - nrColAzioni ? 1 : nrColAzioni"
                    [ngStyle]="{display: idx > nomiColonne.length - nrColAzioni ? 'none' : ''}" mat-header-cell
                    *matHeaderCellDef [disabled]="!colonne[nomeColonna].sortable" mat-sort-header
                    class="titolo-colonna">
                    {{ idx < nomiColonne.length - nrColAzioni ? colonne[nomeColonna].title : 'Azioni' }}</th>

                <td [attr.colspan]=" dataSource.isListaVuota ? nomiColonne.length : 1" class="dati-righe" mat-cell
                    *matCellDef="let record" [ngStyle]="{
                    'width' : colonne[nomeColonna].width,
                    'display':( dataSource.isListaVuota && idx > 0) ? 'none' : 'table-cell',                   
                    'text-align': ( dataSource.isListaVuota) ?  'center':  colonne[nomeColonna].type === 'button' || colonne[nomeColonna].type === 'hamburger' ? 'center' :  colonne[nomeColonna].align,
                    'font-size': ( dataSource.isListaVuota) ?  '15px': '',
                    'color': ( dataSource.isListaVuota) ?  '#006080': '',
                    'font-weight': ( dataSource.isListaVuota) ?  'bold': '',
                    'padding': ( dataSource.isListaVuota) ?  '20px 0px' : colonne[nomeColonna].type === 'button' || colonne[nomeColonna].type === 'hamburger' ? '5px' :  '',
                    
                    
                }">

                    <div *ngIf="!dataSource.isListaVuota" [innerHTML]="record['_col_val_' + nomeColonna]"></div>
                    <div *ngIf="dataSource.isListaVuota" [innerHTML]="record['error']"></div>

                    <!-- BUTTON -->
                    <ng-container *ngIf="!record['_col_hid_' + nomeColonna]">
                        <div *ngIf="colonne[nomeColonna].type === 'button'">
                            <button class="icone-button" mat-icon-button
                                (click)="lanciaButtonPressed(colonne[nomeColonna], record, idx)"
                                [title]="titoloIcona(colonne[nomeColonna], record)"
                                attr.aria-label="{{colonne[nomeColonna].title}}">
                                <mat-icon class="icone">
                                    {{iconaBottone(colonne[nomeColonna], record)}}
                                </mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <!-- HAMBURGER -->
                    <div
                        *ngIf="colonne[nomeColonna].type === 'hamburger' && nomiColonneHamburger?.length && !record._col_burg_hid">

                        <button class="icone-button" mat-icon-button [title]="titoloIcona(colonne[nomeColonna], record)"
                            attr.aria-label="{{colonne[nomeColonna].title}}" [matMenuTriggerFor]="menuHamburger">
                            <mat-icon class="icone">
                                {{iconaBottone(colonne[nomeColonna], record)}}
                            </mat-icon>
                        </button>

                        <mat-menu #menuHamburger="matMenu" [xPosition]="'before'" backdropClass="customize">
                            <ng-container *ngFor="let nomeColHam of nomiColonneHamburger">
                                <button mat-menu-item
                                    (click)="lanciaButtonPressed(buttonHamburger[nomeColHam], record, idx)"
                                    *ngIf="!record['_col_hid_' + nomeColHam]"
                                    [title]="titoloIcona(buttonHamburger[nomeColHam], record)"
                                    attr.aria-label="{{buttonHamburger[nomeColHam].title}}">

                                    <mat-icon class="icone">
                                        <!--  {{buttonHamburger[nomeColHam].buttonIcon}} -->
                                        {{iconaBottone(buttonHamburger[nomeColHam], record)}}
                                    </mat-icon>

                                    <mat-label class="label-mat-menu">
                                        {{titoloIcona(buttonHamburger[nomeColHam], record)}}</mat-label>
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="nomiColonne" class="riga-colonna"></tr>
            <tr mat-row *matRowDef="let row; columns: nomiColonne;" class="riga-riga" [ngStyle]="row._style_riga || {}">
            </tr>
        </table>
    </div>

    <!-- SPINNER TABELLA -->
    <div class="overlay" *ngIf="dataSource.loading$ | async">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <mat-paginator #paginator [pageSize]="defaultPageSize" showFirstLastButtons [pageSizeOptions]="[10, 20, 50, 100]"
        aria-label="Seleziona pagina"></mat-paginator>
</div>