<app-spinner-overlay></app-spinner-overlay>
<div class="landing-page" *ngIf="landingPage" [innerHtml]="landingPage"></div>
<div style="align-items: center;display: flex;justify-content: space-between;width: 100%;height: 50px;">
    <div>
        <div style="font-size: 30px;color: var(--mat-color-accent);font-family: Abel;">{{titolo}}
            <span *ngIf="esercizio">{{esercizio}}</span>
        </div>
    </div>
    <div style="height: 1px;background-color: #707070;flex:1;margin-right: 20px;margin-left: 20px;">
    </div>

    <div style="font-size: 30px;color: var(--mat-color-accent);font-family: Abel;" *ngIf="esercizio">
        {{(currentSlideIndex + 1)}}/{{totTematiche}}
    </div>
</div>

<div mat-dialog-content class="dialog-center-compilazione">
    <form [formGroup]="formCompilazione">
        <div formArrayName="ambiti">
            <div class="ambito" *ngFor="let amb of $any(ambitiFormArray)?.controls; let idxAmb = index"
                [formGroupName]="idxAmb">
                <div class="titolo-sezione-dialog" style="padding-bottom: 5px;">
                    {{amb?.value?.titolo}} - {{amb?.value?.descrizione}}
                </div>
                <div formArrayName="tematiche">
                    <div *ngFor="let tema of amb?.controls?.tematiche?.controls; let idxTem = index"
                        [formGroupName]="idxTem">
                        <!-- {{consolelog(tema)}} -->
                        <div class="tematiche">

                            <div *ngIf="tema?.controls?.domanda?.value" class="domanda">
                                {{tema?.controls?.domanda?.value}}
                            </div>

                            <ng-container *ngIf="tema?.controls?.tipo?.value === 'scelta_singola'">

                                <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                                    [value]="tema?.controls?.risposte?.value?.length ? tema?.value?.risposte![0] : null"
                                    (change)="onRadioChange($event,idxAmb,idxTem)" #radioGroup="matRadioGroup">

                                    <ng-container
                                        *ngFor="let opz of tema?.controls?.opzioni?.value; let idxOpz = index">

                                        <mat-radio-button [value]="opz.chiave">
                                            {{opz.valore}}
                                        </mat-radio-button>

                                        <div class="sotto-opzioni"
                                            *ngIf="opz.sottoOpzioni?.length && radioGroup.value === opz.chiave">

                                            <div *ngFor="let opzSec of opz.sottoOpzioni" class="radio-group">

                                                <mat-checkbox [value]="opzSec.chiave"
                                                    [checked]="checkSotto(opz.sottoRisposte , opzSec.chiave)"
                                                    (change)="cambioCheck($event,idxAmb,idxTem,idxOpz,opzSec.chiave)">
                                                    {{opzSec.valore}}
                                                </mat-checkbox>

                                            </div>

                                        </div>

                                    </ng-container>

                                </mat-radio-group>

                            </ng-container>

                            <div *ngIf="tema?.controls?.tipo?.value === 'scelta_multipla_valore'" appFormContainer
                                [larghezza]="3" [rowGap]="10">

                                <!------------------------------------------- N U M E R I C A ------------------------------>

                                <!---------------------------------------------- I M P A T T O --------------------------------->
                                <ng-container *ngIf="tema?.controls?.impatti?.controls?.length">
                                    <ng-container *ngFor="let impatto of tema?.controls?.impatti?.controls">

                                        <mat-label class="descrizione" appFormElement [larghezza]="3"
                                            [riempi]="true">{{impatto?.controls?.descrizione?.value}}</mat-label>

                                        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                            <mat-label>Risposta</mat-label>
                                            <input matInput [disabled]="isPermessoSoloRead ? true : false"
                                                [placeholder]="'Risposta'"
                                                [formControl]="impatto?.controls?.valoreAttribuito" appInputNumber
                                                type="number" step="0.01" />
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                                    </ng-container>

                                </ng-container>




                                <!---------------------------------------------- T E S T U A L E ------------------------------->

                                <!---------------------------------------------- I M P E G N O --------------------------------->
                                <ng-container *ngIf="tema?.controls?.impegni?.controls?.length">
                                    <ng-container *ngFor="let impegno of tema?.controls?.impegni?.controls">
                                        <!-- IMPEGNO.CONTROLS.DESC.VALUE -->
                                        <mat-label class="descrizione" appFormElement [larghezza]="3"
                                            [riempi]="true">{{impegno?.controls?.descrizione?.value}}</mat-label>

                                        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                            <mat-label>Risposta</mat-label>
                                            <textarea matInput [disabled]="isPermessoSoloRead ? true : false"
                                                [placeholder]="'Risposta'" rows="4"
                                                [formControl]="impegno?.controls?.risposta"></textarea>
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <div *ngIf="impegno.preCompilazioneInfos" class="info-risposta">
                                            <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                info
                                            </span>
                                            <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                                [riempi]="true">{{getTooltip(impegno.preCompilazioneInfos)}}
                                            </mat-label>
                                        </div>

                                        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                                    </ng-container>

                                </ng-container>

                                <!---------------------------------------------- A Z I O N I ------------------------------->
                                <ng-container
                                    *ngIf="tema?.controls?.azioni?.controls?.length && (this._compilazione.tipo !== 'POLICY' && this._compilazione.tipo !== 'PIANO')">
                                    <ng-container *ngFor="let azione of tema?.controls?.azioni?.controls">

                                        <mat-label class="descrizione" appFormElement [larghezza]="3"
                                            [riempi]="true">{{azione?.controls?.descrizione?.value}}</mat-label>

                                        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                            <mat-label>Risposta</mat-label>
                                            <textarea matInput [disabled]="isPermessoSoloRead ? true : false"
                                                [placeholder]="'Risposta'" rows="4"
                                                [formControl]="azione?.controls?.risposta"></textarea>
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <div *ngIf="azione?.controls?.preCompilazioneInfos?.value"
                                            class="info-risposta">
                                            <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                info
                                            </span>
                                            <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                                [riempi]="true">{{getTooltip(azione?.controls?.preCompilazioneInfos?.value)}}
                                            </mat-label>
                                        </div>

                                        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                                    </ng-container>

                                </ng-container>



                                <!-- VALE SOLO PER LE AZIONI BUONE PRATICHE DENTRO ALL PIANO-->
                                <ng-container
                                    *ngIf="tema?.controls?.azioni?.controls?.length && (this._compilazione.tipo === 'POLICY' || this._compilazione.tipo === 'PIANO')">
                                    <ng-container *ngFor="let azione of tema?.controls?.azioni?.controls">

                                        <mat-label class="descrizione" appFormElement [larghezza]="3"
                                            [riempi]="true">{{azione?.controls?.descrizione?.value}}</mat-label>


                                        <div *ngIf="this._compilazione.tipo === 'PIANO'" style="padding-bottom: 10px;"
                                            class="descrizione-sezione-dialog">
                                            {{azione?.controls?.titoloObiettivo?.value}}
                                        </div>

                                        <ng-container *ngIf="this._compilazione.tipo === 'PIANO'">
                                            <mat-form-field appFormElement [larghezza]="3">
                                                <mat-label>Anno</mat-label>
                                                <input matInput [disabled]="isPermessoSoloRead ? true : false"
                                                    [placeholder]="'Anno'" [formControl]="azione?.controls?.anno"
                                                    type="number" step="1" inputmode="numeric" appInputNumber
                                                    />
                                                <mat-error appFormError></mat-error>
                                            </mat-form-field>

                                        </ng-container>

                                        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                                            <mat-label>Risposta Breve</mat-label>
                                            <textarea matInput [disabled]="isPermessoSoloRead ? true : false"
                                                [placeholder]="'Risposta Breve'" rows="4"
                                                [formControl]="azione?.controls?.rispostaBreve"></textarea>
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <div *ngIf="azione?.controls?.risposta?.value" class="info-risposta"
                                            [matTooltip]="getTooltip( azione?.controls?.preCompilazioneInfos?.value)">
                                            <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                info
                                            </span>
                                            <mat-lanbel class="risposta-read-only" appFormElement [larghezza]="3"
                                                [riempi]="true">
                                                {{azione?.controls?.risposta?.value}}
                                            </mat-lanbel>
                                        </div>

                                        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                                    </ng-container>

                                </ng-container>


                                <!---------------------------------------------- K P I --------------------------------->
                                <ng-container *ngIf="tema?.controls?.kpis?.controls?.length">
                                    <ng-container *ngFor="let kpis of tema?.controls?.kpis?.controls">
                                        <!-- VALORI KPI -->

                                        <ng-container *ngIf="this._compilazione.tipo !== 'PIANO'">
                                            <ng-container
                                                *ngFor="let valoriKpi of kpis?.controls?.valoriKpi?.controls; first as isFirst">

                                                <mat-label *ngIf="isFirst" class="descrizione" appFormElement
                                                    [larghezza]="3"
                                                    [riempi]="true">{{kpis?.controls?.descrizione?.value}}</mat-label>

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Unità di Misura</mat-label>
                                                    <mat-select [disabled]="isPermessoSoloRead ? true : false"
                                                        [placeholder]="'Unità di Misura'"
                                                        [formControl]="valoriKpi?.controls?.idUnitaDiRiferimento">
                                                        <mat-option [disabled]="isPermessoSoloRead ? true : false"
                                                            *ngFor="let um of arrayUm[valoriKpi?.controls?.idCategoriaUnitaDiMisura?.value]"
                                                            [value]="um.id">{{um.descrizione}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <div appFormElement [larghezza]="valueToggleAnni ? 0.5 : 1"
                                                    [riempi]="true"
                                                    style="display: flex; align-items: center; column-gap: 10px;">
                                                    <mat-form-field style="width: 100%;">
                                                        <mat-label>Valore</mat-label>
                                                        <input matInput [disabled]="isPermessoSoloRead ? true : false"
                                                            [placeholder]="'Valore'"
                                                            [formControl]="valoriKpi?.controls?.valoreAttribuito"
                                                            type="number" appInputNumber step="0.01"
                                                            inputmode="numeric" />
                                                        <mat-error appFormError></mat-error>
                                                    </mat-form-field>

                                                    <div *ngIf="valoriKpi?.controls?.preCompilazioneInfos?.value"
                                                        class="info-risposta"
                                                        [matTooltip]="getTooltip(valoriKpi?.controls?.preCompilazioneInfos?.value)"
                                                        style="padding-bottom: 20px;">
                                                        <span
                                                            class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                            info
                                                        </span>
                                                    </div>
                                                </div>

                                                <mat-label appFormElement [larghezza]="valueToggleAnni ? 0.5 : 0"
                                                    [riempi]="true" style="padding-left: 50px;padding-top: 18px;">Anno
                                                    <span
                                                        style="font-weight: bold;">{{valoriKpi?.controls?.anno?.value}}</span></mat-label>

                                                <div class="separa" appFormElement [larghezza]="3" [riempi]="true"
                                                    *ngIf="isFirst">
                                                </div>

                                            </ng-container>
                                        </ng-container>

                                        <!-- TARGET -->

                                        <div style="display: flex; flex-direction: column; width: 100%;">
                                            <div style="display: flex; column-gap: 20px;">
                                                <div class="titolo-sezione-dialog">Compilazione Target</div>
                                                <div *ngIf="targetConsigliato" style="color: #40a36f;">
                                                    *Consigliato
                                                </div>
                                            </div>
                                            <div style="padding-bottom: 10px;" class="descrizione-sezione-dialog">
                                                {{this._compilazione.tipo === 'PIANO'?kpis?.titoloObiettivo : "Per
                                                questo
                                                KPI è " +
                                                "prevista la compilazione di uno o più target." }}</div>
                                            <ng-container
                                                *ngFor="let valoriTarget of kpis?.controls?.valoriTarget?.controls; first as isFirst">
                                                <div style="display: flex; column-gap: 10px; padding-bottom: 5px;">
                                                    <mat-form-field appFormElement [larghezza]="0.5">
                                                        <mat-label>Anno</mat-label>
                                                        <input matInput [disabled]="isPermessoSoloRead ? true : false"
                                                            [placeholder]="'Anno'"
                                                            [formControl]="valoriTarget?.controls?.anno" type="number"
                                                            appInputNumber step="1" inputmode="numeric"
                                                            />
                                                        <mat-error style="white-space: nowrap;" appFormError></mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field>
                                                        <mat-label>Tipo Azione</mat-label>
                                                        <mat-select [disabled]="isPermessoSoloRead ? true : false"
                                                            [placeholder]="'Tipo Azione'"
                                                            [formControl]="valoriTarget?.controls?.tipoAzione">
                                                            <mat-option [disabled]="isPermessoSoloRead ? true : false"
                                                                *ngFor="let option of tipoAzione"
                                                                [value]="option.chiave">{{option.descrizione}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <mat-form-field appFormElement [larghezza]="1">
                                                        <mat-label>Unità di Misura</mat-label>
                                                        <mat-select [disabled]="isPermessoSoloRead ? true : false"
                                                            [placeholder]="'Unità di Misura'"
                                                            [formControl]="valoriTarget?.controls?.idUnitaDiRiferimento">
                                                            <mat-option [disabled]="isPermessoSoloRead ? true : false"
                                                                *ngFor="let um of arrayUm[valoriTarget?.controls?.idCategoriaUnitaDiMisura?.value]"
                                                                [value]="um.id">{{um.descrizione}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <div style="display: flex; column-gap: 59px; flex: 1;">
                                                        <div appFormElement [larghezza]="1.5" [riempi]="true"
                                                            style="display: flex; align-items: center; column-gap: 10px;">
                                                            <mat-form-field style="width: 100%;">
                                                                <mat-label>Risposta</mat-label>
                                                                <input [disabled]="isPermessoSoloRead ? true : false"
                                                                    matInput [placeholder]="'Risposta'"
                                                                    [formControl]="valoriTarget?.controls?.valoreAttribuito"
                                                                    type="number" appInputNumber step="0.01"
                                                                    inputmode="numeric" />
                                                                <mat-error appFormError></mat-error>
                                                            </mat-form-field>

                                                            <div *ngIf="valoriTarget?.controls?.preCompilazioneInfos?.value"
                                                                class="info-risposta"
                                                                [matTooltip]="getTooltip(valoriTarget?.controls?.preCompilazioneInfos?.value)"
                                                                style="padding-bottom: 20px;">
                                                                <span
                                                                    class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                                    info
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="this._compilazione.tipo !== 'PIANO'">
                                                <div appFormElement [larghezza]="1" [riempi]="true"
                                                    class="btn-salvataggio-cat" style="padding-bottom: 10px;">
                                                    <button mat-stroked-button
                                                        [disabled]="isPermessoSoloRead ? true : false"
                                                        (click)="visualizzaCampiTarget(kpis)"
                                                        style="height: 40px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                                                        + Aggiungi Nuovo
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>




                                <!---------------------------------------------- T E S T U A L E ------------------------------->
                                <!---------------------------------------------- Certificazione ------------------------------->
                                <ng-container *ngIf="tema?.controls?.certificazioni?.controls?.length">
                                    <ng-container *ngFor="let certif of tema?.controls?.certificazioni?.controls">
                                        <mat-label class="descrizione" appFormElement [larghezza]="3"
                                            [riempi]="true">{{certif?.controls?.descrizione?.value}}</mat-label>
                                        <div style="display: flex; flex: 1; column-gap: 20px;" appFormElement
                                            [larghezza]="3" [riempi]="true">
                                            <div style="flex: 1;">
                                                <mat-form-field style="width: 100%" appFormElement [larghezza]="3"
                                                    [riempi]="true">
                                                    <mat-label>Dettaglio certificazione</mat-label>
                                                    <textarea [disabled]="isPermessoSoloRead ? true : false" rows="7"
                                                        matInput [placeholder]="'Dettaglio certificazione'"
                                                        [formControl]="certif?.controls?.risposta"></textarea>
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <div style="  border: 1px solid #ececec; padding: 5px;">
                                                    <div class="image-container">
                                                        <div class="immagine-cont" [ngStyle]="{'background-image': 'url(' + getUrlImg(
                                                    amb?.value?.id,
                                                    tema?.controls?.id?.value,
                                                    certif?.controls?.id?.value,
                                                    certif?.controls?.urlFile?.value)+')'}">
                                                            <div class="overlay">
                                                                <div class="text">Importa una nuova immagine</div>
                                                            </div>
                                                        </div>
                                                        <input [disabled]="isPermessoSoloRead ? true : false"
                                                            id="fileInput" type="file" #fileInputImg
                                                            (change)="fileSelezionato($any($event.target)?.files,  amb?.value?.id, tema?.controls?.id?.value ,certif?.controls?.id?.value, fileInputImg)"
                                                            class="select-profile-picture" />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="isFilePresente(  amb?.value?.id, tema?.controls?.id?.value ,certif?.controls?.id?.value, certif?.controls?.urlFile?.value)">
                                                    <button [disabled]="isPermessoSoloRead ? true : false"
                                                        class="bottone-elimina" mat-flat-button color="warn"
                                                        (click)="eliminaImg( amb?.value?.id, tema?.controls?.id?.value ,certif?.controls?.id?.value)">Rimuovi</button>
                                                </div>


                                                <div
                                                    *ngIf="!isFilePresente(  amb?.value?.id, tema?.controls?.id?.value , certif?.controls?.id?.value, certif?.controls?.urlFile?.value)">
                                                    <div class="fileinput">

                                                        <button [disabled]="isPermessoSoloRead ? true : false"
                                                            class="bottone-elimina" mat-flat-button color="accent">
                                                            Importa
                                                            <mat-icon>
                                                                file_upload
                                                            </mat-icon>
                                                        </button>
                                                        <input [disabled]="isPermessoSoloRead ? true : false" #fileInput
                                                            type="file" name="fileDaCaricare"
                                                            (change)="fileSelezionato($any($event.target)?.files,  amb?.value?.id, tema?.controls?.id?.value ,certif?.controls?.id?.value, fileInput)"
                                                            (click)="$any($event.target).value=null" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="certif?.controls?.preCompilazioneInfos?.value"
                                            ngClass="info-risposta">
                                            <span class="material-icons-outlined icone-tem icone-label-chack info-icon">
                                                info
                                            </span>
                                            <mat-label class="risposta-read-only" appFormElement [larghezza]="3"
                                                [riempi]="true">{{getTooltip(certif?.controls?.preCompilazioneInfos?.value)}}
                                            </mat-label>
                                        </div>
                                        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
                                    </ng-container>

                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button (click)="chiudi()" color="accent" class="accent-button">Chiudi</button>
    </div>
    <div *ngIf="!isAnteprima && !isPermessoSoloRead">
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Conferma
        </button>
    </div>
</div>