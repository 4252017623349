<h2 mat-dialog-title>Categorie Stakeholder</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formCateStakeholder" appFormContainer [larghezza]="2">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Titolo </mat-label>
            <input matInput placeholder="Titolo" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <!--         <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <textarea matInput placeholder="Descrizione" formControlName="descrizione"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field> -->

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Tipologia</mat-label>
            <mat-select formControlName="tipologia" placeholder="Tipologia">
                <mat-option *ngFor="let tipo of arrayTipologia" [value]="tipo.id">{{tipo.descrizione}}</mat-option>
            </mat-select>
        </mat-form-field>


    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
