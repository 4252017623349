import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class LicenzeWebService {
  private lang = '/it-IT';
  private nomeWs = 'licenze';
  constructor(
    private webService: WebService
  ) { }

  public getLicenzeByUtente(idUtente: string, codiceLicenza: string) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente, {});
  }

  /* public putLicenzeByUtente(idUtente: string, codiceLicenza: string, licenza: any) {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/aggiorna-licenza", licenza);

  } */

  public putLicenzaBase(idUtente: string, codiceLicenza: string, codicePacchetto: string, licenza: any) {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/licenza-base/codice-pacchetto/" + codicePacchetto, licenza);
  }

  public deleteLicenzaBase(idUtente: string, codiceLicenza: string, codicePacchetto: string) {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/licenza-base/codice-pacchetto/" + codicePacchetto, {});
  }

  public postAbilitaLicenza(idUtente: string, licenza: any) {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/utente/' + idUtente + "/abilita-licenza", licenza);
  }

  public postLicenzeList() {
    return this.webService.doPost('/api/v1/' + this.nomeWs + "/list", {});

  }

  public postLicenzeCreate() {
    return this.webService.doPost('/api/v1/' + this.nomeWs + "/create", {});
  }
  public deleteLicenze(idUtente: string) {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/utente/' + idUtente + "/disabilita-licenza", {});
  }
  public getPromotoriList() {
    return this.webService.doPost('/api/v1/promotori/list', {});
  }
  public postPromotori() {
    return this.webService.doPost('/api/v1/promotori/create', {});
  }

  public getUserHasLicense(nomeModulo: string) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/user-has-license/' + nomeModulo, {});

  }

  public getPacchettiBase(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[], codiceLicenza?: string,
    idUtente?: string): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + '/pacchetti-utenza', { page, pageSize, ricerca, filters, sortBy });
  }

  public getPacchettiAggiuntivi(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[], codiceLicenza?: string,
    idUtente?: string): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + '/pacchetti-aggiuntivi', { page, pageSize, ricerca, filters, sortBy });
  }

  public rinnovoLicenzaBase(codiceLicenza: string, idUtente: string, licenza: any) {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/rinnovo-utente", licenza);
  }

  public aggiungiPacchettoAggiuntivo(codiceLicenza: string, idUtente: string, licenza: any) {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + codiceLicenza + '/utente/' + idUtente + "/aggiungi-pacchetti", licenza);
  }

}
