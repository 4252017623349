import { Injectable } from '@angular/core';
import { AmbitiWebService } from './ambiti-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Tematica, TematicaSurvey } from '../tematiche/tematiche.service';


export interface AmbitoBase {
  id: string;
  titolo: string;
  titoloBreve: string;
  chiave: string;
  nomeBreve: string;
  descrizione: string;
  note: string;
  punteggio?: number;
  punteggioPercentuale?: number;
  locked?: boolean;
  infos?: string[];
  index?: number; // ordinamento
}

export interface Ambito extends AmbitoBase {
  tematiche: Tematica[];
}
export interface AmbitoSurvey extends AmbitoBase {
  tematiche: TematicaSurvey[];
}

@Injectable({
  providedIn: 'root'
})
export class AmbitiService {

  constructor(private ambitiWebService: AmbitiWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getAmbiti(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.ambitiWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del Ambito
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAmbiti per la creazione Ambito
     */
  public postAmbiti(data: any) {
    return this.ambitiWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteAmbito(idRiga: any) {
    return this.ambitiWebService.delete(idRiga);
  }

  /**
 * Ambito
 * @param id 
 * @param ambito 
 * @returns 
 */
  public putAmbito(id: string, ambito: any) {
    return this.ambitiWebService.put(id, ambito);
  }
}
