import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtenteService } from '../utente/utente.service';
import { AziendaService } from '../azienda/azienda.service';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemaService {

  private _temaUpdated = new BehaviorSubject<string | undefined>(undefined);

  // non si usa .asObservable() per differenziare quando il valore emesso è diverso dall'ultimo
  // quindi scatta solamente quando sarà diverso
  public temaUpdated = this._temaUpdated.pipe(distinctUntilChanged());

  private _tema: 'SOSTENIBILE_CLOUD' | 'SOSTENIBILE_GO' = 'SOSTENIBILE_CLOUD';

  private _logoPath = {
    SOSTENIBILE_CLOUD: 'assets/images/logo/logo-sostenibile.png',
    SOSTENIBILE_GO: 'assets/images/logo/logo-go.png'
  };

  get logoPath() {
    return this._logoPath[this._tema] || this._logoPath['SOSTENIBILE_CLOUD']; // fallback su cloud se non c'è un'icona per il tema selezionato
  }

  private _bgPath = {
    SOSTENIBILE_CLOUD: 'assets/images/background/bg_login.png',
    SOSTENIBILE_GO: 'assets/images/background/bg_login.png'
  };

  get bgPath() {
    return this._bgPath[this._tema] || this._bgPath['SOSTENIBILE_CLOUD']; // fallback su cloud se non c'è un'icona per il tema selezionato
  }

  private _title = {
    SOSTENIBILE_CLOUD: 'Sostenibile.cloud',
    SOSTENIBILE_GO: 'Sostenibile.go'
  };

  get title() {
    return this._title[this._tema] || this._title['SOSTENIBILE_CLOUD']; // fallback su cloud se non c'è un'icona per il tema selezionato
  }

  constructor(
    private utenteService: UtenteService,
    private aziendaService: AziendaService,
  ) {
    this.aziendaService.aziendaCorrente.subscribe(() => {
      this.getAmbiente();
    });

    this.utenteService.infoUtenteUpdated.subscribe(() => {
      this.getAmbiente();
    });
  }

  /**
   * Metodo che mi ritorna l'environment
   * Se non ho un id azienda da passare l'ambiente verra preso da get info utente o url
   */
  public async getAmbiente() {

    // Azienda
    const azienda = await this.aziendaService.azienda
    if (azienda) {
      this._tema = azienda.tipoInstallazione || 'SOSTENIBILE_CLOUD';
      // Utente
    } else if (this.utenteService.infoUtente?.tipoInstallazione) {
      this._tema = this.utenteService.infoUtente?.tipoInstallazione || 'SOSTENIBILE_CLOUD';

    } else {
      // URL
      if (location.href.startsWith(environment.urlGo)) {
        this._tema = 'SOSTENIBILE_GO';
      } else {
        this._tema = 'SOSTENIBILE_CLOUD';
      }
    }

    this._temaUpdated.next(this._tema);

    console.log(this._tema);
  }

}
