<div mat-dialog-title>Sintesi di Sostenibilità</div>

<div mat-dialog-content class="dialog-center-cont" style="padding: 0px !important;">
    <app-spinner-overlay></app-spinner-overlay>
    <mat-stepper [linear]="!isPubblicato()" #stepper (selectionChange)="cambioStep($event)">

        <mat-step [stepControl]="formCreazioneSostenibilita" [editable]="true">


            <ng-scrollbar [visibility]="'hover'">
                <ng-template matStepLabel>
                    Sintesi di Sostenibilità
                </ng-template>

                <form [formGroup]="formCreazioneSostenibilita" appFormContainer [larghezza]="12" [gap]="10" [rowGap]="5"
                    style="align-items: center;">

                    <div appFormElement [larghezza]="12" [riempi]="true">

                        <div class="titolo-sezione-dialog">Creazione Sintesi di Sostenibilità</div>

                        <div class="descrizione-sezione-dialog">
                            Seleziona l'anno di riferimento per la tua Sintesi di Sostenibilità
                        </div>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4">
                        <mat-label>Anno</mat-label>
                        <mat-select (selectionChange)="onSelectionChangeAnno()" formControlName="anno"
                            placeholder="Anno">
                            <mat-option *ngFor="let aa of arrayAnni" [value]="aa"
                                [disabled]="ctrAnnoSintesiUtilizzata(aa)">{{aa}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div appFormElement [larghezza]="4" style="display:flex; flex-direction: column">
                        <mat-radio-group class="radio-tipo-sett" style="justify-content: space-around;"
                            aria-label="Tipo settore" formControlName="tipoSettore">
                            <mat-radio-button aria-label="Settore Generico" value="GENERALE"
                                [disabled]="this.formCreazioneSostenibilita.get('id')?.value || !this.settoreGenerale">Settore
                                Generico</mat-radio-button>
                            <mat-radio-button aria-label="Settore Specifico" value="SPECIFICO"
                                [disabled]="this.formCreazioneSostenibilita.get('id')?.value || !(this.settoriAzienda.length)">Settore
                                Specifico</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4" class="input-form"
                        *ngIf="this.formCreazioneSostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Settore</mat-label>
                        <mat-select formControlName="settori" placeholder="Settore" [compareWith]="compareById">
                            <mat-option *ngFor="let set of settoriAzienda" [value]="set"
                                [disabled]="ctrSettoreSintesiUtilizzata(set)">{{set.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="12" class="input-form"
                        *ngIf="this.formCreazioneSostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Sotto-settori</mat-label>
                        <mat-select formControlName="sottoSettori" placeholder="Sotto-settori"
                            [compareWith]="compareById" multiple>
                            <mat-option *ngFor="let sottoSet of arraySottoSettori"
                                [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="separatore" appFormElement [larghezza]="12">
                        <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                    </div>

                    <div appFormElement [larghezza]="12" [riempi]="true">

                        <div class="titolo-sezione-dialog">Informazioni Aziendali</div>

                        <div class="descrizione-sezione-dialog">
                            Compila le informazioni mancanti della tua Azienda per proseguire con la Sintesi di
                            Sostenibilità.
                        </div>
                    </div><!--  -->

                    <mat-form-field appFormElement [larghezza]="6" [riempi]="true">
                        <mat-label>Fatturato</mat-label>
                        <input matInput placeholder="Fatturato" formControlName="fatturato" type="number" appInputNumber
                            inputmode="numeric" min="0" max="999999999999" (keydown)="onKeyDown($event)"
                            (keyup)="checkSoglieMaterialita()" />
                        <mat-error appFormError></mat-error>
                    </mat-form-field>


                    <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
                        <mat-label>Nr. Dipendenti</mat-label>
                        <input matInput placeholder="Nr. Dipendenti" formControlName="nrDipendenti" type="number"
                            appInputNumber min="0" max="999999999999" (keydown)="onKeyDownDip($event)"
                            (keyup)="checkSoglieMaterialita()" inputmode="numeric" />
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <div class="separatore" appFormElement [larghezza]="12">
                        <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                    </div>

                    <div appFormElement [larghezza]="12" [riempi]="true">
                        <div class="titolo-sezione-dialog">Non partire da zero, lega la tua
                            sintesi ad
                            altre
                            attività</div>

                        <div class="descrizione-sezione-dialog">
                            Seleziona un'analisi di Materialità o un'Assessment di Sostenibilità da associare alla
                            Sintesi di
                            Sostenibilità
                        </div>
                    </div>

                    <div appFormElement [larghezza]="6">

                        <mat-spinner [diameter]="30" *ngIf="loadingAnalisiMaterialita"></mat-spinner>

                        <mat-form-field
                            *ngIf="!loadingAnalisiMaterialita && (formCreazioneSostenibilita.get('id')?.value || (formCreazioneSostenibilita.get('settori')?.value && arrayAnalisiMaterialita.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizionMaterialita()}}</mat-label>
                            <mat-select formControlName="idMaterialita" placeholder="Analisi Materialità"
                                (selectionChange)="onSelectionChangeMaterialita($event)">
                                <mat-option *ngFor="let mate of arrayAnalisiMaterialita"
                                    [compareWith]="compareAnalisiMaterialita" [value]="mate">{{mate.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idMaterialita')" matSuffix mat-icon-button type="button"
                                aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idMaterialita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button mat-stroked-button
                            *ngIf="!formCreazioneSostenibilita.get('id')?.value && !loadingAnalisiMaterialita && formCreazioneSostenibilita.get('settori')?.value && !arrayAnalisiMaterialita.length"
                            (click)="addMaterialita()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Analisi di Materialità
                        </button>


                        <mat-label
                            *ngIf="!formCreazioneSostenibilita.get('id')?.value && !loadingAnalisiMaterialita && !formCreazioneSostenibilita.get('settori')?.value">
                            Seleziona anno e settore per aggiungere altre attività
                        </mat-label>
                    </div>

                    <div appFormElement [larghezza]="6">
                        <mat-form-field
                            *ngIf="formCreazioneSostenibilita.get('id')?.value || (formCreazioneSostenibilita.get('settori')?.value && arrayPolicySostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizionPolicy()}}</mat-label>
                            <mat-select formControlName="idPolicySostenibilita" placeholder="Policy di Sostenibilità"
                                (selectionChange)="onSelectionChangePolicy($event)">
                                <mat-option *ngFor="let policy of arrayPolicySostenibilita"
                                    [compareWith]="comparePolicySostenibilita"
                                    [value]="policy">{{policy.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idPolicySostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idPolicySostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formCreazioneSostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formCreazioneSostenibilita.get('id')?.value && !arrayPolicySostenibilita?.length"
                            (click)="addPolicy()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Policy di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="6">
                        <mat-form-field
                            *ngIf="formCreazioneSostenibilita.get('id')?.value || (formCreazioneSostenibilita.get('settori')?.value && arrayPianoSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizionPiano()}}</mat-label>
                            <mat-select formControlName="idPianoSostenibilita" placeholder="Piano di Sostenibilità"
                                (selectionChange)="onSelectionChangePiano($event)">
                                <mat-option *ngFor="let piano of arrayPianoSostenibilita"
                                    [compareWith]="comparePianoSostenibilita"
                                    [value]="piano">{{piano.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idPianoSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idPianoSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formCreazioneSostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formCreazioneSostenibilita.get('id')?.value && !arrayPianoSostenibilita?.length"
                            (click)="addPiano()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Piano di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="6">
                        <mat-spinner [diameter]="30" *ngIf="loadingAutovalutazione"></mat-spinner>
                        <mat-form-field
                            *ngIf="!loadingAutovalutazione && (formCreazioneSostenibilita.get('id')?.value || (formCreazioneSostenibilita.get('settori')?.value && arrayAnalisiAutovalutazione.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneAssessment()}}</mat-label>
                            <mat-select formControlName="idAutovalutazioneCompilata"
                                [placeholder]="arrayAnalisiAutovalutazione.length > 0 ? 'Assessment di Sostenibilità' : 'Nessun assessment selezionato'"
                                (selectionChange)="onSelectionChangeAutovalutazione($event)">
                                <mat-option *ngFor="let auto of arrayAnalisiAutovalutazione"
                                    [compareWith]="compareAnalisiAutovalutazione"
                                    [value]="auto">{{auto.titolo}} - {{auto.esercizio}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idAutovalutazioneCompilata')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idAutovalutazioneCompilata', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div appFormElement [larghezza]="12" class="segnalazione" [ngClass]="{'show': mostraDescrizione}">
                        <div style="display: flex;">
                            <mat-icon>error</mat-icon>
                        </div>
                        <div class="descrizione">
                            {{guidaDescrizione}}
                        </div>
                    </div>

                </form>

            </ng-scrollbar>
        </mat-step>
        <!-- ? CREAZIONE SURVEY  -->
        <mat-step [editable]="true" [stepControl]="surveyComp.formCreaCfgQuestionario">
            <ng-template matStepLabel>Tematiche</ng-template>

            <app-cfg-questionario #surveyComp [isPermessoSoloRead]="isPermessoSoloRead" [tipoQuestionario]="'SINTESI'"
                [cfgQuestionario]="sintesi" [idSostenibilita]="formCreazioneSostenibilita.get('id')?.value"
                [nascondiTitolo]="true"></app-cfg-questionario>

        </mat-step>
        <!--  ---------------------- I M P E G N I ---------- -->
        <mat-step>
            <ng-template matStepLabel>Impegni</ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione degli Impegni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona gli impegni da seguire per ridurre il proprio impatto ambientale e per promuovere la
                        sostenibilità.
                    </div>

                </div>
            </div>

            <app-selezione-impegni-sintesi [isPermessoSoloRead]="isPermessoSoloRead" #elemImpegni
                [objQuestionario]="objQuestionarioImpegni" [settore]="sintesi?.settori" chiaveElemento="elementi"
                [idContest]="this.formCreazioneSostenibilita.get('id')?.value" [stato]="sintesi?.stato"
                chiaveElementoSelezione="impegno">

            </app-selezione-impegni-sintesi>

        </mat-step>

        <!--  ---------------------- Azioni e Buone Pratiche  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Azioni e Buone Pratiche
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Azioni e Buone Pratiche
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona le iniziative che l'azienda può intraprendere per ridurre il proprio impatto
                        ambientale.
                    </div>

                </div>
            </div>

            <app-selezione-azioni-buonepratiche-sintesi [isPermessoSoloRead]="isPermessoSoloRead" #elemAzioni
                [objQuestionario]="objQuestionarioAzioniBP" chiaveElemento="elementi"
                [idContest]="this.formCreazioneSostenibilita.get('id')?.value" chiaveElementoSelezione="azionebp"
                [stato]="sintesi?.stato">

            </app-selezione-azioni-buonepratiche-sintesi>

        </mat-step>
        <!--  ---------------------- K P I ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                KPI
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione dei KPI
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona gli indicatori di valutazione relativi agli obiettivi di sostenibilità stabiliti.
                    </div>

                </div>
            </div>

            <app-selezione-kpi-sintesi [isPermessoSoloRead]="isPermessoSoloRead" #elemKpi
                [objQuestionario]="objQuestionarioKPI" chiaveElemento="elementi"
                [idContest]="this.formCreazioneSostenibilita.get('id')?.value" [stato]="sintesi?.stato"
                chiaveElementoSelezione="kpi">
            </app-selezione-kpi-sintesi>

            <div style="display: flex;flex-direction: column;padding-top: 10px;">

                <div class="titolo-sezione-dialog">
                    Includi Anni Precedenti
                </div>

                <div class="descrizione-sezione-dialog">
                    <mat-slide-toggle [disabled]="isPermessoSoloRead" labelPosition="before"
                        [(ngModel)]="valueToggleAnni" [checked]="valueToggleAnni"
                        (change)="cambioToggle($event)">Abilita la visualizzazione e
                        la modifica dei KPI degli anni
                        precedenti durante la
                        compilazione.</mat-slide-toggle>
                </div>

            </div>

        </mat-step>

        <!--  ---------------------- Certificazioni  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Certificazioni
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Certificazioni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Scegli le certificazioni pertinenti tra quelle elencate per valorizzare la tua azienda.
                    </div>

                </div>
            </div>

            <app-selezione-certificazioni-sintesi [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objQuestionarioCertificazioni" chiaveElemento="elementi"
                [idContest]="this.formCreazioneSostenibilita.get('id')?.value" [stato]="sintesi?.stato"
                chiaveElementoSelezione="certif">
            </app-selezione-certificazioni-sintesi>

        </mat-step>

        <!--  ---------------------- C R U S C O T T O ---------- -->
        <mat-step>
            <ng-template matStepLabel>Cruscotto</ng-template>
            <div class="barra-titolo-cruscotto">

                <div class="titolo-slide">
                    <div class="titolo-sezione-dialog">Cruscotto Sintesi di Sostenibilità</div>

                    <div class="descrizione-sezione-dialog">Gestisci, Invia e Ricorda. Tutto per la tua
                        organizzazione.
                    </div>
                </div>

                <div class="contatori">
                    <div style="text-align: center;">
                        <div class="titolo-cont">KPI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{kpiCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{kpiNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">AZIONE E BUONE PRATICHE</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{azioneCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{azioneNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">IMPEGNI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{impegniCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{impegniNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-scrollbar>
                <div style="padding-right: 15px;">
                    <app-lista-tabellare #tabella [defaultPageSize]="4" [fnCaricamentoDati]="fnCaricamentoDati"
                        [colonne]="colonne" [maxNrButton]="1" [filtri]="filtri"
                        (bottoniListaClick)="bottoniListaClick($event)"
                        *ngIf="this.formCreazioneSostenibilita.get('id')?.value">
                        <ng-container btnEnd>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="template()">
                                <mat-icon matSuffix>settings</mat-icon>
                                Testi Mail
                            </button>


                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="inviaTutti()">
                                <mat-icon matSuffix>mail</mat-icon>
                                Invia a Tutti
                            </button>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="notificaTutti()">
                                <mat-icon matSuffix>notifications_active</mat-icon>
                                Notifica Tutti
                            </button>

                        </ng-container>
                    </app-lista-tabellare>
                </div>
            </ng-scrollbar>

        </mat-step>

        <!--  ---------------------- S T A M P A ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Configurazione Stampa
            </ng-template>

            <app-cfg-stampa [isPermessoSoloRead]="isPermessoSoloRead" [fnGetConfigStampa]="fnGetConfigStampa"
                [fnSalvaCfgStampa]="fnSalvaCfgStampa" [fnCaricaImg]="fnImportaImmagine" [fnEliminaImg]="fnEliminaImg"
                (loading)="toggleLoading($any($event))"></app-cfg-stampa>

        </mat-step>
    </mat-stepper>

</div>

<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-flat-button color="accent" [disabled]="stepper.selectedIndex === 0" (click)="indietro()"
            class="accent-button">Indietro</button>
    </div>
    <ng-container *ngIf="stepper.selectedIndex !== 7">
        <div>
            <button mat-flat-button color="primary" (click)="avantiHTML()" class="primary-button" #btnAvanti>
                Avanti
            </button>
        </div>
        <div *ngIf="mostraBottoneAssegnazioni">
            <button mat-flat-button color="primary" (click)="assegnazioni(stepper.selectedIndex)"
                class="primary-button">
                <mat-icon>person_edit</mat-icon> Assegnazioni
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="stepper.selectedIndex === 7">
        <button *ngIf="!isPermessoSoloRead" mat-flat-button color="primary" (click)="salvaCfgStampa()"
            class="primary-button">
            Salva
        </button>
        <button matDialogClose *ngIf="isPermessoSoloRead" mat-flat-button color="accent" class="accent-button">
            Chiudi
        </button>
        <button [disabled]="!this.cfgStampaComponent.isPermessoStampa" mat-flat-button color="accent"
            *ngIf="stepper.selectedIndex === 7" (click)="stampa()" class="accent-button" style="margin: 0 !important;">
            <mat-icon class="material-icons-outlined">
                print
            </mat-icon>Stampa
        </button>
    </ng-container>
</div>