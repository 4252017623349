import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-cfg-sotto-settori',
  templateUrl: './dialog-crea-cfg-sotto-settori.component.html',
  styleUrls: ['./dialog-crea-cfg-sotto-settori.component.scss']
})
export class DialogCreaCfgSottoSettoriComponent implements OnInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public formSottoSettori = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    })
  });

  constructor(
    private settoriService: SettoriService,
    private utilityService: UtilityService,
    public dialogRef: MatDialogRef<DialogCreaCfgSottoSettoriComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      settore: Settore,
      sottoSettore: SottoSettore
    }
  ) { }

  ngOnInit() {
    if (this.data.sottoSettore) {
      Object.keys(this.data.sottoSettore).forEach((value, index, array) => {
        this.formSottoSettori.get(value)?.setValue((this.data.sottoSettore as any)[value]);
      });
    }
  }

  salva() {

    if (this.formSottoSettori.valid) {
      this.spinnerOver.show();

      const id = this.formSottoSettori.get('id')?.value;

      let obs: Observable<any>;

      if (id) {
        obs = this.settoriService.putSottoSettore(this.data.settore.id, this.formSottoSettori.getRawValue());
      } else {
        obs = this.settoriService.postSottoSettore(this.data.settore.id, this.formSottoSettori.getRawValue());
      }

      obs.subscribe(
        {
          next: (result: any) => {

            this.spinnerOver.hide();

            this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.dialogRef.close();

          },
          error: (err: any) => {

            console.error('Errore salvataggio sottosettore: ', err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio',
              bottoni: [{ nome_btn: 'Chiudi' }]
            });

          }
        }
      );
    } else {
      Object.values(this.formSottoSettori.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }

  }
}
