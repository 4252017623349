import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Filtri, Colonna, BottoniListaEvent, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { DialogCreaCfgAutovalutazioneComponent } from './dialog/dialog-crea-cfg-autovalutazione/dialog-crea-cfg-autovalutazione.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { Observable, map, of } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { DialogAnteprimaComponent } from './dialog/dialog-anteprima/dialog-anteprima.component';

@Component({
  selector: 'app-cfg-autovalutazioni',
  templateUrl: './cfg-autovalutazioni.component.html',
  styleUrls: ['./cfg-autovalutazioni.component.scss']
})
export class CfgAutovalutazioniComponent implements OnInit {


  @ViewChild(SpinnerOverlayComponent) spinner!: SpinnerOverlayComponent;
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      forControlName: 'titolo',
      input: 'text',
      titolo: 'Titolo'
    },
    {
      forControlName: 'tipo',
      input: 'multiple-option',
      titolo: 'Tipo',
      fnDatiOption: () => of([{
        valore: 'GENERICO',
        desc: 'Generico'
      },
      {
        valore: 'SETTORE',
        desc: 'Per settore'
      }]),
      descValueOption: 'desc',
      idValueOption: 'valore'
    },
    {
      forControlName: 'settore',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true
    }
  ];

  public colonne: { [key: string]: Colonna } = {
    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    tipo: {
      title: 'Tipo',
      sortable: true,
      campiSort: ['tipo'],
      value: (record: any) => record.tipoAutovalutazione === 'SETTORE' ? 'Per settore' : 'Sito vetrina'
    },
    settori: {
      title: 'Settori',
      sortable: true,
      campiSort: ['settore'],
      value: (record: any) => (record?.settori?.map((set: any) => set.titolo))
    },
    stato: {
      title: 'Stato',
      sortable: true,
      campiSort: ['stato'],
      value: (record: any) => {
        if (record?.stato === 'PUBBLICATO') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#CCDBCD;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#729373;font-size:13px">Pubblicata</p></div>'
        } else if (record?.stato === 'BOZZA') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#FFE199;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#C7A045;font-size:13px">Bozza</p></div>'
        } else if (record?.stato === 'ARCHIVIATO') {
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c53032;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#fff;font-size:13px">Archiviato</p></div>'
        } else {
          return '';
        }
      }
    },
    modifica: {
      type: 'button',
      buttonIcon: (record: any) => (record?.stato === 'BOZZA' ? 'mode_edit' : 'visibility'),
      title: (record: any) => (record?.stato === 'BOZZA' ? 'Modifica' : 'Visualizza'),
      buttonId: (record: any) => (record?.stato === 'BOZZA' ? 'modifica' : 'visualizza'),
      buttonMostraSempre: true
    },
    duplica: {
      type: 'button',
      buttonIcon: 'content_copy',
      title: 'Duplica',
      buttonId: 'duplica',
    },
    elimina: {
      type: 'button',
      buttonIcon: (record: any) => (record.stato === 'BOZZA' ? 'delete' : 'inventory_2'),
      title: (record: any) => (record?.stato === 'BOZZA' ? 'Elimina' : 'Archivia'),
      buttonId: 'elimina',
      nascondiButton: (record: any) => (record?.stato === 'ARCHIVIATO' ? true : false),
    },
  }

  constructor(
    private autovalutazioniService: AutovalutazioniService,
    private dialog: MatDialog,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    public utenteService: UtenteService
  ) {
  }

  ngOnInit(): void {
  }

  public crea(recordCfgAuto?: any) {

    this.spinner.show();

    let obs: Observable<any | null>;

    if (recordCfgAuto) {
      obs = this.autovalutazioniService.getCfgAutovalutazioneById(recordCfgAuto.id)
    } else {
      obs = of(null);
    }

    obs.subscribe({
      next: (autovalutazione) => {

        this.spinner.hide();

        if (recordCfgAuto?.stato === 'PUBBLICATO') {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Assessment di Sostenibilità pubblicata non è possibile modificarla',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return;
        }

        const dialogFiltro = this.dialog.open(DialogCreaCfgAutovalutazioneComponent, {
          data: {
            cfgAutovalutazione: autovalutazione,
          },
          panelClass: 'dialog-container',
          disableClose: false,
          width: '95%',
          height: '98%',
          maxHeight: '98%',
          autoFocus: false,
        });

        dialogFiltro.afterClosed().subscribe((result) => {

          this.tabella.caricaDati();
        });

      },
      error: (error) => {

        console.error(error);

        this.spinner.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error.error.message,
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    });

  }


  public visualizza(autovalutazione: any) {

    const dialogFiltro = this.dialog.open(DialogAnteprimaComponent, {
      data: {
        ambito: autovalutazione.ambiti
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      height: '95%',
      autoFocus: false,
    });

    dialogFiltro.afterClosed().subscribe((result) => {

    });

  }


  public fnCaricamentoDati: FnCaricamentoDati = (page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]) => {
    return this.autovalutazioniService.getCfgAutovalutazioni(page, pageSize, ricerca, filters, sortBy);
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'visualizza':
        this.visualizza(event.data);
        break;
      case 'elimina':
        this._delCfgAutovalutazione(event.data);
        break;
      case 'duplica':
        this._duplica(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }
  private _duplica(cfgAutovalutazione: any) {

    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Duplicare il record selezionato?',
      bottoni: [
        {
          nome_btn: 'No',

        },
        {
          nome_btn: 'Si',
          handler: () => {
            this.autovalutazioniService.duplicaAutovalutazione(cfgAutovalutazione.id).subscribe({
              next: (result: any) => {


                this.utilityService.opneSnackBar(' Autovalutazione duplicata ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.tabella.caricaDati();

              },
              error: (err: any) => {
                console.error('Errore :', err);

                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: 'Errore duplicazione',
                  bottoni: [{ nome_btn: 'Ok' }]
                })
              }
            })
          }
        },
      ]
    })

  }

  private _delCfgAutovalutazione(cfgAutovalutazione: any) {

    /*     if (cfgAutovalutazione?.stato === 'PUBBLICATO') {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Autovalutazione pubblicata non è possibile cancellarla',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return;
        } */

    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: cfgAutovalutazione?.stato === 'PUBBLICATO' ? 'Confermi l\'archiviazione dell\'assessment?' : 'Confermi l\'eliminazione dell\'assessment?',
      bottoni: [
        { nome_btn: 'NO' },
        {
          nome_btn: 'SI', handler: () => {
            this.spinner.show();
            // fai cancellazione 
            this.autovalutazioniService.delCfgAutovalutazione(cfgAutovalutazione.id).subscribe({
              next: (result: any) => {


                this.utilityService.opneSnackBar(cfgAutovalutazione?.stato === 'PUBBLICATO' ? 'Assessment di Sostenibilità archiviata' : 'Assessment di Sostenibilità eliminata', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.tabella.caricaDati();
                this.spinner.hide();
              },
              error: (err: any) => {
                this.spinner.hide();
                console.error('Errore :', err);

                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: 'Errore elimina',
                  bottoni: [{ nome_btn: 'Ok' }]
                })
              }
            })

          }
        }]
    });
  }


}
