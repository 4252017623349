<div class="container">

    <h2 mat-dialog-title>Imposta Esclusioni</h2>
    <div class="esclud">
        <div *ngFor="let esclud of esclusioni">
            <mat-checkbox [disabled]="isPermessoSoloRead" color="primary" [checked]="isChecked(esclud.codice)"
                (change)="esclusioniSelezionate(esclud.codice)">
                <div class="desc-esclud">
                    {{esclud.descrizione}}
                </div>
            </mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div *ngIf="!isPermessoSoloRead">
            <button mat-flat-button color="primary" (click)=" conferma()" class="primary-button">
                Conferma
            </button>
        </div>
    </div>
</div>