import { ValoriKpi } from './../KPI/kpi.service';
import { Injectable } from '@angular/core';
import { TematicheWebService } from './tematiche-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Impatto } from '../impatto/impatto.service';
import { Impegno } from '../impegni/impegni.service';
import { KPI } from '../KPI/kpi.service';
import { AzioneBP } from '../azione-e-buona-pratica/azione-e-buona-pratica.service';
import { Certificazione } from '../certificazioni/certificazioni.service';
import { SottoSettore } from '../settori/settori.service';


export interface SDGS {
  chiave: string,
  valore: string,
}

export interface TematicaBase {
  id: string;
  chiave: string;
  obbligatorio: boolean;
  domanda: string;
  descrizione: string;
  note: string;
  idKpis: string[];
  tipo: string;
  punteggio: 0,
  punteggioPercentuale: 0,
  opzioni: OpzioneTematica[];
  risposte: string[] | null;
  commento: string;
  min: number;
  max: number;
  risposta?: Risposta;
  sottodomande?: string[];
  chiaveGruppoUM: string;
  stdr?: string[];
  sdgs?: SDGS[];
  sottoSettori?: SottoSettore[];
  aree: string[];
  nomeBreve: string;
  idsAmbiti?: string[];
  locked?: boolean;
  infos?: string[];
  minLivelloRispostaAutomatica?: number;
}

export interface Tematica extends TematicaBase {
  impatti: Impatto[];
  impegni?: Impegno[];
  kpis?: KPI[];
  azioni?: AzioneBP[];
  certificazioni?: Certificazione[];
}
export interface TematicaSurvey extends TematicaBase {
  impattiDtos: Impatto[];
}

interface OpzioneTematica {
  id?: string;
  chiave: string;
  tipo: 'checkbox' | 'azione' | 'kpi';
  valore: string;
  note: string;
  punteggio: string;
  sottoOpzioni: OpzioneTematica[];
  sottoRisposte?: string[];
  value?: number; // valore della sottoRisposta ( serve pre calcolo del punteggio)
  descrizione: string;
  idCategoriaUnitaDiMisura: string;
  idUnitaDiRiferimento: string;
  valoreAttribuito: number;
  risposta: string;
  checked: boolean;
  valoriKpi: ValoriKpi[]
}
export interface AreeTematica {
  chiave: string;
  descrizione: string;
}

export interface Risposta {
  id: string;
  chiave: string;
  valore: string;
  um: string;
};

@Injectable({
  providedIn: 'root'
})
export class TematicheService {

  constructor(private tematicheWebService: TematicheWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getTematiche(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.tematicheWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del Tematica
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione post Tematiche per la creazione Tematica
     */
  public postTematiche(data: any) {
    return this.tematicheWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteTematica(idRiga: any) {
    return this.tematicheWebService.delete(idRiga);
  }

  /**
   * Tematica
   * @param id 
   * @param tematica 
   * @returns 
   */
  public putTematica(id: string, tematica: any) {
    return this.tematicheWebService.put(id, tematica);
  }

  public getAree() {
    return this.tematicheWebService.getAree();
  }
  public getStdr() {
    return this.tematicheWebService.getStdr();
  }

  /**
   * Get degli Obiettivi di Sviluppo Sostenibile
   * @returns 
   */
  public getSDGS() {
    return this.tematicheWebService.getSDGS();
  }

  /**
   * Put degli Obiettivi di Sviluppo Sostenibile
   * @returns 
   */
  public putSDGS(idTematica: string) {
    return this.tematicheWebService.putSDGS(idTematica);
  }


}
