import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, filter, map, of, throwError } from 'rxjs';
import { InfoUtente, UtenteWebService } from './utente-web.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { AziendaService } from '../azienda/azienda.service';


export interface Utente {
  keyId: string;
  idAziende: string[];
  firstName: string;
  lastName: string;
  email: string;
  ruolo: RuoliList;
  attivo: boolean;
  username: string;
  cmsId: string;
  id: string;
  lastAziendaSel: string;
  codiceLicenza: string;
  utenteResponsabile: Responsabile;
}

export interface RuoliList {
  descrizione: string;
  roleName: string;
  systemRole: boolean
}

export interface Responsabile {
  keyId?: string,
  id?: string,
  nomeUtente?: string,
  ruolo?: RuoliList,
  idAziende?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class UtenteService {
  public nrChiamataDialogUsr: number = 0;

  /**
   * Emette true quando le informazioni dell'utente vengono aggiornate 
   */
  private _infoUtenteUpdated = new BehaviorSubject<InfoUtente | undefined>(undefined);
  public infoUtenteUpdated = this._infoUtenteUpdated.asObservable();

  public infoUtente: InfoUtente | undefined;

  private _permessi: { [key: string]: boolean } = {};

  constructor(
    private aziendaService: AziendaService,
    private utenteWebService: UtenteWebService,
  ) {
    // Se sente un cambio azienda, aggiorna anche su DB
    this.aziendaService.aziendaCorrente.pipe(
      distinctUntilChanged(),
      filter((value) => !!value)
    ).subscribe((azienda) => {

      if (azienda?.id) {
        this._aggiornaAziendaSelezionata(azienda.id).subscribe({
          next: (result) => {


            if (azienda?.id) {
              this.aziendaService.setSoglia(azienda?.id);
            }

          },
          error: (err) => {
            console.error(err);
          }
        });
      }
    });
  }

  login(datiLogin: { username: string; password: string; rememberMe: boolean; origin: string }): Observable<any> {
    return this.utenteWebService.login(datiLogin).pipe(
      catchError((error: HttpErrorResponse) => {
        let msg = "Impossibile effettuare l'accesso. Riprovare più tardi.";

        if (error.status === 401) {
          // Se c'è un exceptionCode specifico per questo errore, si ritorna quello, altrimenti il messaggio di credenziali non valide.
          msg = error?.error?.exceptionCode || 'Utente o password non validi';
        }

        return throwError(() => msg);
      })
    );
  }

  logout() {
    return this.utenteWebService.logout();
  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public getUtenti(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.utenteWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  public getUtente(idUtente:string){
    return this.utenteWebService.getUtente(idUtente);
  }

  /**
   * Funzine ws per la creazione del Utente
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postutente per la creazione Utente
   */
  public postUtente(data: any) {
    return this.utenteWebService.post(data);
  }

  /**
    * Funzione per disattivare l'utente
    * @param idRiga id dell'utente da disattivare
    * @returns 
    */
  public disattivaUtente(idRiga: any) {
    return this.utenteWebService.disattivaUtente(idRiga);
  }

  /**
    * Funzione per attivare l'utente
    * @param idRiga id dell'utente da attivare
    * @returns 
    */
  public attivaUtente(idRiga: any) {
    return this.utenteWebService.attivaUtente(idRiga);
  }

  /**
     * Chiamata endpoint per lista ruoli 
     * @returns lista di tutti i ruoli
     */
  public getRuoli() {
    return this.utenteWebService.getRuoli();
  }

  public getRuoliCreate() {
    return this.utenteWebService.getRuoliCreate();
  }

  public putUtente(keyId: string, utente: any) {
    return this.utenteWebService.put(keyId, utente);
  }


  public simulaUtente(idUtente: string, origin: string) {
    return this.utenteWebService.simulaUtente(idUtente, origin);
  }

  public resetPassword(idUtente: string) {
    return this.utenteWebService.resetPassword(idUtente);
  }

  public richiediResetPassword(username: string) {
    return this.utenteWebService.richiediResetPassword(username);
  }


  public getUtentiByIdAzienda(
    idAzienda: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    if (!filters?.length) {
      filters = [{
        values: [
          idAzienda
        ],
        operator: "eq",
        chiave: "idAziende"
      }];
    } else {

      filters = filters.filter((val) => { return val.chiave !== 'idAziende' });

      filters.push({
        values: [
          idAzienda
        ],
        operator: "eq",
        chiave: "idAziende"
      });
    }
    return this.utenteWebService.getUtentiByIdAzienda(page, pageSize, ricerca, filters, sortBy);
  }

  public setNrChiamataDialogUsr(chiamata: number) {
    if (chiamata === 0) {
      this.nrChiamataDialogUsr = 0;;
    } else {
      this.nrChiamataDialogUsr += chiamata;
    }
  }

  public getNrChiamataDialogUsr() {
    return this.nrChiamataDialogUsr;
  }

  /**
   * Mi aggiorna le info dell'utente e mi mantiene
   * le aziende aggiornate ogni volta che viene effettuata una richiesta
   * @returns 
   */
  public aggiornaInfoUtente() {
    return this.getInfoUtente().pipe(
      map((result) => {

        this.infoUtente = result;

        this._infoUtenteUpdated.next(this.infoUtente);

        this.aziendaService.caricaAziende();

        this._permessi = {};

        if (this.infoUtente.userDetails.permissions.length) {
          this.infoUtente.userDetails.permissions.forEach((perm) => {
            this._permessi[perm] = true;
          });

        }

        if (result?.utente?.lastAziendaSel) {
          this.aziendaService.getAziendaByIdAzienda(result.utente.lastAziendaSel).subscribe({
            next: (resultAz) => {
              if (resultAz) {
                this.aziendaService.azienda = resultAz;
              }
            },
            error: (err) => {
              console.error(err);
            }
          })
        }

        return true;
      }),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }

  private _aggiornaAziendaSelezionata(idAzienda: string) {
    return this.utenteWebService.aggiornaAziendaSelezionata(idAzienda);
  }

  public getInfoUtente() {
    return this.utenteWebService.getInfoUtente();
  }


  /**
   * METODO DA UTILIZZARE PER VEDERE SE ATTIVO QUESTO PERMESSO
   * @param chiave 
   * @returns 
   */
  public isPermessoAttivo(chiave: string): boolean {
    return this._permessi[chiave] || false;
  }

}
