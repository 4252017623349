<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>
  Utente
</h2>
<div mat-dialog-content class="dialog-center-cont">
  <form [formGroup]="formUtenti" appFormContainer [larghezza]="3">

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Nome</mat-label>
      <input matInput placeholder="Nome" formControlName="firstName" />

      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Cognome</mat-label>
      <input matInput placeholder="Cognome" formControlName="lastName" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" formControlName="email" type="email" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field class="input-form" appFormElement [larghezza]="3">
      <mat-label>Ruoli Utente</mat-label>
      <mat-select formControlName="ruolo" placeholder="Ruoli Utente" [compareWith]="compareRuoli"
        (selectionChange)="onSelectionChangeRuolo($event)">
        <mat-option *ngFor="let rol of arrayRuoli" [value]="rol"
          [disabled]="ctrDisable(rol.roleName)">{{rol.descrizione}}</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container
      *ngIf="(this.formUtenti.get('ruolo')?.value?.roleName === 'collaboratore' || this.formUtenti.get('ruolo')?.value?.roleName === 'collaboratore_go') && this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore'">
      <mat-form-field class="input-form" appFormElement [larghezza]="3">
        <mat-spinner style="position: absolute; left: 120px;
    top: 15px;" [diameter]="25" *ngIf="loadingResponsabile"></mat-spinner>
        <mat-label>Responsabile</mat-label>
        <mat-select formControlName="utenteResponsabile" placeholder="Responsabile" [compareWith]="compareResponsabile"
          (selectionChange)="onSelectionChangeResponsabile($event)">
          <mat-option *ngFor=" let responsabile of objResponsabile" [value]="responsabile">{{
            (responsabile.ruolo?.descrizione?.toUpperCase()
            || '') + ' - ' + responsabile.nomeUtente }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div class="separatore" appFormElement [larghezza]="3">
      <app-separatore-label titoloSeparatore="Assegnazione Aziende"></app-separatore-label>
    </div>

    <mat-form-field class="input-form" appFormElement [larghezza]="3">
      <mat-label>Aziende</mat-label>
      <mat-select formControlName="idAziende" placeholder="Aziende" [(value)]="selectedAzienda" multiple>
        <mat-option *ngFor="let azd of arrayAziende" [value]="azd.id">{{azd.ragioneSociale}}</mat-option>
      </mat-select>
    </mat-form-field>

  </form>

  <!-- G E S T I O N E     L I C E N Z E  -->
  <div *ngIf="canSeeLicenze()">
    <div class="separatore" appFormElement [larghezza]="3">
      <app-separatore-label titoloSeparatore="Licenze Base"></app-separatore-label>
    </div>
    <app-gestione-licenze [isNuovo]="this.formUtenti.get('id')?.value ? false : true"
      [isSostenibileGo]="this.formUtenti.get('ruolo')?.value?.roleName === 'utente_premium_go'"></app-gestione-licenze>
  </div>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
  <div>
    <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
  </div>
  <div>
    <button [disabled]="isPermessoSoloRead && this.utenteService.infoUtente?.utente?.keyId !== this.data.utente.keyId"
      mat-flat-button color="primary" (click)="salvaUtente()" class="primary-button">
      Salva
    </button>
  </div>
  <div *ngIf="canSeeLicenze() && this.formUtenti.get('id')?.value ">
    <button [disabled]="isPermessoSoloRead" mat-flat-button color="accent" style="max-width: 155px !important;"
      class="accent-button" (click)="gestioneRiepilogoLicenze()">Riepilogo Licenze</button>
  </div>
</div>