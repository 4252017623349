import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Colonna, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { MatDialog } from '@angular/material/dialog';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { InfoUtente } from 'src/app/services/utente/utente-web.service';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { Subscription } from 'rxjs';


interface DatiBarChart {
  titolo: string,
  utilizzati: number,
  nonUtilizzati: number 
}

interface RiepilogoGenerale {
  nomeAzienda: string,
  datiRiepilogoPerSettore: RiepilogoPerSettore[],
}

interface RiepilogoPerSettore {
  nomeSettore: string,
  anno: number,
  statoAutovalutazione: string,
  statoPolicy: string,
  statoBilancio: string,
  statoSintesi: string,
  statoMaterialita: string
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',]
})

export class DashboardComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) barChart: BaseChartDirective<'bar'> | undefined;
  @ViewChild('tabella') tabellaRiepilogo!: ListaTabellareComponent;

  public infoUtente: InfoUtente | undefined;
  private isUtenteGo: boolean = false;

  public colonneRiepilogo: { [key: string]: Colonna } | undefined = undefined;

  public datiBarChart: DatiBarChart[] | undefined = undefined;
  public datiRiepilogoGenerale: RiepilogoGenerale[] | undefined;
  private _subTema: Subscription | undefined;
  constructor(
    public dialog: MatDialog,
    private utenteService: UtenteService,
    private dashboardService: DashboardService,
    private temaService: TemaService,
  ) {

    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })

    this.infoUtente = this.utenteService.infoUtente;
  }

  ngOnInit(): void {

    let colonne: any = {
      nomeAzienda: {
        title: 'Azienda',
        value: 'nomeAzienda',
        sortable: true,
      },
      anno: {
        title: 'Anno',
        value: 'anno',
        sortable: true,
      },
      nomeSettore: {
        title: 'Settore',
        value: 'nomeSettore',
        sortable: true,
      },
      statoAutovalutazione: {
        title: 'Assessment',
        value: (record: any) => {
          if (record?.statoAutovalutazione === 'rosso') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else if (record?.statoAutovalutazione === 'giallo') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else if (record?.statoAutovalutazione === 'verde') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#CCDBCD;width:90px;text-align: center;border-radius: 15px;height:27px"><p style="color:#729373;font-size:13px">' + record?.statoAutovalutazione + '</p></div>'
          }
        },
        sortable: true
      },
    };

    if (!this.isUtenteGo) {
      colonne.statoMaterialita = {
        title: 'Materialità',
        value: (record: any) => this.ctrRiepilogoColore(record.statoMaterialita),
        sortable: true
      };

      colonne.statoPolicy = {
        title: 'Policy',
        value: (record: any) => this.ctrRiepilogoColore(record.statoPolicy),
        sortable: true
      };

      colonne.piano = {
        title: 'Piano',
        value: (record: any) => this.ctrRiepilogoColore(record.statoPiano),
        sortable: true
      };

      colonne.statoSintesi = {
        title: 'Sintesi',
        value: (record: any) => this.ctrRiepilogoColore(record.statoSintesi),
        sortable: true
      };
    }

    colonne.statoBilancio = {
      title: this.isUtenteGo ? 'Report Tematico' : 'Bilancio',
      value: (record: any) => this.ctrRiepilogoColore(record.statoBilancio),
      sortable: true
    }

    this.colonneRiepilogo = colonne;
  };


  ngAfterViewInit(): void {

    this.getInfoLicenze();

  }
  ngOnDestroy(): void {
    this._subTema?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.dashboardService.getRiepilogoGenerale(page, pageSize, ricerca, filters, sortBy);
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 1,
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },

    },
    plugins: {
      legend: {
        display: true,
        align: 'end',
      },

    },
  };
  public barChartType = 'bar' as const;

  public barChartData: ChartData<'bar'> = {

    labels: [],
    datasets: [
      {
        data: [],
        label: 'Non Utilizzati',
        backgroundColor: '#A5DFC6',
        borderColor: '#77CF7A',
        borderWidth: 1

      },
      {
        data: [],
        label: 'Utilizzati',
        backgroundColor: '#FFB1C1',
        borderColor: '#FF6384',
        borderWidth: 1

      }
    ],
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void { }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

  /**
   * Metodo che setta i dati iniziali del grafico per Disponibilità Pacchetti 
   */
  private _setDatiBarChart() {
    if (this.datiBarChart) {
      this.datiBarChart.forEach((data: DatiBarChart | null) => {
        if (data) {
          this.barChartData.labels?.push(data.titolo);
          (this.barChartData.datasets[1].data as number[]).push(data.utilizzati);
          (this.barChartData.datasets[0].data as number[]).push(data.nonUtilizzati);
        }
      });
      this.barChart?.update();
    }
  }

  private getInfoLicenze() {
    if (this.infoUtente?.utente.id) {
      this.dashboardService.getInfoLicenze(this.infoUtente?.utente.id).subscribe({
        next: (esito) => {
          this.datiBarChart = esito.datiBarCharts;
          this._setDatiBarChart();
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }

  ctrRiepilogoColore(statoContesto: string) {
    if (statoContesto === 'Completata') {
      return '<div style="font-weight: bold; color: var(--mat-color-primary)">' + statoContesto + '</div>';
    }
    if (statoContesto === 'Iniziata') {
      return '<div style="font-weight: bold; color:var(--mat-color-accent)">' + statoContesto + '</div>';
    } else {
      return statoContesto;
    }

  }
}

