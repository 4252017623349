import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { DialogCreaImpegnoComponent } from 'src/app/page/configurazioni/cfg-impegni/dialog/dialog-crea-impegno/dialog-crea-impegno.component';
import { of, Observable, map, catchError, firstValueFrom, tap } from 'rxjs';
@Component({
  selector: 'app-selezione-impegni-policy',
  templateUrl: '../../selezione-elementi-questionario.component.html',
  styleUrls: ['../../selezione-elementi-questionario.component.scss']
})
export class SelezioneImpegniPolicyComponent extends SelezioneElementiQuestionarioComponent {
  public sonoInCtr: boolean = false;
  override editElementoListaChk(idAmbito: string, idTematica: string, impegno?: any): void {
    const dialogCreaImpegno = this.dialog.open(DialogCreaImpegnoComponent, {
      data: {
        impegno: impegno,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'policy'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaImpegno.afterClosed().subscribe((result) => {

      if (result) {
        this.spinnerOver.show();

        if (this.sonoInCtr) {
          impegno.selected = true;
          this.sonoInCtr = false;
        }

        this.salvataggio(idAmbito, idTematica, impegno?.id);

      } else if (this.sonoInCtr) {
        this.spinnerOver.show();
        this.sonoInCtr = false;
        impegno.selected = false;
        this.salvataggio(idAmbito, idTematica, impegno?.id);
      }
    });
  }

  async salvataggio(idAmbito: string, idTematica: string, idImpegno: string) {
    try {
      // Salvo i dati modificati
      await firstValueFrom(this.salva(true));

      // Get dei dati aggiornati 
      await firstValueFrom(this._getImpegniPolicy());

      // Scroll all'elemento modificato 
      setTimeout(() => {
        this.scrollToElement(idAmbito, idTematica, idImpegno);
      }, 0);

    } catch (error) {
      console.error(error);
    }
  }

  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del impegno 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idImpegno: string, idFigAziendale: string): void {
    this.spinnerOver.show();
    this.policyService.putAssegnaFiguraImpegnoPolicy(this.idContest, idAmbito, idTematica, idImpegno, idFigAziendale).subscribe({
      next: () => {
        this.salvataggio(idAmbito, idTematica, idImpegno);

      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }

  private _getImpegniPolicy(): Observable<any> {
    this.spinnerOver.show();

    return this.policyService.getImpegniPolicy(this.idContest).pipe(
      tap((esito) => {
        this.objQuestionario = esito;
      }),
      catchError((err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli impegni',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(null);
      }),
      tap({
        finalize: () => this.spinnerOver.hide()
      })
    );
  }


  override ctrStepper(msgChiave: string): boolean {
    return super.ctrStepper(msgChiave);
  }


  salva(noCtr?: boolean) {
    let impegniSelezionati = this.oggettoElementiSelezionati();


    //Controllo se è stato selezionato almeno un impatto per tematica 
    if (!noCtr && this.ctrElemChecked(impegniSelezionati, 'impegno')) {
      return of(false);
    }

    return this._salvaSurveyElemSel(impegniSelezionati);
  }


  private _salvaSurveyElemSel(impegniSelezionati: any): Observable<boolean> {

    if (this.idContest) {
      return this.policyService.putImpegniPolicySelezionati(
        this.idContest, impegniSelezionati).pipe(
          map((result: any) => {


            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore salvataggio',
              bottoni: [{ nome_btn: 'Ok' }]
            });

            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }
  override ctrCheck(idAmbito: string, idTematica: string, elementoChk: any, check: boolean): void {
    if (!elementoChk.descrizione && check) {
      this.sonoInCtr = true;
      this.editElementoListaChk(idAmbito, idTematica, elementoChk);
    } else {
      elementoChk.selected = check;
    }

  }
}
