<h2 mat-dialog-title>Ambito</h2>
<div mat-dialog-content class="dialog-center-cont">

  <app-spinner-overlay></app-spinner-overlay>

  <form [formGroup]="formAmbiti" appFormContainer [larghezza]="6">

    <mat-form-field appFormElement [larghezza]="1">
      <mat-label>Nome Breve</mat-label>
      <input matInput placeholder="Nome Breve" formControlName="nomeBreve" />

      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
      <mat-label>Titolo</mat-label>
      <input matInput placeholder="Titolo" formControlName="titolo" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1">
      <mat-label>Titolo Breve</mat-label>
      <input matInput placeholder="Titolo Breve" formControlName="titoloBreve" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1">
      <mat-label>Ordinamento</mat-label>
      <input matInput placeholder="Ordinamento" formControlName="index" type="number" inputmode="numeric" min="0" appInputNumber
        max="999999999999" (keydown)="onKeyDown($event)" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="6">
      <mat-label>Settore</mat-label>
      <mat-select formControlName="idSettore" placeholder="Settore" multiple>
        <mat-option *ngFor="let set of arraySettori" [value]="set.id">{{set.titolo}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="6" [riempi]="true">
      <mat-label>Descrizione</mat-label>
      <textarea matInput placeholder="Descrizione" formControlName="descrizione" rows="4"></textarea>
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="6" [riempi]="true">
      <mat-label>Note</mat-label>
      <textarea matInput placeholder="Note" formControlName="note" rows="4"></textarea>
      <mat-error appFormError></mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
  <div>
    <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
  </div>
  <div>
    <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
      Salva
    </button>
  </div>
</div>
