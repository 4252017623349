import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ComitatiWebService } from './comitati-web.service';
import { Injectable } from '@angular/core';
import { Figura } from '../KPI/kpi.service';

export interface Comitati {
  id: string,
  descrizione: string,
  guidaAllaScrittura: string,
  idAzienda: string
}
export interface ComitatiConFigAz extends Comitati {
  figureAziendali: FiguraInComiato[],
  risposta: string;
  compilato: boolean;
  dataCompilazione: string;
}

export interface FiguraInComiato {
  email: string;
  figura: string;
  id: string;
  idAzienda: string;
  nome: string;
  selected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ComitatiService {

  constructor(private comitatiWebService: ComitatiWebService) { }

  /**
    * Funzione per selezionare le righe da far vedere nella grid
    * @param page pagina
    * @param pageSize record per pagina
    * @param ricerca risultato per ricerca 
    * @param filters valori da filtrare 
    * @param sortBy sort asc desc
    * @returns lista di righe
    */
  public getComitati(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.comitatiWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  public getComitatiLibreriaAndAzienda(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.comitatiWebService.getComitatiLibreriaAndAzienda(page, pageSize, ricerca, filters, sortBy);
  }



  public postComitati(data: any) {
    return this.comitatiWebService.post(data);
  }


  public deleteComitati(idRiga: any) {
    return this.comitatiWebService.delete(idRiga);
  }

  public putComitati(id: string, comitato: any) {
    return this.comitatiWebService.put(id, comitato);
  }

}

