import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Domande, InformativaAziendale, InformativaAziendaleService, Sezioni } from 'src/app/services/informativa-aziendale/informativa-aziendale.service';
import { CategorieUm, UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-informativa-aziendale',
  templateUrl: './dialog-crea-informativa-aziendale.component.html',
  styleUrls: ['./dialog-crea-informativa-aziendale.component.scss']
})
export class DialogCreaInformativaAziendaleComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  @ViewChildren('inputDesc', { read: CdkTextareaAutosize }) queryListTextarea!: QueryList<CdkTextareaAutosize>;

  public arrayTipologiaRisposta = [
    {
      "chiave": "scelta_singola",
      "valore": "Scelta Singola"
    },
    {
      "chiave": "scelta_multipla",
      "valore": "Scelta Multipla"
    },
    {
      "chiave": "testo",
      "valore": "Testo"
    },
    {
      "chiave": "numero_intero",
      "valore": "Numero"
    },
    {
      "chiave": "numero_decimale",
      "valore": "Decimale"
    },
    {
      "chiave": "data",
      "valore": "Data"
    }
  ];

  public arrayCategorie: CategorieUm[] = [];
  public mappaCategorieUm: { [idCategoriaUnitaDiMisura: string]: UnitaDiMisura[] } = {};
  public arrayUm: UnitaDiMisura[] = [];

  /* Form Controls */
  public formInfoAz = new FormGroup({
    /* Id */
    idInformativa: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    chiave: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    codiceLingua: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    stato: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    versione: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* titolo */
    titolo: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* descrizione */
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    sezioni: new FormArray([]),

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaInformativaAziendaleComponent>,
    private informativaAziendale: InformativaAziendaleService,
    private utilityService: UtilityService,
    private unitaDiMisura: UnitaDiMisuraService,
    @Inject(MAT_DIALOG_DATA) public data: {
      informativaAziendale: InformativaAziendale,
    }
  ) {

  }

  ngAfterViewInit(): void {
    this.spinnerOver.show();
    setTimeout(() => {
      this.unitaDiMisura.getCategoria().subscribe((categorie) => {
        this.arrayCategorie = categorie;
        this.arrayCategorie.forEach((cate) => {
          if (cate?.id) {
            this.mappaCategorieUm[cate.id] = cate.unitaDiMisura || [];
          }

        })

      });

      if (this.data?.informativaAziendale?.sezioni.length) {
        this.formInfoAz.get('idInformativa')?.setValue(this.data.informativaAziendale.idInformativa);
        this.formInfoAz.get('chiave')?.setValue(this.data.informativaAziendale.chiave);
        this.formInfoAz.get('codiceLingua')?.setValue(this.data.informativaAziendale.codiceLingua);
        this.formInfoAz.get('id')?.setValue(this.data.informativaAziendale.id);
        this.formInfoAz.get('stato')?.setValue(this.data.informativaAziendale.stato);
        this.formInfoAz.get('versione')?.setValue(this.data.informativaAziendale.versione);
        this.formInfoAz.get('titolo')?.setValue(this.data.informativaAziendale.titolo);
        this.formInfoAz.get('descrizione')?.setValue(this.data.informativaAziendale.descrizione);
        this._initInfoAz(this.data.informativaAziendale.sezioni);
      } else {
        this.addSezione();
      }
    }, 0);
    this.spinnerOver.hide()
  }

  private _initInfoAz(sezioni: Sezioni[]) {

    sezioni.forEach((value, index, array) => {

      const formSezioni = new FormGroup({
        chiave: new FormControl<string | undefined>(value.chiave || undefined),
        nomeBreve: new FormControl<string | undefined>(value.nomeBreve || undefined),
        id: new FormControl<string | undefined>(value.id || undefined),
        descrizione: new FormControl<string | undefined>(value.descrizione || undefined),
        titolo: new FormControl<string | undefined>(value.titolo || undefined),
        domande: new FormArray([])
      });

      value.domande.forEach((domanda) => {

        const formDomanda = new FormGroup({
          chiave: new FormControl<string | undefined>(domanda.chiave || undefined),
          domanda: new FormControl<string | undefined>(domanda.domanda || undefined, [Validators.required, UtilityService.blankValidator]),
          id: new FormControl<string | undefined>(domanda.id || undefined),
          note: new FormControl<string | undefined>(domanda.note || undefined),
          obbligatorio: new FormControl<boolean>(domanda.obbligatorio || false),
          sottodomanda: new FormControl<boolean>(domanda.sottodomanda || false),
          tipo: new FormControl<string | undefined>(domanda.tipo || undefined, [Validators.required, UtilityService.blankValidator]),
          idCategoriaUnitaDiMisura: new FormControl<string | undefined>(domanda.idCategoriaUnitaDiMisura || undefined),
          idUnitaDiRiferimento: new FormControl<string | undefined>(domanda.idUnitaDiRiferimento || undefined),
          opzioni: new FormArray([])
        });

        (domanda.opzioni || []).forEach((value, index, array) => {

          const opzioni = new FormGroup({
            chiave: new FormControl<string | undefined>(value.chiave || undefined),
            id: new FormControl<string | undefined>(value.id || undefined),
            valore: new FormControl<string | undefined>(value.valore || undefined)
          });

          (formDomanda.get('opzioni') as FormArray).push(opzioni);
        });

        (formSezioni.get('domande') as FormArray).push(formDomanda);

      });

      (this.formInfoAz.get('sezioni') as FormArray).push(formSezioni);
    });

    setTimeout(() => {
      this.queryListTextarea?.forEach((area) => area.resizeToFitContent(true));
    }, 0);
  }

  addSezione() {
    const formSezioni = new FormGroup({
      chiave: new FormControl<string | undefined>(undefined),
      nomeBreve: new FormControl<string | undefined>(undefined),
      id: new FormControl<string | undefined>(undefined),
      descrizione: new FormControl<string | undefined>(undefined),
      titolo: new FormControl<string | undefined>(undefined),
      domande: new FormArray([])
    });
    (this.formInfoAz.get('sezioni') as FormArray).push(formSezioni);
  }

  salvaInfoAz() {
    this.spinnerOver.show();
    this.salva().subscribe(
      {
        next: (risp) => {


          if (risp) {

            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.dialogRef.close();

          } else {

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })

          }
          this.spinnerOver.hide();
        }, error: (err) => {
          console.error(err);

          let msgErr = 'Errore nel salvataggio';

          if (err === 'NO_OPZ') {
            msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono risposte';

          } else if (err === 'NO_DOM') {
            msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono domande';

          } else if (err === 'NO_UM') {
            msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono unità di misura';

          } else if (err === 'NO_SEZ') {
            msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono domande';
          }

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: msgErr,
            bottoni: [{ nome_btn: 'Chiudi' }]
          })
          this.spinnerOver.hide();
        }
      }
    )
  }

  salva(): Observable<boolean> {

    const form = this.formInfoAz.getRawValue();

    if (!form.sezioni.length || !(form.sezioni[0] as any)?.domande?.length) {

      return throwError(() => 'NO_SEZ');

    } else {

      const sezVuote = this.formInfoAz.getRawValue().sezioni.map((val: any, index, array) => {

        return val.domande.map((dom: Domande) => {

          if (!dom.domanda || !dom.tipo) {

            return 'NO_DOM';

          } else if ((dom.tipo === "scelta_multipla" || dom.tipo === "scelta_singola") && !dom.opzioni?.length) {


            return 'NO_OPZ';


          } else if ((dom.tipo === "numero_intero" || dom.tipo === "numero_decimale") && (!dom.idCategoriaUnitaDiMisura || !dom.idUnitaDiRiferimento)) {

            return 'NO_UM';

          } else {

            return null;
          }
        }).find((val: any) => !!val);

      }).find((val: any) => !!val);

      if (sezVuote) {
        return throwError(() => sezVuote);
      }
    }

    if (this.formInfoAz.valid) {

      const idInformativa = this.formInfoAz.get('idInformativa')?.value;
      if (idInformativa) {
        return this.informativaAziendale.put(idInformativa, this.formInfoAz.getRawValue()
        ).pipe(
          map((risp) => {
            return true;
          }
          ),
          catchError((err) => {
            console.error('Errore durante il salvataggio :', err);
            return of(false);
          })
        );
      } else {
        return this.informativaAziendale.post(this.formInfoAz.getRawValue()).pipe(
          map((risp) => {
            if (risp.body) {
              this.formInfoAz.get('idInformativa')?.setValue(risp.body.idInformativa);
            }
            return true;
          }
          ),
          catchError((err) => {
            console.error('Errore durante il salvataggio :', err);
            return of(false);
          })
        );
      }
    } else {
      Object.values(this.formInfoAz.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return of(true);
    }
  }

  addDomanda(formArray: FormArray) {

    const formDomanda = new FormGroup({
      chiave: new FormControl<string | undefined>(undefined),
      domanda: new FormControl<string | undefined>(undefined, [Validators.required, UtilityService.blankValidator]),
      id: new FormControl<string | undefined>(undefined),
      note: new FormControl<string | undefined>(undefined),
      obbligatorio: new FormControl<boolean>(false),
      sottodomanda: new FormControl<boolean>(false),
      idCategoriaUnitaDiMisura: new FormControl<string | undefined>(undefined),
      idUnitaDiRiferimento: new FormControl<string | undefined>(undefined),
      tipo: new FormControl<string | undefined>(undefined, [Validators.required, UtilityService.blankValidator]),
      opzioni: new FormArray([])
    });

    formArray.push(formDomanda);

  }

  addOpzioni(formArray: FormArray) {
    const opzioni = new FormGroup({
      chiave: new FormControl<string | undefined>(undefined),
      id: new FormControl<string | undefined>(undefined),
      valore: new FormControl<string | undefined>(undefined)
    });
    formArray.push(opzioni);
  }

  elimina(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  drop(event: CdkDragDrop<any[]>, formSezione: FormGroup) {
    const formArrayDomande = formSezione.get("domande") as FormArray;
    const currentGroup = formArrayDomande.at(event.previousIndex);
    formArrayDomande.removeAt(event.previousIndex, { emitEvent: false });
    formArrayDomande.insert(event.currentIndex, currentGroup, { emitEvent: false });
  }

  dropSotto(event: CdkDragDrop<any[]>, formArraySotto: FormArray) {
    const currentGroup = formArraySotto.at(event.previousIndex);
    formArraySotto.removeAt(event.previousIndex, { emitEvent: false });
    formArraySotto.insert(event.currentIndex, currentGroup, { emitEvent: false });
  }

  cambiaTipologia(event: any, domandeArr: FormGroup) {



    if (event.value !== "numero_intero" && event.value !== "numero_decimale") {
      domandeArr.get('idUnitaDiRiferimento')?.setValue(undefined);
      domandeArr.get('idCategoriaUnitaDiMisura')?.setValue(undefined);
    }

  }

  /*   cambioCategoria(event: any) {
      
      this.arrayUm = [];
      if (event?.value) {
        const cate = this.arrayCategorie.find((cat) => {
          return cat.id === event.value;
        });
        this.arrayUm = cate?.unitaDiMisura || [];
      }
  
    } */


  pubblica() {

    this.utilityService.openDialog({
      descrizione: 'Rendere pubblica l\'Informativa Aziendale?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.salva().subscribe(
              {
                next: (risposta) => {

                  if (risposta) {

                    const idInformativa = this.formInfoAz.get('idInformativa')?.value;
                    if (idInformativa) {
                      this.informativaAziendale.putPubblica(idInformativa).subscribe(
                        {
                          next: (risp) => {
                            this.utilityService.opneSnackBar('Configurazione Informativa Aziendale PUBBLICATA', '', {
                              duration: 2000,
                              panelClass: ['success-snackbar']
                            });
                            this.dialogRef.close();
                          },
                          error: (err) => {
                            this.utilityService.openDialog({
                              titolo: 'Attenzione',
                              descrizione: 'Errore nella pubblicazione ',
                              bottoni: [{ nome_btn: 'Chiudi' }]
                            })
                            this.spinnerOver.hide();
                          }

                        }
                      )

                    }
                  } else {
                    this.utilityService.openDialog({
                      titolo: 'Attenzione',
                      descrizione: 'Errore nella pubblicazione ',
                      bottoni: [{ nome_btn: 'Chiudi' }]
                    })
                  }
                  this.spinnerOver.hide();

                },
                error: (err) => {

                  console.error(err);
                  let msgErr = 'Errore nel salvataggio';

                  if (err === 'NO_OPZ') {
                    msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono risposte';

                  } else if (err === 'NO_DOM') {
                    msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono domande';

                  } else if (err === 'NO_UM') {
                    msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono unità di misura';

                  } else if (err === 'NO_SEZ') {
                    msgErr = 'Non è possibile salvare questa informativa aziendale, non ci sono domande';
                  }

                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: msgErr,
                    bottoni: [{ nome_btn: 'Chiudi' }]
                  })
                  this.spinnerOver.hide();
                }
              }
            )
          }
        }]
    });
  }


  scrollaInView(elemento: any) {
    console.log('ELEMENTO : ', elemento);
    setTimeout(() => {
      elemento.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

}
