import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { PianoService } from 'src/app/services/piano/piano.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-cfg-modelli-sviluppo-piano',
  templateUrl: './dialog-crea-cfg-modelli-sviluppo-piano.component.html',
  styleUrls: ['./dialog-crea-cfg-modelli-sviluppo-piano.component.scss']
})
export class DialogCreaCfgModelliSviluppoPianoComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public isPermessoSoloRead: boolean = false;
  public formModelloSviluppoPiano = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCfgModelliSviluppoPianoComponent>,
    private pianoService: PianoService,
    private utilityService: UtilityService,
    private utenteService: UtenteService,

    @Inject(MAT_DIALOG_DATA) public data: {
      modelloSviluppoPiano: any,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
    }

  ) { }

  ngAfterViewInit(): void {
    if (this.data?.modelloSviluppoPiano) {
      Object.keys(this.data.modelloSviluppoPiano).forEach((value, index, array) => {
        this.formModelloSviluppoPiano.get(value)?.setValue((this.data.modelloSviluppoPiano)[value]);
      })
    }

    const isEdit = this.utenteService.isPermessoAttivo('EDIT_PIANO');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_PIANO')
    this.isPermessoSoloRead = (!isEdit && !isCreate)

    if (this.isPermessoSoloRead) {
      this.formModelloSviluppoPiano.disable();
    }
  }

  public salva() {

    if (this.formModelloSviluppoPiano.valid) {

      if (this.data.idContest) {
        return this.salvaInContesto();
      }

      const id = this.formModelloSviluppoPiano.get('id')?.value;

      if (id) {
        return this.pianoService.putModelliSviluppoPiano(id, this.formModelloSviluppoPiano.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err.error.message,
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }
          }
        );
      } else {
        return this.pianoService.postModelliSviluppoPiano(this.formModelloSviluppoPiano.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: () => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formModelloSviluppoPiano.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
  /**
     * Metodo che mi salva un nuovo modelloSviluppo effimero in piano di sostenibilità
     * @returns 
     */
  public salvaInContesto() {

    if (!this.formModelloSviluppoPiano.get('id')?.value) {
      this.formModelloSviluppoPiano.get('creatoDaContesto')?.setValue(true);
    }
    this.pianoService.putModelloSviluppoEffimeri(this.data.idContest,
      this.formModelloSviluppoPiano.getRawValue()).subscribe({
        next: (result: any) => {
          this.spinnerOver.hide();
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          this.spinnerOver.hide();
          console.error('Errore durante il salvataggio dell\'modelloSviluppo:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio del modello Sviluppo ');
        }
      });

  }
  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}
