import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaFigureAziendeComponent } from './dialog/dialog-crea-figure-aziende/dialog-crea-figure-aziende.component';
import { Subscription } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-figure-aziende',
  templateUrl: './figure-aziende.component.html',
  styleUrls: ['./figure-aziende.component.scss']
})
export class FigureAziendeComponent implements AfterViewInit, OnDestroy {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public isPermessoSoloRead: boolean = (!this.utenteService.isPermessoAttivo('EDIT_FIGURA_AZ') && !this.utenteService.isPermessoAttivo('CREATE_FIGURA_AZ'))
  private _subAziendaCorrente: Subscription | undefined;

  //** DEVO DICHIARARLA QUA PERCHE SENNO MI DA CIRCULAR DEP. 
  public idAzienda!: string;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Figura Aziendale',
      forControlName: 'figura',
      input: 'text',
    },

  ];

  public colonne: { [key: string]: Colonna } = {
    nome: {
      title: 'Nome e Cognome',
      value: 'nome',
      sortable: true
    },
    email: {
      title: 'Email',
      value: 'email',
      sortable: true
    },
    figura: {
      title: 'Figura Aziendale',
      value: 'figura',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },

    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (record?.figura.toLowerCase() === 'referente' || this.isPermessoSoloRead),
      buttonMostraSempre: true
    },
  };

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    public utenteService: UtenteService,
    private aziendaService: AziendaService,
    private cdRef: ChangeDetectorRef,
  ) { }


  ngAfterViewInit(): void {

    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this.idAzienda = azienda?.id || '';

      this.tabella?.caricaDati();
    });
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }


  creaNuovo(record?: any) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaFigureAziendeComponent, {
      data: {
        figAzienda: record,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '60%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.aziendaService.getFigureAz(this.idAzienda, page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _elimina(id: string) {

    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare una figura aziendale?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI',
          background_color: 'var(--mat-color-primary)',
          handler: () => {
            this.spinnerOver.show();
            this.aziendaService.deleteFigureAz(this.idAzienda, id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });
  }

}

